import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { SearchableDropdown } from '../../../ui/dropdowns/searchable-dropdown';
import { FormInput } from '../../../components/ui/form-input';
import { SelectDropdown } from '../../../ui/dropdowns/select-dropdown';

export const StyledSearchableDropdown = styled(SearchableDropdown)(
  ({ theme: { colors: { SlicerShadowColor } = {} as any } = {} as any }) => css`
    min-width: 10rem;
    &.disabled {
      pointer-events: none;
      span {
        color: ${SlicerShadowColor};
      }
    }
  `,
);

export const StyledSelectDropdown = styled(SelectDropdown)(
  ({ theme: { colors: { SlicerShadowColor } = {} as any } = {} as any }) => css`
    min-width: 10rem;
    &.disabled {
      pointer-events: none;
      span {
        color: ${SlicerShadowColor};
      }
    }
  `,
);

export const LabelWrapper = styled.div`
  width: 185px;
  display: flex;

  &.relative-date-row-2 {
    padding-left: 40px;
  }
`;

export const StyledFormGroup = styled.div(
  ({ theme: { colors: { ErrorColor } = {} as any } = {} as any }) => css`
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;
    &.error {
      input[type='text'],
      label {
        border: 1px solid ${ErrorColor};
        box-shadow: 0 0 4px 0px ${ErrorColor};
      }
    }
    input[type='checkbox'],
    label,
    .form-control {
      margin: 0 0 0 0;
    }
    & > * {
      vertical-align: middle;
      flex-shrink: 1;
      max-width: 15rem;
      max-height: 28px;
    }
    > div,
    input[type='checkbox'] {
      margin-right: 1rem;
    }
  `,
);
`
`;
export const DatetimeTextbox = styled(FormInput)(
  ({ theme: { colors: { LightFontWeight } = {} as any } = {} as any }) => css`
    width: 200px;
    input {
      font-weight: ${LightFontWeight};
    }
  `,
);
