import { compose, pure } from 'react-recompose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import MonitorQueries from '../graphql/MonitorsGql';
import DiscoverQueries from '../graphql/DiscoverQueries';
import _ from 'lodash';
import Discover from '../redux/actions/DiscoverActions';
import { ComponentTypes } from '../Constants';
import ServiceLocator from '../ServiceLocator';
import Util from '../Util';
import { withDiscoverRouter } from '../utilities/router.hoc';
import { SkeletonListLoader } from '../loaders/skeleton-list-loader';

const MonitorEventRedirect = props => {
  if (_.isNull(props.visualizations) || _.isNull(props.monitorEvent)) {
    return <Redirect to='/' />;
  } else if (!props.vizQueryLoading && !props.monitorQueryLoading) {
    const redirectComponents = ServiceLocator.getAll('REDIRECT', {
      type: ComponentTypes.REDIRECT,
    });
    const RedirectComponent = redirectComponents.find(comp =>
      comp.supports(props.monitorEvent),
    );
    return <RedirectComponent {...props} />;
  } else {
    return <SkeletonListLoader />;
  }
};

const VizQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  skip: ownProps => _.isUndefined(ownProps.match.params.monitorEventId),
  props: ({ data }) => {
    if (data?.error) {
      return { visualizations: null, vizQueryLoading: false };
    } else if (data?.visualizations) {
      const visualizations = data.visualizations.map(v => ({
        ...v,
        dataset: _.find(data.datasets, { id: v.datasetId }),
        discoveryType: 'VISUALIZATION',
      }));
      return { visualizations, vizQueryLoading: false };
    } else {
      return { vizQueryLoading: true };
    }
  },
});

const MonitorEventQuery = graphql(MonitorQueries.MonitorEventQuery, {
  skip: ownProps => _.isUndefined(ownProps.match.params.monitorEventId),
  options: ownProps => ({
    variables: {
      id: ownProps.match.params.monitorEventId,
    },
  }),
  props: ({ data, ownProps }) => {
    if (data?.error) {
      return { monitorEvent: null, monitorQueryLoading: false };
    } else if (data?.monitorEvent) {
      let options;
      try {
        options = JSON.parse(Util.decode(ownProps.match.params.options));
        return {
          monitorEvent: data.monitorEvent,
          options,
          monitorQueryLoading: false,
        };
      } catch (e) {
        return { monitorEvent: data.monitorEvent, monitorQueryLoading: false };
      }
    } else {
      return { monitorQueryLoading: true };
    }
  },
});

export default compose(
  pure,
  withDiscoverRouter,
  VizQuery,
  MonitorEventQuery,
  connect(
    () => ({}),
    dispatch => {
      return {
        openVisualization: discovery => {
          dispatch(Discover.openVisualization(discovery));
        },
      };
    },
  ),
)(MonitorEventRedirect);
