import { useStyles as useSlicerStyles } from '../slicer/slicer-widget/slicer-widget.styles';
import { useDiscoverTheme } from '../../common/emotion';

export const useStyles = ({
  isOpen,
  headerSx: providedHeaderSx = {},
  listSx: providedListSx = {},
  hasSelection = false,
}) => {
  const {
    darkMode,
    isDashletMode,
    isMobile,
    colors: {
      Gray90,
      ContentText,
      PlaceholderText,
      LightFontWeight,
      ContentBackground,
      FilterWidgetHoverBackground,
    },
  } = useDiscoverTheme();

  const isResponsive = isDashletMode || isMobile;

  const {
    dropdownWrapperStyle,
    searchMenuItemSx,
    headerSx: baseHeaderSx,
  } = useSlicerStyles({ isOpen });

  const selectedColor = darkMode ? ContentText : Gray90;

  const headerSx = {
    ...baseHeaderSx,
    ...{
      width: 'auto',
      maxWidth: '100%',
      containerType: 'size',
      height: isResponsive ? '30px' : '40px',
      padding: '6px 12px',
      color: hasSelection ? selectedColor : PlaceholderText,
      fontWeight: LightFontWeight,
    },
    ...(isOpen
      ? {}
      : {
          '&:hover': {
            backgroundColor: FilterWidgetHoverBackground,
          },
        }),
    ...providedHeaderSx,
  };

  const listSx = {
    backgroundColor: ContentBackground,
    ...providedListSx,
  };

  return {
    dropdownWrapperStyle,
    searchMenuItemSx,
    headerSx,
    listSx,
  };
};
