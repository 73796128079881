import ArrowDown from '../../../../images/sdd/arrow_down.svg';
import ArrowUp from '../../../../images/sdd/arrow_up.svg';
import ArrowDownThin from '../../../../images/sdd/arrow_down_thin.svg';
import ArrowUpThin from '../../../../images/sdd/arrow_up_thin.svg';
import Bars from '../../../common/d3/Bars';
import _ from 'lodash';
import TimeUtils from '../../../common/TimeUtils';
import { messages } from '../../../i18n';

const PercentChangeCard = ({
  up,
  period,
  pct,
  payload,
  barData,
  condensed,
  title,
  subtitle,
  inverted,
  chartHeight,
  chartWidth,
  includeArrowInTitle,
  date,
}) => {
  const direction = up ? 'up' : 'down';
  const periodShort = !_.isNil(payload.previousPeriod)
    ? payload.previousPeriod?.toLowerCase()
    : 'period';
  return (
    <div className={`percent-changed-card${inverted ? ' inverted' : ''}`}>
      <span className={'title'}>
        {includeArrowInTitle &&
          (up ? (
            <ArrowUp className='arrow-up' height='16' width='12' />
          ) : (
            <ArrowDown className='arrow-down' height='16' width='12' />
          ))}
        <span title={title}>{title}</span>
        {subtitle && (
          <div className='subtitle' title={subtitle}>
            {subtitle}
          </div>
        )}
      </span>
      {condensed && (
        <div className='card-date'>{TimeUtils.formatDate(date)}</div>
      )}
      <div className='hbox'>
        <div className={'pct-container'}>
          <div className={`pct ${direction} pct-digits-` + `${pct.length - 1}`}>
            {up ? (
              <ArrowUpThin className={'arrow-up'} />
            ) : (
              <ArrowDownThin className='arrow-down' />
            )}
            {pct}
          </div>
          <div className='period'>{period}</div>
        </div>
        <div className='chart'>
          <svg height={chartHeight} width={chartWidth}>
            <Bars
              height={chartHeight}
              width={chartWidth}
              data={barData}
              getX={d => d.name}
              getY={d => {
                const val = d.value;
                return _.isNumber(val) && _.isFinite(val) ? val : 0;
              }}
              showLabels
              chartPadding={{ expandRange: 0.2 }}
              showLabelsInsideBar={false}
            />
          </svg>
          <div className='axis'>
            <span>
              {messages.formatString(
                messages.insightFeed.priorTimePeriodShort,
                periodShort,
              )}
            </span>
            <span>
              {messages.formatString(
                messages.insightFeed.thisTimePeriodShort,
                periodShort,
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

PercentChangeCard.defaultProps = {
  chartHeight: 90,
  chartWidth: 104,
  chartPadding: 4,
  includeArrowInTitle: false,
};

export default PercentChangeCard;
