import { css } from '@emotion/react';
import { ModernBorderRadius, font } from './mixins';
import { siconSelectorMixin } from '../../icons';

export const bootstrapOverridesStyles = ({
  mobileMaxWidth,
  theme: {
    viewWidth,
    viewHeight,
    isDashletMode,
    colors: {
      ContentText,
      ShareDropdownColor,
      MediumFontWeight,
      LightFontWeight,
      DefaultButtonBorderColorHover,
      ButtonColorPrimary,
      DefaultButtonBorderColor,
      DefaultButtonTextColor,
      PanelIconColor,
      ContentBackground,
      DropdownTextPrimary,
      MenuHoverBackgroundColor,
      DropdownTextHover,
      DropdownButtonBackgroundColor,
      DropdownBackgroundHover,
      DimText,
      Strokes,
      negativeBackgroundColor,
      negativeColor,
      MediumBorder,
      ModalHeaderBackground,
      PanelBackground,
      DefaultIconColor,
      ModalBackground,
      ModalBackgroundBorder,
    },
  },
}) => css`
  .popover {
    background-color: ${PanelBackground};
  }
  .popover-title {
    background-color: ${ModalHeaderBackground};
    color: ${ContentText} !important;
    border-bottom-color: ${ModalHeaderBackground};
  }
  .popover.bottom > .arrow::after {
    border-bottom-color: ${PanelBackground};
  }
  /*
  Bootstrap Overrides. If we move to build Bootstrap Less from source this would be included as part of that.
 */
  @media (min-width: ${mobileMaxWidth}) {
    .form-inline .form-group {
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 8px;
      vertical-align: middle;
    }
    .modal-title {
      ${font({ weight: MediumFontWeight, size: '24px' })}
      text-align: center;
    }
  }
  ${
    isDashletMode
      ? `.dialog {
    position: absolute;
    top: auto;
    width: ${viewWidth};
    height: ${viewHeight};
  }
  .modal-backdrop {
    position: absolute !important;
    top: auto;
    width: ${viewWidth};
    height: ${viewHeight};
    z-index: 80;
  }
  .modal {
    position: absolute !important;
    top: auto;
    width: ${viewWidth};
    height: ${viewHeight};
    z-index: 81;
  }
  .prompt-dialog-backdrop {
    z-index: 80 !important;
  }
  .prompt-dialog.modal {
    z-index: 81 !important;
  }`
      : ''
  }
  .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    background-color: ${MenuHoverBackgroundColor};
  }
  .dropdown-toggle.btn.btn-default: {
    background-color: ${DropdownButtonBackgroundColor};
  }
  .dropdown.btn-group-default:hover .btn-default,
  .dropdown.btn-group-default:hover .btn-default:focus:not(.dropdown-toggle),
  .dropdown.btn-group-default:hover .btn-default:active:not(:hover) {
    border-color: ${DefaultButtonBorderColorHover};
    color: ${DefaultButtonBorderColorHover};
  }
  .dropdown.btn-group-default:hover .dropdown-toggle.btn-default {
    background-color: ${ButtonColorPrimary};
    color: ${ContentBackground};
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    background-color: ${DropdownButtonBackgroundColor};
    color: ${ContentText};
    &:focus {
      color: ${ContentText};
      background-color: ${DropdownButtonBackgroundColor};
    }
  }
  .dropdown.btn-group-default .btn-default {
    line-height: unset;
    height: unset;
    background-color: ${DropdownButtonBackgroundColor};
  }
  .dropdown-menu {
    background-color: ${ContentBackground};
  }
  .dropdown-menu > li:not(.disabled) > a {
    color: ${ContentText} !important;
  }
  .dropdown-menu > li.disabled > a {
    color: ${ShareDropdownColor} !important;
  }
  .form-control {
    background-color: ${ContentBackground};
  }
  .dropdown-menu li a {
    background-color: ${isDashletMode ? 'transparent' : ContentBackground};
  }
  .dropdown.btn-group-default .btn-default:hover {
    border-color: ${DefaultButtonBorderColorHover};
    color: ${DefaultButtonBorderColorHover};
  }
  .dropdown.btn-group-default .btn-default:active:not(:hover),
  .dropdown.btn-group-default .btn-default:focus {
    border-color: ${DefaultButtonBorderColor};
    color: ${DefaultButtonTextColor};
  }
  .dropdown.btn-group-default .btn-default:focus:hover:not(.dropdown-toggle) {
    border-color: ${DefaultButtonBorderColorHover};
    color: ${DefaultButtonBorderColorHover};
  }
  .dropdown.btn-group-default .dropdown-toggle.btn-default {
    background-color: ${PanelIconColor};
    color: ${ContentBackground};
    padding: 6px 14px;
    border: 1px solid transparent;
  }
  .dropdown.btn-group-default .dropdown-menu {
    background-color: ${ContentBackground};
  }
  .dropdown.btn-group-default .dropdown-menu > li > a {
    ${font({
      size: '16px',
      weight: LightFontWeight,
      color: DropdownTextPrimary,
    })}
    padding: 8px 16px;
  }
  .dropdown.btn-group-default .dropdown-menu > li > a:focus,
  .dropdown.btn-group-default .dropdown-menu > li > a:hover {
    ${font({ size: '16px', weight: LightFontWeight, color: DropdownTextHover })}
    background-color: ${DropdownBackgroundHover};
  }
  .dropdown-menu {
    min-width: unset;
    border-radius: 2px;
    border-color: ${MediumBorder};
    box-shadow: none;
    margin-top: 0;
    padding: 8px 0px;
  }
  .dropdown-menu > li > a:hover {
    color: ${DropdownTextHover};
  }
  .dropdown-menu > li.disabled > a,
  .dropdown-menu > li.disabled > a:hover {
    color: ${DimText};
  }
  .dropdown-menu .divider {
    width: 100%;
    margin: 8px 0px;
    background-color: ${Strokes};
  }
  .dropdown-menu > li > a {
    ${font({ size: '12px', weight: LightFontWeight })}
    padding: 8px 16px;
  }
  .has-error input[type='text'],
  .has-error input[type='tel'].react-phone-number-input__phone:focus,
  .has-error input[type='tel'].react-phone-number-input__phone {
    background-color: ${negativeBackgroundColor};
    border: 1px solid ${negativeColor};
    box-shadow: 0 0 4px 0px ${negativeColor};
  }
  .divider {
    background-color: ${ContentText};
  }
  .panel.panel-default {
    border-color: ${MediumBorder};
  }
  .panel-default > .panel-body {
    background-color: ${PanelBackground};
    color: ${ContentText} !important;
  }
  .panel-default > .panel-heading, .panel-default > .panel-footer {
    border-color: ${MediumBorder};
    background-color: ${ModalHeaderBackground};
    color: ${ContentText} !important;
  }
  .modal-content {
    background-color: ${ModalBackground} !important;
    border: 1px solid ${ModalBackgroundBorder} !important;
    border-radius: ${ModernBorderRadius};
  }
  .caret {
    border: none !important;
    ${siconSelectorMixin({ icon: 'chevron-down' })}
    color: ${DefaultIconColor};
    height: calc(100% - 12px);
    width: 1em;
    height: 1em;
  }
`;
