import { memo, useMemo } from 'react';
import { use18nFormatter } from '../../../../common/formatting';
import { messages } from '../../../../i18n';
import { Tooltip } from '../../../../components/ui/tooltip';
import _ from 'lodash';
import { useSlicerTheme } from '../common';

const NUM_TOOLTIP_ITEMS = 10;
const SLICER_DELAY_TOOLTIP_DELAY = 500;

export const SlicerTooltip = memo<{
  field?: string;
  options;
  loading;
  children;
  delay?;
}>(
  ({
    field,
    options,
    loading = false,
    children,
    delay = SLICER_DELAY_TOOLTIP_DELAY,
  }) => {
    const { isOpen = false } = useSlicerTheme();
    const { formatI18n } = use18nFormatter();
    return useMemo(() => {
      const selectedOptions = _(options)
        .filter('isSelected')
        .map('option')
        .value();
      let text = '';
      if (!loading && !isOpen) {
        text = _.take(selectedOptions, NUM_TOOLTIP_ITEMS).join(', ');
        if (selectedOptions.length > NUM_TOOLTIP_ITEMS) {
          text += formatI18n(
            messages.slicer.andMore,
            selectedOptions.length - NUM_TOOLTIP_ITEMS,
          );
        }
      }
      return (
        <Tooltip
          key={'slicer-tooltip'}
          placement='top'
          title={field ? `${field}: ${text}` : text}
          enterDelay={delay}
          arrow
          classes={{ tooltip: 'center-text' }}
        >
          {children}
        </Tooltip>
      );
    }, [options, loading, isOpen, field, delay, children, formatI18n]);
  },
);
