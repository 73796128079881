import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { font, ModernBorderRadius } from '../../common/emotion';

export const UserDataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const UserProfileDetail = styled.div(
  ({
    theme: {
      colors: { ContentBackground, ContentText, Gray40 },
    },
  }) => css`
    margin-top: 30px;
    flex: 1 1 40%;
    color: ${ContentText};

    & [role='list'],
    & [role='tooltip'] ul {
      background-color: ${ContentBackground};
    }

    & > div {
      &.i18n-setting-input {
        background-color: ${ContentBackground};
        border-radius: ${ModernBorderRadius};
      }
      &.user-label {
        ${font({ size: '14px', weight: '400', color: Gray40 })};
      }
      &.user-value {
        ${font({ size: '16px', weight: '400', color: ContentText })};
      }
    }
  `,
);
