import { css } from '@emotion/react';
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import DatePicker from 'rc-calendar/lib/Picker';
import { DatasetSection } from '../../edit-dataset-settings.styles';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputBase from '@mui/material/InputBase';
import { ModernBorderRadius } from '../../../../common/emotion';

const DatePickerWrapper = styled.div`
  width: 250px;
`;

export const FiscalDatasetSection = styled(DatasetSection)`
  max-width: 40em;
`;

export const DatePickerStyled = forwardRef((props, ref) => {
  return (
    <DatePickerWrapper>
      <DatePicker ref={ref} {...props} />
    </DatePickerWrapper>
  );
}) as any;

// going with span(display block) because of a current global styling conflict
export const DatasetSectionTitle = styled.span(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }: any) => css`
    font-size: 12px;
    color: ${Gray70};
    margin-bottom: 0.35em;
    display: block;
  `,
);

export const DatasetDateSelectionContainer = styled.div`
  display: inline-block;
`;

export const CalendarFieldInputFormControl = styled(InputBase)(
  ({
    theme: {
      darkMode,
      colors: {
        MediumBlue,
        ContentText,
        SugarGrayPalette: { Gray30, Gray80 },
        FormControlShadowColor,
      } = {} as any,
    } = {} as any,
  }: any) => css`
    width: 100%;

    input {
      font-weight: 300;
      font-style: inherit;
      font-size: 14px;
      color: inherit;
      background-color: inherit;
      height: 28px;
      padding: 7px;
      border-radius: ${ModernBorderRadius};
      border: 1px solid ${darkMode ? Gray80 : Gray30};
      box-shadow: inset 0 1px 1px ${FormControlShadowColor};
      box-sizing: border-box;

      &:hover {
        border-color: ${darkMode ? Gray80 : Gray30};
      }

      &:focus {
        border-color: ${MediumBlue};
        box-shadow: none;
        color: ${ContentText};
      }
      &.Mui-disabled {
        cursor: not-allowed;
      }
    }
  `,
);

export const InputGroup = styled.div`
  position: relative;

  .sicon {
    position: absolute;
    z-index: 5;
    margin: 8px;
    right: 0;
  }
`;

export const DatePreviewBox = styled.div(
  ({
    theme: { colors: { DatePreviewBox } = {} as any } = {} as any,
  }: any) => css`
    background-color: ${DatePreviewBox};
    border-radius: 0.2em;
    padding: 0.8em 0.9em;
    min-width: 25em;
    display: inline-block;

    ${DatePreviewBoxData}:last-child {
      margin-bottom: 0;
    }
  `,
);

export const DatePreviewBoxData = styled.span`
  font-size: 14px;
  margin-bottom: 1em;
  display: block;
`;

export const DatasetSettingsContent = styled.div`
  padding: 20px 30px;
  width: 100%;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: -2px;
  margin-bottom: 0;
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
  .Mui-disabled {
    cursor: not-allowed;
  }
`;

export const RadioStyled = styled(Radio)(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }: any) => css`
    color: ${Gray70};
    padding: 2px;
    &.Mui-checked {
      color: ${Gray70};
    }
    & .MuiSvgIcon-root {
      font-size: 16px;
    }
  `,
);
