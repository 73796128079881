import { useDiscoverTheme } from '../../common/emotion';
import { merge } from 'lodash';

export const useSwitchStyles = ({ providedSx }) => {
  const {
    colors: { SwitchTrackChecked, SwitchTrackUnchecked, SwitchThumb },
  } = useDiscoverTheme();

  const sx = merge(
    {
      width: 36,
      height: 20,
      padding: 0,
      margin: 1,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0.25,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: SwitchThumb,
          '& + .MuiSwitch-track': {
            opacity: 1,
            border: 0,
            backgroundColor: SwitchTrackChecked,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: SwitchTrackChecked,
          border: `4px solid ${SwitchThumb}`,
        },
      },
      input: {
        margin: 0,
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
        margin: 0,
        boxShadow: 'none',
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: SwitchTrackUnchecked,
        opacity: 1,
      },
    },
    providedSx,
  );

  return { sx };
};
