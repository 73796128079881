import React from 'react';
import PromptDialog from '../../common/widgets/dialogs/PromptDialog';
import { Viz, Viz as VizUtils } from '../../discovery/VizUtil';
import {
  branch,
  compose,
  renderComponent,
  renderNothing,
  withProps,
} from 'react-recompose';
import { HelpBlock } from '../../views/VizSaveDialog/ui';
import { messages } from '../../i18n';
import _, { cloneDeep } from 'lodash';
import { ICalc, ILayout } from '../interfaces';
import Discover from '../../common/redux/actions/DiscoverActions';
import { ChartSpecs } from '../ChartSpecs';
import { ChartSpecIds, ShelfName } from '../../common';
import { IAnyAttribute } from '../../datasets';
import { ThunkActionDispatch, ThunkAction } from 'redux-thunk';

export interface IAddFieldArguments {
  discoveryId: string;
  chartType: string;
  field: IAnyAttribute;
  shelf: ShelfName;
  layout: ILayout;
  index?: number;
  reduxTransactionId?: string;
}

export const addFieldDispatch = (
  dispatch: ThunkActionDispatch<(args: any) => ThunkAction<any, any, any, any>>,
) => ({
  discoveryId,
  chartType,
  field,
  shelf,
  layout,
  index,
  reduxTransactionId,
}: IAddFieldArguments) => {
  // if the field is a time attribute, make sure it is not a more granular attribute than the lowest in any prior period calc field already in play
  const priors = Viz.priorPeriodCalcsFieldInvalidates(field, layout);
  if (!_.isEmpty(priors)) {
    // You are adding a time attribute that will invalidate a prior period calculation, continue?
    dispatch(
      Discover.showConfirmAddField(discoveryId, field, shelf, priors, index),
    );
  } else {
    const addField = enforceAggregationType({
      dispatch,
      discoveryId,
      chartType,
      field,
      shelfId: shelf,
    });

    dispatch(
      Discover.addFieldToVisualization(
        discoveryId,
        addField,
        shelf,
        index,
        reduxTransactionId,
      ),
    );
  }
};

export const enforceAggregationType = ({
  dispatch,
  discoveryId,
  chartType,
  field,
  shelfId,
}: {
  dispatch: any;
  discoveryId: string;
  chartType: string;
  field: IAnyAttribute;
  shelfId: string;
}): IAnyAttribute => {
  const updatedField = cloneDeep(field);
  // force waterfall principal metric to aggregate by Sum
  const chartSpec = ChartSpecs[chartType];
  if (
    chartSpec.id === ChartSpecs.waterfall.id &&
    shelfId === ChartSpecIds.VALUES
  ) {
    // render field as Sum
    updatedField.defaultAggregation = 'Sum';

    dispatch(
      Discover.updateAggregation({
        discoveryId,
        newAggregation: { name: 'Sum' },
        field: updatedField,
        shelf: shelfId,
      }),
    );
  }

  return updatedField;
};

export const ConfirmDeleteCalcPrompt = props => {
  const boldName = (
    <strong>
      '{props.calcFieldToDelete ? props.calcFieldToDelete.name : ''}'
    </strong>
  );
  const deleteFieldDetail = (
    <HelpBlock className='text-center'>
      <p>
        {messages.formatString(
          messages.layoutPanel.confirmDeleteCalcField,
          boldName,
        )}
      </p>
      <br />
      <p>{messages.layoutPanel.warningOpCannotBeUndone}</p>
      <br />
    </HelpBlock>
  );
  return (
    <PromptDialog
      show={props.show}
      detail={deleteFieldDetail}
      doYes={() => {
        props.onConfirm();
      }}
      doNo={() => {
        props.onCancel();
      }}
    />
  );
};

export const WarnDeleteDependentCalcPrompt = props => {
  const deps = props.dependentFields;
  const depsList = deps.map(d => `'${d.name}'`).join(', ');
  const boldName = (
    <strong>
      '{props.calcFieldToDelete ? props.calcFieldToDelete.name : ''}'
    </strong>
  );
  const deleteFieldDetail = (
    <HelpBlock className='text-center'>
      <p>
        {messages.formatString(
          messages.layoutPanel.warnFieldHasDependentCalcs,
          {
            name: boldName,
            dependentList: <strong>{depsList}</strong>,
          },
        )}
      </p>
      <br />
      <p>
        <em>{messages.layoutPanel.cannotDeleteFieldReferencedByOthers}</em>
      </p>
      <br />
    </HelpBlock>
  );
  return (
    <PromptDialog
      show={props.show}
      detail={deleteFieldDetail}
      asInfo
      doOk={() => {
        props.onCancel();
      }}
    />
  );
};

interface IDeleteCalcPromptProps {
  show?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  calcFieldToDelete?: ICalc;
  calcFields?: ICalc[];
  dependentFields?: ICalc[];
}

export const DeleteCalcPrompt = compose<any, IDeleteCalcPromptProps>(
  branch((props: IDeleteCalcPromptProps) => {
    return !props.show;
  }, renderNothing),
  withProps((props: IDeleteCalcPromptProps) => {
    const deps = VizUtils.findDependentCalcs(
      props.calcFieldToDelete,
      props.calcFields,
    );
    return {
      dependentFields: deps,
    };
  }),
  branch(
    (props: IDeleteCalcPromptProps) => {
      return _.isEmpty(props.dependentFields);
    },
    renderComponent((props: IDeleteCalcPromptProps) => (
      <ConfirmDeleteCalcPrompt {...props} />
    )),
  ),
)(WarnDeleteDependentCalcPrompt);
