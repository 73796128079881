import { useDiscoverTheme } from '../../../../../common/emotion/theme';

export const useSelectedListItemSxStyles = () => {
  const {
    colors: { LightBlue },
  } = useDiscoverTheme();

  return {
    sx: {
      backgroundColor: LightBlue,
      pointerEvents: 'none',
    },
  };
};
