import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import InListFilter from '../discovery/filter/exports/InListFilter';
import { VizRedirect } from '../discovery/viz-redirect';

const WeightedForecastRedirect = ({ adminName, attributeProp }) => {
  const func = ({ monitorEvent, visualizations, openVisualization }) => {
    let payload;
    try {
      payload = JSON.parse(monitorEvent.payload);
    } catch (e) {
      return <Redirect to='/' />;
    }
    const linkedContent = visualizations
      ? visualizations.find(
          viz =>
            viz.name === monitorEvent.monitor.name && viz.creator === adminName,
        )
      : null;
    const segmentField = linkedContent
      ? _.find(
          linkedContent.dataset.attributes,
          a => _.lowerCase(a.name) === _.lowerCase(payload[attributeProp]),
        )
      : null;
    if (linkedContent) {
      let filters = null;
      if (!_.isNil(segmentField)) {
        const segmentFilter = InListFilter(
          segmentField,
          payload.attributeValue,
        );
        filters = {
          [segmentField.name]: segmentFilter,
        };
      }
      openVisualization(linkedContent);
      return (
        <VizRedirect
          vizId={linkedContent.id}
          state={{
            // add slicers, slicerSelections here
            filters,
            monitorEventId: monitorEvent.id,
          }}
        />
      );
    }
    return <Redirect to='/' />;
  };
  func.supports = monitorEvent =>
    ['weightedforecast'].includes(monitorEvent.eventType);
  return func;
};

export default WeightedForecastRedirect;
