import Library from './component';
import { connect } from 'react-redux';
import { compose, withProps } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import Discover from '../../common/redux/actions/DiscoverActions';
import MainActions from '../../common/redux/actions/MainActions';
import DiscoverQueries from '../../common/graphql/DiscoverQueries';
import { graphql } from '@apollo/client/react/hoc';
import {
  ACCOUNT_SELECTORS,
  isAdmin,
} from '../../common/redux/selectors/AccountSelectors';
import { TABLE_SELECTORS } from '../../common/redux/selectors/TableSelectors';
import _ from 'lodash';
import { CreateVizOptions, DatasetQueries } from '../../common/graphql';

const mapStateToProps = (state, ownProps) => {
  return {
    saveError: state.discover.saveError,
    isAdvanced: state.main.advanced,
    isAdmin: isAdmin(state.account),
    currentUser: ACCOUNT_SELECTORS.getCurrentUser(state),
    search: TABLE_SELECTORS.getSearch(state, ownProps),
    tags: TABLE_SELECTORS.getSearchTags(state, ownProps),
    chartTypes: TABLE_SELECTORS.getSearchChartTypes(state, ownProps),
    searchDatasets: TABLE_SELECTORS.getSearchDatasets(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearSaveError: () => {
      dispatch(Discover.setSaveError(undefined));
    },
    openDiscovery: discovery => {
      dispatch(Discover.openDiscovery(discovery));
    },
    openVisualization: discovery => {
      dispatch(Discover.openVisualization(discovery, ownProps.history));
    },
    setSearch: search => {
      dispatch(MainActions.setSearch(ownProps.domain, search));
    },
    setSearchTags: tags => {
      dispatch(MainActions.setSearchTags(ownProps.domain, tags));
    },
    setSearchChartTypes: chartTypes => {
      dispatch(MainActions.setSearchChartTypes(ownProps.domain, chartTypes));
    },
    setSearchDatasets: datasets => {
      dispatch(MainActions.setSearchDatasets(ownProps.domain, datasets));
    },
  };
};

const VizQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  options: () => ({
    fetchPolicy: 'no-cache',
  }),
  props: store => {
    const visualizations = store.data.visualizations
      ? store.data.visualizations.map(v => ({
          ...v,
          dataset: _.find(store?.data?.datasets, { id: v.datasetId }),
          discoveryType: 'VISUALIZATION',
        }))
      : [];
    return { visualizations };
  },
});

const DatasetQuery = graphql(DatasetQueries.DatasetDetailQuery, {
  props: store => {
    const datasets =
      store.data && store.data.datasets ? [...store.data.datasets] : [];
    return { datasets };
  },
});

const SaveVizMutation = graphql(DiscoverQueries.CreateVisualizationMutation, {
  options: CreateVizOptions,
  props: ({ mutate }) => ({
    newVisualization(viz) {
      return mutate({
        variables: {
          viz,
        },
        onError: error => {
          console.log('creating visualization error', error);
        },
      });
    },
  }),
});

export default compose(
  withRouter,
  withProps(() => {
    return {
      domain: 'library',
    };
  }),
  VizQuery,
  DatasetQuery,
  SaveVizMutation,
  connect(mapStateToProps, mapDispatchToProps),
)(Library);
