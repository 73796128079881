import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  DiscoveryToolbarNavbarHeight,
  IDiscoverEmotionTheme,
  svgColorizePartial,
} from '../../common/emotion';
import { Nav, Navbar, NavItem } from '@sugar-discover/react-bootstrap-wrapper';

export const ToolbarRightNav = styled(Nav)(
  ({
    theme: { colors: { TabBackground } = {} as any } = {} as any,
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    & > li {
      width: 56px;
      min-width: 56px;

      a:focus {
        color: inherit !important;
        background-color: ${TabBackground} !important;
      }
    }
  `,
);

export const ActionItemRightNav = styled(Nav)`
  margin-right: 0;

  & > li {
    width: 40px;
    min-width: 40px;
  }

  .dropdown.btn-group .dropdown-toggle {
    padding: 6px 0;
  }
`;

export const SaveDropdownStyles = styled.li`
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

export const NavWithLeftMargin = ({ leftOffset, children, ...props }) => {
  return (
    <Nav
      css={css`
        width: auto;
        max-width: 50vw;
        margin-left: ${leftOffset - 10}px;
      `}
      {...props}
    >
      {children}
    </Nav>
  );
};
export const NavItemFullWidth = styled(NavItem)`
  width: 100%;
`;

export const HoverIconWrapper = styled(NavItem)(
  ({
    theme: {
      colors: {
        SugarGrayPalette: { Gray40, Gray50 },
      },
    },
  }) => css`
    & .sicon {
      color: ${Gray40};
    }
    &:hover .sicon,
    &.active .sicon {
      color: ${Gray50};
      transition: color 0.3s ease;
    }
  `,
);

export const DiscoveryToolbarNavbar = styled(Navbar)(
  ({
    theme: {
      colors: {
        PanelBackground,
        Strokes,
        ContentText,
        DropdownBackgroundHover,
        PanelIconColor,
        PanelIconHoverColor,
        PrimaryColor,
        BorderColor,
        ActiveTabIndicator,
      } = {} as any,
    } = {} as any,
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    background-color: transparent;
    border-radius: 0px;
    border: none;
    margin: 0px;

    & .container {
      width: 100%;
      padding: 0px;
      height: ${DiscoveryToolbarNavbarHeight};
    }

    #discovery-toolbar-left,
    #discovery-toolbar-right {
      padding-left: 0;
      margin-right: 12px;
      li {
        width: 56px;
        a {
          padding: 0px 15px;
        }
      }
    }

    & .nav > li {
      height: ${DiscoveryToolbarNavbarHeight};
    }
    & .nav > li:first-of-type {
      margin-left: 0px;
    }
    & .nav > li > a {
      padding: 0px 0px;
      height: 100%;
      border-width: 4px 1px 0px 1px;
      border-style: solid;
      border-color: transparent;
      pointer-events: all !important;
    }
    & .nav > li.active > a {
      background-color: ${PanelBackground};
    }
    & .nav > li.active::after {
      content: '';
      position: absolute;
      height: 2px;
      width: calc(100% - 2px);
      bottom: 0;
      left: 1px;
      background-color: ${ActiveTabIndicator};
      z-index: 999;
    }
    & .vertical-center {
      display: flex;
      justify-content: center;
    }
    & .nav > li > a:hover {
      cursor: pointer;
    }
    & .nav > li.disabled > a:hover {
      cursor: default;
    }

    &-right {
      margin-right: 0px;
    }
    &-actions {
      margin-right: 82px;
    }

    & .nav li.panel-icon-dropdown {
      display: flex;
      align-items: center;
      padding: 0px;
      justify-content: center;
    }
    & .nav li.panel-icon-dropdown .btn.dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      background: none;
      box-shadow: none;
    }
    & .nav li.panel-icon-dropdown .btn.dropdown-toggle:disabled {
      cursor: default;
    }
    & .nav li.panel-icon-dropdown .dropdown-save-icon {
      display: flex;
      align-items: center;
    }
    & .nav li.panel-icon-dropdown .dropdown-save-icon.disabled {
      pointer-events: none;
    }
    & .nav li.panel-icon-dropdown .dropdown-save-icon:hover {
      cursor: pointer;
    }
    & .dropdown-menu {
      padding: 0px 0px;
      top: 45px;
    }

    & .dropdown-menu > li:not(:last-of-type) {
      border-bottom: 1px ${Strokes} solid;
    }
    & .dropdown-menu > li:not(.disabled) > a:hover {
      color: ${ContentText};
      background-color: ${DropdownBackgroundHover} !important;
    }

    ${svgColorizePartial({
      fromColor: PanelIconColor,
      toColor: PanelIconHoverColor,
    })(`
    & .nav li.panel-icon-dropdown .btn.dropdown-toggle:not(:disabled):hover .down-chevron.hovered,
    & .nav li.panel-icon-dropdown .btn.dropdown-toggle:not(:disabled):hover .dropdown-save-icon:hover
  `)}

    & li.active > a {
      border-top: 1px solid ${BorderColor};
    }

    & li:hover > a {
      color: ${ContentText};
    }

    #discovery-toolbar-left {
      z-index: 100;
      position: relative;
      border-bottom: none;
    }

    .down-chevron {
      padding-left: 5px;
      padding-right: 5px;
    }
    #discovery-toolbar-left,
    #discovery-toolbar-right {
      li.active > a {
        background-color: ${PanelBackground} !important;
      }
    }

    .dropdown.open .down-chevron {
      .sicon-chevron-down {
        color: ${PrimaryColor} !important;
      }
      border-color: ${PrimaryColor};
      border-radius: 3px;
      border-style: solid;
      border-width: 2px;
      padding-left: 2px;
      padding-right: 5px;
    }
  `,
);

export const ToolbarDropdownRoot = styled.div(
  ({ theme: { colors: { ShareDropdownColor } = {} as any } = {} }: any) => css`
    display: flex;
    align-items: center;
    color: ${ShareDropdownColor};
  }
`,
);
