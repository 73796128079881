import { messages } from '../../i18n';
import { PrimaryButton } from '../../ui';
import { IMainHeaderSectionProps } from './main-section-header.interface';
import {
  ActionButtons,
  StyledMainSectionHeader,
} from './main-section-header.styles';

export const MainSectionHeader = ({
  headerLabel,
  isButtonVisible = false,
  onClickHandler,
  advancedActions = <></>,
}: IMainHeaderSectionProps) => {
  return (
    <StyledMainSectionHeader>
      <span>{headerLabel}</span>
      <ActionButtons>
        {advancedActions}
        {isButtonVisible && (
          <PrimaryButton onClick={onClickHandler}>
            {messages.calcDialog.createButtonText}
          </PrimaryButton>
        )}
      </ActionButtons>
    </StyledMainSectionHeader>
  );
};
