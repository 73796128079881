import {
  ProfileIconHoverContainer,
  ProfileIconWrapper,
} from './sugar-profile-dropdown.styles';
import {
  DownChevron,
  LogoutIcon,
  ProfileIcon,
  SettingsCogIcon,
} from '../../icons';
import { messages } from '../../i18n';
import { Fragment, useState } from 'react';
import { useOutsideClick } from '../ClickOutsideListener';
import {
  ProfileSubMenuListStyled,
  ProfileSubMenuWrapper,
  ProfileWrapperIcon,
} from './sugar-profile-dropdown.styles';
import { filter, isFunction, map } from 'lodash';
import {
  IconWrapper,
  MenuItemStyled,
} from '../../discovery/sidebar-navigation/sub-menu/sub-menu.styles';
import { IProfileDropdownProps } from './sugar-profile-dropdown.interface';

export const SugarProfileDropdown = ({
  isAdmin,
  setShowLogoutDialog,
  menuItemSelected,
}: IProfileDropdownProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const outsideClickRef = useOutsideClick<HTMLDivElement>(handleClose, open);
  const renderContent = ({ icon, label }) => (
    <Fragment>
      <IconWrapper>{icon}</IconWrapper>
      <span>{label}</span>
    </Fragment>
  );

  const menuItems = [
    {
      key: 'profile-link',
      label: messages.nav.profile,
      icon: <ProfileIcon size={12} />,
      handleOnItemClick: () => menuItemSelected('account/user'),
      display: true,
    },
    {
      key: 'admin-link',
      label: messages.nav.admin,
      icon: <SettingsCogIcon size={12} />,
      handleOnItemClick: () => menuItemSelected('administration'),
      display: isAdmin,
    },
    {
      key: 'logout-link',
      label: messages.nav.logout,
      icon: <LogoutIcon size={12} />,
      handleOnItemClick: () => setShowLogoutDialog(true),
      display: true,
    },
  ];
  return (
    <>
      <ProfileIconHoverContainer onClick={handleToggle} ref={outsideClickRef}>
        <ProfileIconWrapper id={'profile-icon'}>
          <ProfileIcon size={18} />
        </ProfileIconWrapper>
        <ProfileSubMenuWrapper>
          <ProfileWrapperIcon
            className={`kabob-dropdown ${open ? 'active' : 'inactive'}`}
          >
            <DownChevron size={12} />
          </ProfileWrapperIcon>
        </ProfileSubMenuWrapper>
      </ProfileIconHoverContainer>
      <ProfileSubMenuListStyled open={open}>
        {map(
          filter(menuItems, { display: true }),
          ({ key, label, icon, handleOnItemClick }) => (
            <MenuItemStyled
              key={key || label}
              onClick={() => {
                if (isFunction(handleOnItemClick)) {
                  handleOnItemClick();
                }
                handleClose();
              }}
            >
              <div>{renderContent({ icon, label })}</div>
            </MenuItemStyled>
          ),
        )}
      </ProfileSubMenuListStyled>
    </>
  );
};
