import { css } from '@emotion/react';
import { font, ModernBorderRadius } from '../../common/emotion/mixins';

export const pivotConditionalFormattingStyles = ({
  theme: {
    colors: { LightFontWeight, ContentBackground, Strokes },
  },
}) => css`
  .pivot-conditional-formatting__form-group {
    ${font({ size: '12px', weight: LightFontWeight })}
    background-color: ${ContentBackground};
    border: 1px solid ${Strokes};
    border-radius: ${ModernBorderRadius};
    padding: 12px;
  }
  .pivot-conditional-formatting .checkbox {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  .pivot-conditional-formatting .checkbox:last-of-type {
    margin-bottom: 0px;
  }
  .pivot-conditional-formatting .checkbox label {
    ${font({ weight: LightFontWeight })}
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 32px;
    padding: 0px;
  }
  .pivot-conditional-formatting .checkbox input {
    margin: 0px;
    margin-right: 8px;
    position: initial;
  }
  .pivot-conditional-formatting__dropdown {
    margin-left: 16px;
    min-width: 170px;
    flex: 1;
  }
  .pivot-conditional-formatting__dropdown.btn-group .dropdown-toggle,
  .portal-dropdown .pivot-conditional-formatting__dropdown-menu li > a {
    padding: 0px;
    height: 30px;
    display: flex;
    align-items: center;
    box-sizing: content-box;
  }
`;
