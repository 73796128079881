import {
  ComponentClass,
  Component,
  useContext,
  useEffect,
  useState,
} from 'react';
import Discover from '../common/redux/actions/DiscoverActions';
import { connect, useDispatch } from 'react-redux';
import ClosablePanel from '../common/widgets/ClosablePanel';
import { VizLayoutPanel } from './viz-layout-panel/viz-layout-panel.component';
import _ from 'lodash';
import { ChartSpecs } from './ChartSpecs';
import VizSettingsPanel from './settings/viz-settings-panel.component';
import { VIZ } from '../common/';
import { VizFormatting } from './viz-formatting';
import classnames from 'classnames';
import ReportDetail from '../views/ReportDetail';
import { messages } from '../i18n';
import {
  useOpenDiscoveryPresentStateSelector,
  useOpenVizChartSpec,
  useOpenVizLayoutSelector,
  useVizOptionSelector,
  useConfigPanelDetailSelector,
} from '../common/redux/selectors/viz-selector.hook';
import { useMultiSelectDrillLinkDisable } from './viz-config.util';
import { IViz } from './interfaces';
import { SelectDropdown, SelectItem } from '../ui/dropdowns/select-dropdown';
import { ToastContext } from '../components/toast/toast-launcher';
import { ErrorBoundaryComponentProps } from '../components/error-boundary';
import { ErrorBoundary } from 'react-error-boundary';

export const VIZ_SELECT_DROPDOWN_HEIGHT = 36;

const PanelContent = props => {
  let content: any = [];
  switch (props.configPanelDetail) {
    case 'layout':
      content = [
        <VizChartSelection
          key='chart-selection'
          {...props}
          viz={props.discovery.viz}
        />,
        <VizLayoutPanel
          key='layout-panel'
          {...props}
          viz={props.discovery.viz}
        />,
      ];
      break;
    case 'format':
      content = (
        <VizFormatting {...props} vizId={props.discovery?.id as string}>
          <VizSettingsPanel vizId={props.discovery?.id as string} />
        </VizFormatting>
      );
      break;
    case 'detail':
      content = <ReportDetail />;
      break;
    default:
      break;
  }
  return <div className='viz-config-panel'>{content}</div>;
};

const mapStateToProps = state => {
  return {
    isAdvancedMode: state.main.advanced,
  };
};

interface VizComponentChartSelectionProps {
  viz: IViz;
  isAdvancedMode?: boolean;
  onChangeViz: (id: string) => void;
}

interface IVizChartSelectionDispatchProps {
  changeViz: (id: string) => void;
}

class UnconnectedVizChartSelection extends Component<
  VizComponentChartSelectionProps & IVizChartSelectionDispatchProps
> {
  state;
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }
  toggleDropdownMenu() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }
  render() {
    const spec = ChartSpecs[this.props.viz.chartType];
    const vizOptions = _.values(ChartSpecs)
      .filter(cs => !cs.hidden)
      .filter(cs =>
        !this.props.isAdvancedMode ? !cs.isAdvancedModeOnly : true,
      )
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        } else {
          return 0;
        }
      })
      .map((chartSpec, idx) => {
        const firstItemStyles =
          idx === 0
            ? {
                marginTop: '0.8rem',
                marginBottom: '0.5rem',
                paddingTop: '0',
                paddingBottom: '0',
              }
            : {};
        return (
          <SelectItem
            key={chartSpec.id}
            onClick={() => this.props.changeViz(chartSpec.id)}
            className={classnames('chart-type', {
              active: this.props.viz.chartType === chartSpec.id,
            })}
            sx={{
              margin: '0.25rem 0',
              ...firstItemStyles,
            }}
          >
            <span className='icon'>{chartSpec.icon}</span>
            <span className='name'>
              {_.get(messages, chartSpec.name, chartSpec.name)}
            </span>
          </SelectItem>
        );
      });
    return (
      <div className={'viz-chart-type'} style={{ marginBottom: '0.5rem' }}>
        <label>{messages.configPanel.chartTypeSectionLabel}</label>
        <SelectDropdown
          aria-label={messages.layoutPanel.selectVisualizationType}
          title={[
            <span key={'icon'} className='icon'>
              {spec.icon}
            </span>,
            <span key={'name'} className='name'>
              {_.get(messages, spec.name, spec.name)}
            </span>,
          ]}
          headerSx={{
            minWidth: '100%',
            width: '100%',
            height: `${VIZ_SELECT_DROPDOWN_HEIGHT}px`,
            padding: '5px 10px',
          }}
        >
          {vizOptions}
        </SelectDropdown>
      </div>
    );
  }
}

const VizChartSelection = connect(
  mapStateToProps,
  (dispatch, ownProps: any) => ({
    changeViz: type => {
      dispatch(Discover.changeVizType(ownProps.discovery.id, type));
    },
  }),
)(
  UnconnectedVizChartSelection as ComponentClass<
    VizComponentChartSelectionProps
  >,
);

const VizConfigPanel = ({ vizId }) => {
  const dispatch = useDispatch();

  const discovery = useOpenDiscoveryPresentStateSelector({
    discoveryId: vizId,
  });
  const leftPanelWidth = parseInt(
    useVizOptionSelector({
      discoveryId: discovery.id,
      option: 'leftPanelWidth',
      skipParse: true,
      defaultValue: `${VIZ.LEFT_PANEL_MIN_WIDTH}`,
    }),
  );
  const configPanelDetail = useConfigPanelDetailSelector({
    discoveryId: discovery.id,
  });
  const chartSpec = useOpenVizChartSpec({ discoveryId: discovery.id });
  const layout = useOpenVizLayoutSelector({ discoveryId: discovery.id });

  const setConfigPanelDetail = _panelKey => {
    dispatch(Discover.setConfigPanelDetail(discovery.id, _panelKey));
  };

  const setLeftPanelWidth = _leftPanelWidth => {
    dispatch(
      Discover.setSettingForViz({
        id: discovery.id,
        setting: 'leftPanelWidth',
        value: _leftPanelWidth,
      }),
    );
  };

  const onClose = () => {
    setConfigPanelDetail(null);
  };

  const onResize = newWidth => {
    setLeftPanelWidth(newWidth);
  };

  const titleMap = {
    layout: messages.configPanel.layoutTitle,
    format: messages.configPanel.formatTitle,
    detail: messages.configPanel.detailTitle,
  };
  const open = !_.isEmpty(configPanelDetail);

  const title = open
    ? titleMap[configPanelDetail]
    : messages.configPanel.unknown;

  const [
    disabledDrillLinkAcknowledged,
    setDisabledDrillLinkAcknowledged,
  ] = useState(false);

  const { multiSelectDrillLinkDisabled } = useMultiSelectDrillLinkDisable(
    _.get(chartSpec, 'id', ''),
    layout,
  );

  const { showToast } = useContext(ToastContext) as any;
  useEffect(() => {
    if (!disabledDrillLinkAcknowledged && multiSelectDrillLinkDisabled) {
      showToast({
        children: <span>{messages.layoutPanel.drillLinkingDisabled}</span>,
        onClose: () => {
          setDisabledDrillLinkAcknowledged(true);
        },
        label: messages.toast.notice,
      });
    }
  }, [disabledDrillLinkAcknowledged, multiSelectDrillLinkDisabled, showToast]);

  return (
    <ClosablePanel
      slideTo='left'
      expanded={open}
      title={title}
      onClose={onClose}
      onOpen={_.noop}
      panelWidth={leftPanelWidth}
      setPanelWidth={onResize}
    >
      <ErrorBoundary {...ErrorBoundaryComponentProps}>
        <PanelContent
          discovery={discovery}
          configPanelDetail={configPanelDetail}
        />
      </ErrorBoundary>
    </ClosablePanel>
  );
};

export default VizConfigPanel;
