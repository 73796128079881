import { useEffect, useRef, useState } from 'react';
import {
  ISearchableDropdownOption,
  SearchableDropdown,
} from '../../../../ui/dropdowns/searchable-dropdown';
import { head, map } from 'lodash';
import Checkbox from '@mui/material/Checkbox';
import { SecondaryButton } from '../../../../ui';
import { TrashIcon } from '../../../../icons';
import { useResizeObserver } from '../../../../common/utilities/resize-observer';
import {
  useDatasetIngestionCallbacks,
  useDatasetIngestionVars,
  useJoinedTablesData,
  useJoiningTableCallbacks,
} from './data-ingestion.hook';
import { IJoinedTableData } from './data-ingestion.interfaces';
import {
  Label,
  TableColumn,
  TableColumnHeader,
  TableColumnHeaderLast,
  TrackingCloseDateDiv,
  useDatasetIngestionStyles,
  useJoiningTablesStyles,
  SearchableColumn,
} from './data-ingestion.styles';
import { DatasetStatusIcon } from '../../../../datasets/utils';
import { messages } from '../../../../i18n';
import { DATASET_STATUS, MAX_ALIAS_LENGTH } from '../../../../common/Constants';
import InputBase from '@mui/material/InputBase';
import { IJoiningTable } from '../../edit-dataset-settings.interfaces';
import { useDiscoverTheme } from '../../../../common/emotion';
import {
  SelectDropdown,
  SelectItem,
} from '../../../../ui/dropdowns/select-dropdown';
import { makeDropdownOption } from '../../../../ui/dropdowns/searchable-dropdown/searchable-dropdown.utils';

export const DataIngestionSettings = () => {
  const {
    topSectionStyle,
    datasetStatusStyle,
    primaryModuleContainerStyle,
    primaryModuleDropdownStyle,
    rewindHistoryStyle,
    continueTrackingStyle,
    trackingPeriodLabelStyle,
    trackingPeriodDropdownStyle,
    trackingFiscalLabelStyle,
    trackingFiscalDropdownStyle,
    rewindHistoryContainerStyle,
    rewindHistoryDropdownStyle,
    importHistoryContainerStyle,
    importHistoryLabelStyle,
    importHistoryCheckboxStyle,
  } = useDatasetIngestionStyles();

  const {
    datasetStatusType,
    primaryModule: {
      defaultValue: primaryModuleDefaultValue,
      options: primaryModuleOptions,
    },
    lastEntryDate: {
      defaultValue: lastEntryDateDefaultValue,
      options: lastEntryDateOptions,
    },
    periodType: {
      defaultValue: periodTypeDefaultValue,
      options: periodTypeOptions,
    },
    periodMonthEnd: {
      defaultValue: periodMonthEndDefaultValue,
      options: periodMonthEndOptions,
    },
    importHistory,
  } = useDatasetIngestionVars();

  const {
    handlePrimaryModuleChange,
    handleRewindHistoryChange,
    handlePeriodTypeChange,
    handleLastEntryDateChange,
    handleImportHistoryChange,
  } = useDatasetIngestionCallbacks();

  return (
    <div>
      <div style={topSectionStyle} className={'top-section'}>
        <div style={datasetStatusStyle} className={'dataset-status'}>
          <Label>{messages.editDatasetPanel.datasetStatus}</Label>
          <DatasetStatusIcon statusType={datasetStatusType as DATASET_STATUS} />
        </div>
        <div style={primaryModuleContainerStyle} className={'primary-module'}>
          <Label>{messages.editDatasetPanel.primaryModule}</Label>
          <SearchableDropdown
            id={'primaryModule'}
            placeholder={messages.editDatasetPanel.moduleName}
            sx={primaryModuleDropdownStyle}
            popperSx={{
              zIndex: 1050,
            }}
            defaultValue={primaryModuleDefaultValue}
            options={primaryModuleOptions}
            onSelect={handlePrimaryModuleChange}
          />
        </div>
        <div style={rewindHistoryStyle} className={'history'}>
          <Label>{messages.editDatasetPanel.rewindHistoryOn}</Label>
          <div style={rewindHistoryContainerStyle}>
            <SearchableDropdown
              id={'lastEntryDateType'}
              style={rewindHistoryDropdownStyle}
              defaultValue={lastEntryDateDefaultValue}
              options={lastEntryDateOptions}
              onSelect={handleRewindHistoryChange}
              popperSx={{
                zIndex: 1050,
              }}
            />
          </div>
        </div>
        <div style={continueTrackingStyle} className={'other-history'}>
          <Label>{messages.editDatasetPanel.continueTrackingDate}</Label>
          <TrackingCloseDateDiv>
            <span style={trackingPeriodLabelStyle}>
              {messages.editDatasetPanel.periodType}
            </span>
            <SelectDropdown
              id={'lastEntryDateType'}
              style={trackingPeriodDropdownStyle}
              title={periodTypeDefaultValue?.label}
              headerSx={{
                width: '100%',
              }}
            >
              {map(periodTypeOptions, ({ value, label }) => (
                <SelectItem onClick={() => handlePeriodTypeChange(value)}>
                  {label}
                </SelectItem>
              ))}
            </SelectDropdown>
          </TrackingCloseDateDiv>
          <TrackingCloseDateDiv>
            <span style={trackingFiscalLabelStyle}>
              {messages.editDatasetPanel.fiscalMonthEnd}
            </span>
            <SelectDropdown
              id={'lastEntryDateType'}
              style={trackingFiscalDropdownStyle}
              title={periodMonthEndDefaultValue?.label}
              headerSx={{
                width: '100%',
              }}
            >
              {map(periodMonthEndOptions, ({ value, label }) => (
                <SelectItem onClick={() => handleLastEntryDateChange(value)}>
                  {label}
                </SelectItem>
              ))}
            </SelectDropdown>
          </TrackingCloseDateDiv>
          <div style={importHistoryContainerStyle}>
            <Label style={importHistoryLabelStyle}>
              {messages.editDatasetPanel.importHistory}
            </Label>
            <Checkbox
              style={importHistoryCheckboxStyle}
              checked={importHistory}
              onChange={(_e, checked) => handleImportHistoryChange(checked)}
            />
          </div>
        </div>
      </div>
      <JoiningTable />
    </div>
  );
};

const JoiningTable = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerRect = useResizeObserver(containerRef?.current);

  // needed for resizing during tab switching
  const [_hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const { handleAddJoinTable } = useDatasetIngestionCallbacks();

  const joinedTablesData = useJoinedTablesData();

  const { width: containerWidth = 0 } =
    containerRect ?? ({} as DOMRectReadOnly);

  const deleteWidth = 60;
  const columnWidth = (containerWidth - deleteWidth) / 5;

  const columnHeaders = [
    messages.editDatasetPanel.name,
    messages.editDatasetPanel.alias,
    messages.editDatasetPanel.parent,
    messages.editDatasetPanel.joinForeignKey,
    messages.editDatasetPanel.fieldAttributes,
  ];

  const {
    bottomSectionStyle,
    joinsTableContainerStyle,
    joiningTableContainerStyle,
    joinsLabelStyle,
    joinsControlContainerStyle,
    defaultContainerStyle,
    containerStyle,
    headerContainerStyle,
  } = useJoiningTablesStyles({
    columnHeadersLength: columnHeaders?.length,
    columnWidth,
    deleteWidth,
  });

  if (containerWidth === 0) {
    return <div ref={containerRef} style={defaultContainerStyle} />;
  }

  return (
    <div style={bottomSectionStyle} className={'bottom-section'}>
      <div style={joinsTableContainerStyle}>
        <span style={joinsLabelStyle}>{messages.editDatasetPanel.joins}</span>
        <div style={joinsControlContainerStyle}>
          <SecondaryButton onClick={handleAddJoinTable}>
            {messages.editDatasetPanel.addNew}
          </SecondaryButton>
        </div>
      </div>
      <div style={joiningTableContainerStyle}>
        <div ref={containerRef} style={containerStyle}>
          <div style={headerContainerStyle} className={'headers'}>
            {map(columnHeaders, (label, idx) => (
              <TableColumnHeader className={'header'} colNum={idx + 1}>
                {label}
              </TableColumnHeader>
            ))}
            <TableColumnHeaderLast colLength={columnHeaders?.length} />
          </div>
          {map<IJoiningTable>(
            joinedTablesData,
            (rowData: IJoinedTableData, rowIdx) => (
              <JoiningTableRow
                rowData={rowData}
                rowIdx={rowIdx}
                columnHeadersLength={columnHeaders?.length}
                columnWidth={columnWidth}
                deleteWidth={deleteWidth}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

const JoiningTableRow = ({
  rowData,
  rowIdx,
  columnHeadersLength,
  columnWidth,
  deleteWidth,
}: {
  rowData: IJoinedTableData;
  rowIdx: number;
  columnHeadersLength: number;
  columnWidth: number;
  deleteWidth: number;
}) => {
  const { colors: { DarkRed } = {} } = useDiscoverTheme();

  const {
    joiningRowStyle,
    aliasInputStyle,
    deleteColumnStyle,
  } = useJoiningTablesStyles({
    columnHeadersLength,
    columnWidth,
    deleteWidth,
  });
  const { handleRowChange, handleDelete } = useJoiningTableCallbacks(rowIdx);

  const {
    name,
    alias: providedAlias,
    nameOptions,
    parentName,
    parentNameOptions,
    foreignKey,
    foreignKeyOptions,
    filteredAttributes,
    fieldAttributesOptions,
  } = rowData;

  const aliasRef = useRef<HTMLInputElement>();

  return (
    <div
      key={`${name}-${rowIdx}`}
      style={joiningRowStyle}
      className={'joins-row'}
    >
      <TableColumn width={columnWidth}>
        <SearchableColumn
          id={`join-table-${rowIdx}-name`}
          placeholder={messages.editDatasetPanel.moduleName}
          defaultValue={makeDropdownOption(name)}
          options={nameOptions}
          onSelect={(options: ISearchableDropdownOption[]) => {
            const { value } = head(options) || {};

            handleRowChange('name', value);
          }}
          popperSx={{
            zIndex: 1050,
          }}
        />
      </TableColumn>
      <TableColumn width={columnWidth}>
        <InputBase
          inputRef={aliasRef}
          id={`join-table-${rowIdx}-alias`}
          inputProps={{
            maxLength: MAX_ALIAS_LENGTH,
            pattern: `[A-Z_a-z]{0,${MAX_ALIAS_LENGTH}}`,
          }}
          placeholder={messages.editDatasetPanel.alias}
          sx={{
            ...aliasInputStyle,
            ...(aliasRef?.current?.validity?.valid
              ? {}
              : { borderColor: DarkRed }),
          }}
          defaultValue={aliasRef?.current?.value ?? providedAlias}
          onInput={e => {
            const value = (e?.target as HTMLInputElement)?.value;

            handleRowChange('alias', value);
          }}
        />
      </TableColumn>
      <TableColumn width={columnWidth}>
        <SearchableColumn
          id={`join-table-${rowIdx}-parent-name`}
          placeholder={messages.editDatasetPanel.parent}
          defaultValue={makeDropdownOption(parentName)}
          options={parentNameOptions}
          onSelect={(options: ISearchableDropdownOption[]) => {
            const { value } = head(options) || {};

            handleRowChange('parentName', value);
          }}
          popperSx={{
            zIndex: 1050,
          }}
        />
      </TableColumn>
      <TableColumn width={columnWidth}>
        <SearchableColumn
          id={`join-table-${rowIdx}-foreign-key`}
          placeholder={messages.editDatasetPanel.selectField}
          defaultValue={{ value: foreignKey, label: foreignKey }}
          options={foreignKeyOptions}
          onSelect={(options: ISearchableDropdownOption[]) => {
            const { value } = head(options) || {};

            handleRowChange('foreignKey', value);
          }}
          popperSx={{
            zIndex: 1050,
          }}
        />
      </TableColumn>
      <TableColumn width={columnWidth}>
        <SearchableColumn
          id={`join-table-${rowIdx}-filtered-attributes`}
          placeholder={messages.editDatasetPanel.selectFields}
          defaultValue={map(filteredAttributes, fAttr => ({
            value: fAttr,
            label: fAttr,
          }))}
          options={fieldAttributesOptions}
          onSelect={(options: ISearchableDropdownOption[]) => {
            const value = map(options, 'value');

            handleRowChange('filteredAttributes', value);
          }}
          popperSx={{
            zIndex: 1050,
          }}
          multiSelect
        />
      </TableColumn>
      <TableColumn width={deleteWidth}>
        <div style={deleteColumnStyle}>
          <TrashIcon onClick={() => handleDelete()} size={14} />
        </div>
      </TableColumn>
    </div>
  );
};
