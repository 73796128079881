import { css } from '@emotion/react';
import { font } from '../../common/emotion/mixins';
import styled from '@emotion/styled';

export const libraryDashletStyles = css`
  .library {
    flex: 1;
  }
  .library-header {
    ${font({ size: '20px' })}
    padding: 16px;
    margin: 0px;
  }
  .report-select-header-cell {
    padding: 10px;
  }
`;

export const TextSearchFieldWrapper = styled.div`
  min-width: 160px;
  width: 35%;
  padding-right: 10px;
`;

export const TagWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 65%;

  .tag-slug {
    font-size: 12px;
  }
`;

export const FilterWidgetItem = styled.div`
  width: calc(100% / 3);

  &:not(:last-child) {
    padding-right: 10px;
  }

  > span {
    width: 100%;
    display: block;
  }
`;

export const EmptyView = styled('div')(
  ({
    theme: {
      colors: { ContentText, ContentBackground, TableBorderColor } = {} as any,
    } = {} as any,
  }: any) => css`
    width: 100%;
    height: 50px;
    line-height: 43px;
    font-size: 14px;
    background-color: ${ContentBackground};
    border-bottom: 1px solid ${TableBorderColor};
    text-align: center;
    color: ${ContentText};
  `,
);

export const Root = styled('div')(
  ({
    theme: { colors: { TableHeaderColor } = {} as any } = {} as any,
  }: any) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 1px);
    .ReactVirtualized__Table__headerRow {
      background-color: ${TableHeaderColor};
    }
  `,
);

export const HeaderCell = styled('div')`
  div {
    padding: 0px;
  }
`;

export const ListHeader = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px;
  flex: 0 1 auto;
`;
