import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import _ from 'lodash';
import inflection from 'lodash-inflection';
import { AutoSizer } from 'react-virtualized';
import BaseInsightComponent from '../Common';
import {
  CUSTOM_FORMAT_ANNOTATION,
  DATA_FORMATTER,
  DATA_TYPE_FORMAT,
  NULL_DISPLAY,
} from '../../../common/';
import Discover from '../../../common/redux/actions/DiscoverActions';
import ReportParamFunc from './report/func';

import ArrowDown from '../../../../images/sdd/arrow_down.svg';
import ArrowUp from '../../../../images/sdd/arrow_up.svg';
import { VIZ_SELECTORS } from '../../../common/redux/selectors/viz-selectors';
import { messages } from '../../../i18n';
import JSON5 from 'json5';
import { useDiscoverTheme } from '../../../common/emotion';

_.mixin(inflection);

const getFormatterFromDataset = (dataset, measure) => {
  // Measure may not be in-play. Lookup in Dataset
  let formatter = null;
  let customFormatter = null;
  const measureAttribute = dataset?.attributes.find(a => a.name === measure);
  if (measureAttribute && !_.isEmpty(measureAttribute.formatType)) {
    formatter = DATA_TYPE_FORMAT.getFormatterByName(
      measureAttribute.formatType,
    );
    // if it is still empty, try to find it by key
    if (_.isNil(formatter)) {
      formatter = DATA_FORMATTER[measureAttribute.formatType.toUpperCase()];
      formatter = _.isNil(formatter) ? DATA_FORMATTER.NUMBER : formatter;
    }
    if (measureAttribute.formatType === 'Custom') {
      const customAnnotation = _.find(measureAttribute.annotations, {
        key: CUSTOM_FORMAT_ANNOTATION,
      });
      try {
        customFormatter = JSON5.parse(customAnnotation?.value);
      } catch {
        console.log('error parsing annotation value');
      }
    }
  }
  return { formatter, customFormatter };
};

const BiggestMoverComponent = ({
  monitorEvent,
  dataset,
  viz,
  newVisualization,
  useFiscalCalendar,
  i18nPrefs = {},
}) => {
  const { eventData, excluded } = monitorEvent;
  const theme = useDiscoverTheme();
  // find the highest value, bars are sized based on this
  let max = _.max(
    [
      ...Object.values(eventData.top),
      ...Object.values(eventData.bottom),
    ].map(stats => Math.max(stats.current, stats.previous)),
  );

  // Check to see if all values are negative. If so our max is actually min. Bars will grow in amount below zero
  if (max <= 0) {
    max = _.min(
      [
        ...Object.values(eventData.top),
        ...Object.values(eventData.bottom),
      ].map(stats => Math.min(stats.current, stats.previous)),
    );
  }
  const getRelativeValueClass = increasesAreGood => {
    return increasesAreGood ? 'top' : 'bottom';
  };

  const getCommonRenderingValues = (stats, segment) => {
    const increasesAreGood =
      _.isNil(eventData.increasesAreGood) || eventData.increasesAreGood;
    const direction = increasesAreGood ? 'bottom' : 'top';

    let { formatter, customFormatter } = getFormatterFromDataset(
      dataset,
      eventData.measure,
    );
    // use the format from the measure field for the tooltips
    if (!formatter) {
      formatter = DATA_TYPE_FORMAT.getDefaultFormatterForType('Number');
    }
    const formattedSegmentValue =
      `current: ${formatter.format(
        stats.current,
        i18nPrefs,
        customFormatter,
      )}\n` +
      `previous: ${formatter.format(
        stats.previous,
        i18nPrefs,
        customFormatter,
      )}`;

    // If the pct difference is zero or negative show arrow, otherwise format the percentage
    let pctLabel = '';
    if (
      _.isNumber(stats.percentChange) &&
      stats.percentChange !== 0 &&
      stats.current !== 0 &&
      stats.previous !== 0
    ) {
      pctLabel = `${Math.round(stats.percentChange * 100)}%`;
    } else {
      pctLabel =
        stats.previous === 0 ? (
          <ArrowUp height={16} className={direction} />
        ) : (
          <ArrowDown height={16} className={direction} />
        );
    }

    const segmentFormatted = segment === 'null' ? NULL_DISPLAY : segment;

    return {
      direction,
      formattedSegmentValue,
      pctLabel,
      segmentFormatted,
      formatter,
      increasesAreGood,
    };
  };

  const getTop = () =>
    Object.entries(eventData.top)
      .sort((a, b) => b[1].percentChange - a[1].percentChange)
      .map(([segment, stats]) => {
        const {
          formattedSegmentValue,
          pctLabel,
          segmentFormatted,
          formatter,
          increasesAreGood,
        } = getCommonRenderingValues(stats, segment);
        return (
          <div
            key={`insight-row-${formattedSegmentValue}`}
            className={`dynamic-insight-row ${getRelativeValueClass(
              increasesAreGood,
            )}`}
          >
            <div className='segment' title={segmentFormatted}>
              {segmentFormatted}
            </div>
            <div
              className={`segment-bar-container ${getRelativeValueClass(
                increasesAreGood,
              )}`}
            >
              <AutoSizer>
                {({ width }) => [
                  <div
                    key={`bullet-bg-${formattedSegmentValue}`}
                    className='bullet-background'
                    title={formattedSegmentValue}
                    style={{ width: `${(stats.previous / max) * width}px` }}
                  >
                    &nbsp;
                  </div>,
                  <div
                    key={`bullet-${formattedSegmentValue}`}
                    className='bullet'
                    title={formattedSegmentValue}
                    style={{ width: `${(stats.current / max) * width}px` }}
                  >
                    &nbsp;
                  </div>,
                ]}
              </AutoSizer>
            </div>
            <div className='segment-value' title={formattedSegmentValue}>
              <span>
                {formatter.formatSmall(stats.current, i18nPrefs)}{' '}
                <span className='difference'>{pctLabel}</span>
              </span>
            </div>
          </div>
        );
      });

  const getBottom = () =>
    Object.entries(eventData.bottom)
      .sort((a, b) => b[1].percentChange - a[1].percentChange)
      .map(([segment, stats]) => {
        const {
          direction,
          formattedSegmentValue,
          pctLabel,
          segmentFormatted,
          formatter,
        } = getCommonRenderingValues(stats, segment);

        return (
          <div
            key={`insight-row-bottom-${formattedSegmentValue}`}
            className={`dynamic-insight-row ${direction}`}
          >
            <div className='segment' title={segmentFormatted}>
              {segmentFormatted}
            </div>
            <div className={`segment-bar-container ${direction}`}>
              <AutoSizer>
                {({ width }) => [
                  <div
                    key={`insight-row-bottom-bullet-bg-${formattedSegmentValue}`}
                    className='bullet-background'
                    title={formattedSegmentValue}
                    style={{ width: `${(stats.previous / max) * width}px` }}
                  >
                    &nbsp;
                  </div>,
                  <div
                    key={`insight-row-bottom-bullet-${formattedSegmentValue}`}
                    className='bullet'
                    title={formattedSegmentValue}
                    style={{ width: `${(stats.current / max) * width}px` }}
                  >
                    &nbsp;
                  </div>,
                ]}
              </AutoSizer>
            </div>
            <div className='segment-value' title={formattedSegmentValue}>
              <span>
                {formatter.formatSmall(stats.current, i18nPrefs)}
                <span className='difference'>{pctLabel}</span>
              </span>
            </div>
          </div>
        );
      });
  let period = _.flow(
    _.toLower,
    _.partialRight(_.trimEnd, 's'),
    _.words,
    _.partialRight(_.join, ' '),
  )(eventData.periodType);

  const rangeUnit = _.tail(_.split(period, ' '));

  if (eventData.periodNum > 1) {
    period = `${eventData.periodNum} ${period}`;
  }

  const i18nId = `insightFeed.${
    useFiscalCalendar ? 'fiscal' : 'calendar'
  }${_.capitalize(rangeUnit)}`;
  const displayPeriod = _.get(messages, i18nId, period);

  const partition = eventData.partitionName;
  const { partitionValue } = eventData;

  let title = `Biggest Movers in ${eventData.segment} for ${eventData.measure} (${displayPeriod}/${displayPeriod})`;
  if (partition) {
    title += ` (${partition}=${partitionValue})`;
  }

  const onClick = () => {
    const params = ReportParamFunc({ monitorEvent, dataset, viz, theme });
    const {
      chartSpec: { customFormatToggles },
      options,
    } = params;
    const colGrandTotals = _.find(
      customFormatToggles,
      _.matches({ name: 'colGrandTotals' }),
    );
    _.assign(colGrandTotals, { default: false });
    _.assign(options, { showLegendPanel: false });
    newVisualization(params);
  };

  return (
    <BaseInsightComponent title={title} excluded={excluded} onClick={onClick}>
      {getTop()}
      {getBottom()}
    </BaseInsightComponent>
  );
};
BiggestMoverComponent.supports = function(payload) {
  return (
    payload.monitorEvent.insightType &&
    payload.monitorEvent.insightType === 'BIGGEST_MOVERS'
  );
};

const mapStateToProps = (state, ownProps) => {
  const { i18nPrefs = {} } = state?.account?.currentUser;
  const useFiscalCalendar =
    VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, ownProps) &&
    VIZ_SELECTORS.getActiveVizFiscalSetting(state, ownProps) === 'true';
  return {
    useFiscalCalendar,
    i18nPrefs,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    newVisualization: spec => {
      dispatch(Discover.newVisualization(spec));
    },
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BiggestMoverComponent,
);

export { getFormatterFromDataset };
