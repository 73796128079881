import withStyles from '@mui/styles/withStyles';
import { Input, InputAdornment } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import styled from '@emotion/styled';
import { useCallback } from 'react';
import { opacify } from 'polished';
import { ModernBorderRadius, useDiscoverTheme } from '../../../common/emotion';

export interface ITextInputThemeArgs extends Theme {
  borderColorBase: string;
  borderColorError: string;
  borderColorFocused: string;
  borderColorErrorFocused: string;
  adornmentColorBase: string;
  adornmentColorError: string;
  adornmentBackgroundBase: string;
  adornmentBackgroundError: string;
  borderWidth: number;
  borderRadius: number;
  paddingX: number;
  height: number;
  glowColorBase: string;
  glowColorError: string;
}

export interface ITextInputTheme extends ITextInputThemeArgs {
  borderColor: string;
  adornmentColor: string;
  adornmentBackgroundColor: string;
  paddingLeft: number;
  paddingRight: number;
  glowColor: string;
}

export const DiscoverTextInputThemeProvider = ({
  theme = {},
  focused,
  hasStartAdornment,
  hasEndAdornment,
  error,
  children,
}: {
  theme: Partial<ITextInputThemeArgs>;
  focused: boolean;
  error: boolean;
  hasStartAdornment: boolean;
  hasEndAdornment: boolean;
  children;
}) => {
  const {
    colors: {
      MediumBorder,
      TextInputBorderColorErrorFocused,
      TextInputBorderColorError,
      TextInputBorderColorFocus,
      TextInputAdornmentErrorBackground,
      PanelNavBackground,
      TextInputAdornmentErrorColor,
      TextInputErrorGlow,
    } = {} as any,
  } = useDiscoverTheme();
  let {
    borderColorBase = MediumBorder,
    borderColorFocused = TextInputBorderColorFocus,
    borderColorError = TextInputBorderColorError,
    borderColorErrorFocused = TextInputBorderColorErrorFocused,
    adornmentColorBase,
    adornmentBackgroundBase = PanelNavBackground,
    adornmentColorError = TextInputAdornmentErrorColor,
    adornmentBackgroundError = TextInputAdornmentErrorBackground,
    glowColorBase = borderColorFocused,
    glowColorError = TextInputErrorGlow,
    borderWidth = 1,
    borderRadius = 2,
    height = 23,
    paddingX = 8,
  } = theme;
  let borderColor = borderColorBase;
  if (error && focused) {
    borderColor = borderColorErrorFocused;
  } else if (error) {
    borderColor = borderColorError;
  } else if (focused) {
    borderColor = borderColorFocused;
  }
  const adornmentBackgroundColor = error
    ? adornmentBackgroundError
    : adornmentBackgroundBase;
  const adornmentColor = error ? adornmentColorError : adornmentColorBase;
  const paddingLeft = hasStartAdornment ? 0 : paddingX;
  const paddingRight = hasEndAdornment ? 0 : paddingX;
  const glowColor = error ? glowColorError : glowColorBase;
  borderColorFocused = error ? borderColorErrorFocused : borderColorFocused;
  const _theme = useCallback(
    parentTheme => ({
      ...parentTheme,
      adornmentBackgroundColor,
      adornmentColor,
      borderColor,
      borderColorBase,
      borderColorFocused,
      borderColorError,
      borderColorErrorFocused,
      borderWidth,
      height,
      borderRadius,
      paddingLeft,
      paddingRight,
      glowColor,
    }),
    [
      adornmentBackgroundColor,
      adornmentColor,
      borderColor,
      borderColorBase,
      borderColorError,
      borderColorErrorFocused,
      borderColorFocused,
      borderRadius,
      borderWidth,
      glowColor,
      height,
      paddingLeft,
      paddingRight,
    ],
  );
  return <ThemeProvider theme={_theme}>{children}</ThemeProvider>;
};

export const StyledInput = withStyles(
  ({
    borderColorBase: borderColor,
    borderColorFocused,
    borderColorError,
    borderWidth,
    height,
    paddingLeft,
    paddingRight,
    glowColor,
    colors: {
      ContentBackground,
      ContentText,
      FormControlShadowColor,
      TextInputBoxShadow,
    } = {} as any,
  }: any) => {
    return {
      root: {
        borderRadius: ModernBorderRadius,
        borderStyle: 'solid',
        borderWidth,
        borderColor,
        boxShadow: `inset 0 1px 1px ${TextInputBoxShadow}`,
        height,
        marginTop: 10,
        fontWeight: 300,
        fontSize: 12,
        padding: 8,
        paddingLeft,
        paddingRight,
        margin: 4,
        color: ContentText,
        backgroundColor: ContentBackground,
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      },
      error: {
        borderColor: borderColorError,
        boxShadow: `inset 0 1px 1px ${FormControlShadowColor}`,
      },
      focused: {
        borderColor: borderColorFocused,
        outline: 0,
        boxShadow: `inset 0 1px 1px ${FormControlShadowColor}, 0 0 8px ${opacify(
          0.6,
          glowColor,
        )}`,
      },
    };
  },
)(Input);

export const StyledAdornment = styled(
  withStyles(
    ({
      borderColor,
      adornmentBackgroundColor,
      adornmentColor,
      borderRadius,
      borderWidth,
      height,
    }: any) => {
      return {
        root: {
          paddingLeft: 5,
          paddingRight: 5,
          height: height - borderWidth * 2,
          borderColor,
          backgroundColor: adornmentBackgroundColor,
          color: adornmentColor,
          borderRadius,
          borderStyle: 'solid',
          borderWidth,
        },
      };
    },
  )(InputAdornment),
)(({ position = 'start' }: any) => {
  const borderSide = position === 'start' ? 'Right' : 'Left';
  const noBorderSide = position === 'end' ? 'Right' : 'Left';
  const style = {
    borderTopWidth: '0px !important',
    borderBottomWidth: '0px !important',
    [`border${noBorderSide}Width`]: '0px !important',
    [`borderTop${borderSide}Radius`]: '0px !important',
    [`borderBottom${borderSide}Radius`]: '0px !important',
    [`borderTop${noBorderSide}Radius`]: `${ModernBorderRadius} !important`,
    [`borderBottom${noBorderSide}Radius`]: `${ModernBorderRadius} !important`,
  };
  return style as any;
});
