import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const IndicatorBubble = styled.div<{
  size?: number;
  color?: string;
  empty?: boolean;
}>(
  ({
    size,
    color,
    empty,
    theme: { colors: { ActiveTabIndicator } = {} as any } = {} as any,
  }: any) => {
    const bgColor = ActiveTabIndicator;
    return css`
      min-width: 3px;
      min-height: 3px;
      width: ${size ?? 3}px;
      height: ${size ?? 3}px;
      background-color: ${empty ? 'transparent' : color ?? bgColor};
      border: ${empty ? 2 : (size ?? 3) / 2}px solid ${color ?? bgColor};
      border-radius: 50%;
      display: inline-block;
      z-index: 2;
    `;
  },
);
