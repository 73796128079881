import { css } from '@emotion/react';
import { useCallback, memo, useRef } from 'react';
import { List } from 'react-virtualized';
import { ISlicerOption } from '../../interfaces';
import { SlicerOption } from '../../slicer-option';
import { SCROLL_ROW_HEIGHT, useSlicerTheme } from '../common';
import _, { max, min } from 'lodash';
import { useSlicerDimensions } from '../common/slicer-dimensions.hook';
import { messages } from '../../../../i18n';
import { useDiscoverTheme } from '../../../../common/emotion';
import { useResizeObserver } from '../../../../common/utilities/resize-observer';

export interface ISlicerOptionsCommonProps {
  options: ISlicerOption[];
  name: string;
  slicerErrorVisible: boolean;
  isDashletTag?: boolean;
}

export interface ISlicerOptionsProps extends ISlicerOptionsCommonProps {
  toggleOption: (option: string) => any;
  selectOnly: (option: string) => any;
}

const ROW_WIDTH_STYLE = css({
  overflow: 'hidden',
  maxHeight: SCROLL_ROW_HEIGHT,
});

const WRAPPER_BORDER_WIDTH = 1;

export const SlicerOptions = memo<ISlicerOptionsProps>(
  ({
    options,
    name,
    toggleOption,
    slicerErrorVisible,
    selectOnly,
    isDashletTag,
  }) => {
    const {
      colors: { DimText },
    } = useDiscoverTheme();
    const { isMobile } = useSlicerTheme();

    const { height, width } = useSlicerDimensions({
      optionsLength: options.length,
      slicerErrorVisible,
      isDashletTag,
    });

    const listContainerRef = useRef<HTMLDivElement>();
    const { height: listContainerHeight = 0 } = useResizeObserver(
      listContainerRef?.current,
    );

    // allow the container height to expand, but defer once it has a height
    const listHeight =
      listContainerHeight !== 0 ? min([height, listContainerHeight]) : height;

    const RowRenderer = useCallback(
      ({ key, index, style }) => {
        const { option, isSelected } = options[index];
        return (
          <SlicerOption
            isMobile={isMobile}
            style={style}
            width={width}
            css={ROW_WIDTH_STYLE}
            key={key}
            name={name}
            option={
              _.isEmpty(option) ? `(${messages.slicer.emptyOption})` : option
            }
            selected={isSelected}
            onSelect={() => toggleOption(option)}
            onSelectOnly={() => selectOnly(option)}
            disableOnly={selectOnly == _.noop}
          />
        );
      },
      [options, isMobile, width, name, toggleOption, selectOnly],
    );

    return (
      <div
        ref={listContainerRef}
        style={{
          overflowX: 'hidden',
          margin: '0 10px',
          borderColor: `${DimText}`,
          borderWidth: `${WRAPPER_BORDER_WIDTH}px`,
          borderStyle: 'solid',
          height: `${height + 2 * WRAPPER_BORDER_WIDTH}px`,
        }}
      >
        <List
          width={width - 20}
          height={max([listHeight, 0])}
          style={{
            maxHeight: listHeight,
          }}
          rowCount={options.length}
          rowHeight={SCROLL_ROW_HEIGHT}
          rowRenderer={RowRenderer}
        />
      </div>
    );
  },
);
