import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import InListFilter from '../discovery/filter/exports/InListFilter';
import { VizRedirect } from '../discovery/viz-redirect';
import { Viz as VizUtils } from '../discovery/VizUtil';

const IdleopsRedirect = ({ adminName, attributeProp }) => {
  const func = ({
    monitorEvent,
    options,
    visualizations,
    openVisualization,
  }) => {
    let payload;
    try {
      payload = JSON.parse(monitorEvent.payload);
    } catch (e) {
      return <Redirect to='/' />;
    }
    const linkedContent = visualizations
      ? visualizations.find(
          viz =>
            viz.name === monitorEvent.monitor.name && viz.creator === adminName,
        )
      : null;
    const segmentField = linkedContent
      ? _.find(
          linkedContent.dataset.attributes,
          a => _.lowerCase(a.name) === _.lowerCase(attributeProp),
        )
      : null;
    if (linkedContent) {
      let filters = null;
      if (!_.isNil(options) && !_.isNil(segmentField)) {
        // Use single operand passed by options
        const segmentFilter = InListFilter(segmentField, options.operand);
        filters = {
          [segmentField.name]: segmentFilter,
        };
      } else if (!_.isNil(segmentField)) {
        const segmentFilter = InListFilter(
          segmentField,
          payload.events.map(o => o.opportunity),
        );
        filters = {
          [segmentField.name]: segmentFilter,
        };
      }
      openVisualization(linkedContent);
      return (
        <VizRedirect
          vizId={linkedContent.id}
          state={{
            filters,
            slicerSelections: VizUtils.getSlicerSelections(linkedContent),
            slicers: VizUtils.getSlicers(linkedContent),
            removeFiltersOnFields: ['Idle Opportunity', 'Stage', 'Type'],
            monitorEventId: monitorEvent.id,
          }}
        />
      );
    }
    return <Redirect to='/' />;
  };
  func.supports = monitorEvent => ['idleops'].includes(monitorEvent.eventType);
  return func;
};

export default IdleopsRedirect;
