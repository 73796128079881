import { memo, useState, Fragment } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import {
  SubMenu,
  IconWrapper,
  MenuListStyled,
  MenuItemStyled,
  SubMenuTitle,
  SidebarSubMenuBtn,
  ShowMoreBtn,
  ScrollItems,
} from './sub-menu.styles';
import { ISubMenuProps } from './sub-menu.interfaces';
import { CloseIcon, KebabIcon } from '../../../icons';
import { isFunction } from 'lodash';
import { messages } from '../../../i18n';
import { useOutsideClick } from '../../../components/ClickOutsideListener';

export const NUMBER_OF_ITEMS_TO_SHOW = 5;

export const SubMenuComponent = memo(
  ({
    setOpenedSubMenu,
    unsetOpenedSubMenu,
    menuItems,
    collapsedTitle = null,
  }: ISubMenuProps) => {
    const [open, setIsOpen] = useState(false);
    const [menuItemLimit, setMenuItemLimit] = useState(NUMBER_OF_ITEMS_TO_SHOW);
    const moreThenMinimum = menuItems.length > NUMBER_OF_ITEMS_TO_SHOW;

    const handleClose = () => {
      setIsOpen(false);
      unsetOpenedSubMenu();
    };

    const handleToggle = e => {
      e.stopPropagation();
      if (open) {
        unsetOpenedSubMenu();
      } else {
        setOpenedSubMenu();
      }

      setIsOpen(prev => !prev);
    };

    const showMore = () => {
      setMenuItemLimit(prev => {
        if (prev === NUMBER_OF_ITEMS_TO_SHOW) {
          return menuItems.length;
        } else {
          return NUMBER_OF_ITEMS_TO_SHOW;
        }
      });
    };

    const menuListRef = useOutsideClick<HTMLUListElement>(handleClose, open);

    const renderContent = ({ icon, label }) => (
      <Fragment>
        <IconWrapper>{icon}</IconWrapper>
        <span>{label}</span>
      </Fragment>
    );

    return (
      <SubMenu>
        <SidebarSubMenuBtn
          className={classnames('sidebar-nav-item-kebab', {
            active: open,
            inactive: !open,
          })}
          onClick={handleToggle}
        >
          <KebabIcon hover size={12} />
        </SidebarSubMenuBtn>
        <MenuListStyled
          open={open}
          ref={menuListRef}
          className={'sidebar-nav-sub-menu'}
        >
          {collapsedTitle && (
            <SubMenuTitle>
              <span>{collapsedTitle}</span>
              <CloseIcon onClick={handleClose} size={12} />
            </SubMenuTitle>
          )}
          <ScrollItems
            className={classnames({
              'not-rounded': moreThenMinimum,
            })}
          >
            {menuItems
              .slice(0, menuItemLimit)
              .map(({ key, label, icon, link, handleOnItemClick }) => (
                <MenuItemStyled
                  key={key || label}
                  onClick={() => {
                    if (isFunction(handleOnItemClick)) {
                      handleOnItemClick();
                    }
                    handleClose();
                  }}
                >
                  {link ? (
                    <Link to={link}>{renderContent({ icon, label })}</Link>
                  ) : (
                    <div>{renderContent({ icon, label })}</div>
                  )}
                </MenuItemStyled>
              ))}
          </ScrollItems>
          {moreThenMinimum && (
            <MenuItemStyled onClick={showMore}>
              <ShowMoreBtn>
                <span>
                  {menuItemLimit === NUMBER_OF_ITEMS_TO_SHOW
                    ? `${messages.nav.moreReports}`
                    : messages.nav.lessReports}
                </span>
              </ShowMoreBtn>
            </MenuItemStyled>
          )}
        </MenuListStyled>
      </SubMenu>
    );
  },
);
