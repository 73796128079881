import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  IDiscoverEmotionTheme,
  useDiscoverTheme,
} from '../../common/emotion/theme';
import { ModalPortal } from '../../common/widgets/dialogs/modal-portal';
import { ModernBorderRadius } from '../../common/emotion';

export const StyledCustomFormatModal = styled(ModalPortal)(
  ({
    theme: {
      colors: { RegularFontWeight } = {} as any,
    } = {} as IDiscoverEmotionTheme,
  }: {
    theme?: IDiscoverEmotionTheme;
  }) => css`
    font-size: 12px;

    .MuiTypography-root {
      font-weight: ${RegularFontWeight};
      font-size: 12px;
    }

    .custom-format-dialog {
      width: 475px;
    }
  `,
);

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 16px;
`;

export const StyledDisplayFormatArea = styled.div(
  ({
    darkMode,
    theme: { colors: { Gray20, Gray90 } = {} as any } = {},
  }: any) => css`
    background-color: ${darkMode ? Gray90 : Gray20};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    border-radius: ${ModernBorderRadius};
  `,
);

export const StyledPreviewDiv = styled.div(() => {
  const {
    colors: { SemiboldFontWeight },
  } = useDiscoverTheme();

  return css`
    text-align: center;
    font-weight: ${SemiboldFontWeight};
    font-size: 14px;
    margin: 0;
  `;
});

export const StyledModalBody = styled.div`
  padding: 0 1rem;
`;

export const FormGroup = styled.div`
  flex: 1;
  & input {
    border-radius: ${ModernBorderRadius};
  }
`;

export const ControlLabel = styled.label(() => {
  const {
    darkMode,
    colors: {
      SugarGrayPalette: { Gray30, Gray70 },
      RegularFontWeight,
    },
  } = useDiscoverTheme();

  return css`
    color: ${darkMode ? Gray30 : Gray70};
    font-weight: ${RegularFontWeight};
    font-size: 12px;
    margin: 0;
  `;
});
