import { css } from '@emotion/react';
import { Fragment, Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { ShelfTypes } from './interfaces';
import {
  DATA_FORMATTER,
  DATA_TYPE_FORMAT,
  Types,
  USE_FISCAL_REPORTING,
} from '../common/Constants';
import _ from 'lodash';
import { compose } from 'react-recompose';
import { VIZ_SELECTORS } from '../common/redux/selectors/viz-selectors';
import { Tooltip } from '../components/ui/tooltip';
import { messages, i18nUtils } from '../i18n';
import { FieldTypeIcon, InfoIcon } from '../icons/icons/';
import {
  useOpenVizLayoutSelector,
  useVizOptionSelector,
} from '../common/redux/selectors/viz-selector.hook';
import { Viz } from './VizUtil';
import { ChartSpecs } from './ChartSpecs';
import { FieldMetadata } from '../datasets';

export const FieldTextWithAdvancedTooltip = ({
  discoveryId,
  field,
  fieldTypeOverride,
  isDragging,
  isHovering,
  isScrolling,
  isFieldMissing,
  onClick = _.noop,
}) => {
  const renderField = { ...field };

  const layout = useOpenVizLayoutSelector({ discoveryId });
  const isInShelf = layout && !!Viz.isFieldInPlay(field?.name, layout);
  const useFiscalCalendar = useVizOptionSelector({
    discoveryId,
    option: USE_FISCAL_REPORTING,
  });

  const fieldType = fieldTypeOverride ?? field?.attributeType;

  renderField.name =
    !_.isNil(field.fieldListDisplayName) && !isInShelf
      ? _.get(
          messages,
          `timeAttributes.${field.fieldListDisplayName}`,
          field.fieldListDisplayName,
        )
      : field.name ?? '';

  let displayName = i18nUtils.translateFieldName(
    messages,
    renderField,
    useFiscalCalendar,
  );

  let italicizeName = !_.isNil(field.formula);
  if (field.attributeType === Types.TIME_CALC && field.parentField) {
    italicizeName = !_.isNil(field.parentField?.formula);
  }
  if (italicizeName) {
    displayName = <i>{displayName}</i>;
  }
  const calculation = field.calculation || field.formula || '';
  const isCalc = !_.isEmpty(calculation);
  const calcContent = (
    <>
      {isCalc && [
        <hr
          key='field-tooltip-separator'
          className='field-tooltip-separator'
        />,
        <div key='field-tooltip-body' className='field-tooltip-body'>
          <i>{calculation}</i>
        </div>,
      ]}
    </>
  );

  let tooltipContent;
  switch (_.toUpper(fieldType)) {
    case Types.TIMESTAMP:
    case Types.TIME_CALC:
    case 'HIERARCHY':
    case 'TARGET':
      tooltipContent = (
        <Fragment>
          {displayName}
          {calcContent}
        </Fragment>
      );
      break;
    case Types.NUMBER:
    case Types.STRING:
    case Types.CALC:
    case Types.STRING_CALC:
    case Types.PRIOR_PERIOD_CALC: {
      const aggName = i18nUtils.getAggregationDisplayText(
        field.defaultAggregation,
      );
      tooltipContent = (
        <Fragment>
          <div className={`field-tooltip-header ${isCalc ? 'with-calc' : ''}`}>
            {displayName} ({aggName})
          </div>
          {isFieldMissing && (
            <div className={'field-tooltip-missing-field'}>
              <InfoIcon />
              {messages.layoutPanel.calcFieldMissingTooltip}
            </div>
          )}
          <div className='field-tooltip-body'>{field.description}</div>
          {calcContent}
        </Fragment>
      );
      // Show label in italics for report level calcs
      if (!_.isNil(field.formula)) {
        displayName = <i>{displayName}</i>;
      }
      break;
    }
    default:
      tooltipContent = isFieldMissing ? (
        <div className={'field-tooltip-missing-field'}>
          <InfoIcon />
          {messages.formatString(
            messages.layoutPanel.fieldMissingTooltip,
            displayName,
          )}
        </div>
      ) : (
        ''
      );
      break;
  }

  const isTooltipOpen = !isScrolling && isHovering && !isDragging;

  return (
    <>
      <Tooltip
        id='field-tooltip-advanced'
        arrow
        open={isTooltipOpen}
        disableHoverListener={true}
        placement='right'
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
        title={tooltipContent}
      >
        <div onClick={onClick} className={'viz-field-label'}>
          {displayName}
        </div>
      </Tooltip>
      <DropdownCaret onClick={onClick} className={'dropdown-caret'} />
    </>
  );
};

export const DropdownCaret = ({ className = '', onClick }) => (
  <div
    className={className}
    onClick={onClick}
    css={css({ display: 'flex', alignItems: 'center' })}
  >
    <span className='caret'></span>
  </div>
);

class SimpleFieldPill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      isHovering: false,
    };
  }

  render() {
    const {
      discoveryId,
      field,
      isDecrease,
      invalid,
      isDragging,
      iconDataType,
      shelf,
      aggregateType,
      isScrolling,
      isFieldMissing,
    } = this.props;

    const invalidClass = invalid ? ' invalid-field' : '';

    let _element;
    const hover = isHovering => {
      // add the hover class
      const node = ReactDOM.findDOMNode(_element);
      if (isHovering) {
        node.classList.add('hover');
      } else {
        node.classList.remove('hover');
      }
      this.setState({ isHovering });
    };

    const formatType = field?.formatType;
    const dataFormatter = DATA_TYPE_FORMAT.getFormatterByName(formatType);

    const fieldTypeOverride = _.isNil(iconDataType)
      ? field.attributeType
      : iconDataType;

    let pillText;
    pillText = (
      <FieldTextWithAdvancedTooltip
        discoveryId={discoveryId}
        field={field}
        fieldTypeOverride={fieldTypeOverride}
        shelf={shelf}
        isDragging={isDragging}
        isHovering={this.state.isHovering ?? false}
        isScrolling={isScrolling ?? false}
        isFieldMissing={isFieldMissing ?? false}
      />
    );

    if (_.isFunction(this.props.renderFieldDropdown)) {
      pillText = this.props.renderFieldDropdown(isDragging);
    }

    const shelfType = _.get(shelf, 'shelfType', ShelfTypes.SELECTION);
    const aggType = _.isNil(aggregateType) ? Types.NUMBER : aggregateType;

    const isInMeasureShelf = shelfType === ShelfTypes.MEASURE;
    let fieldIconType = aggType;

    if (!isInMeasureShelf) {
      if (dataFormatter?.formatType === DATA_FORMATTER.NUMBER.formatType) {
        fieldIconType = Types.NUMBER;
      } else {
        fieldIconType = fieldTypeOverride;
      }
    }

    return (
      <div
        ref={el => {
          _element = el;
        }}
        className={`viz-field-pill${
          _.isEmpty(invalidClass) ? '' : ` ${invalidClass}`
        } ${isDragging ? 'dragging' : ''}`}
        onMouseOver={() => hover(true)}
        onMouseOut={() => hover(false)}
      >
        <FieldTypeIcon
          fieldType={fieldIconType}
          isCalculation={!_.isEmpty(field.calculation || field.formula || '')}
          shelfId={shelf?.id}
          isFieldDecrease={isDecrease}
        />
        <div
          className={`viz-field-text ${isFieldMissing ? 'missing-error' : ''}`}
        >
          {pillText}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { field, shelf } = ownProps;
  const open = VIZ_SELECTORS.getActive(state, ownProps);
  let aggregateType = null;
  let discoveryId;
  let aggregationName = field?.defaultAggregation;

  if (open) {
    aggregationName = Viz.customAggregation({
      options: open.viz.options,
      field,
      aggName: shelf?.id ? undefined : 'NA', // legacy behavior
    });

    discoveryId = open?.id;
  }

  aggregateType = _.get(
    _.find(state.main.aggregateInfo, { name: aggregationName }),
    'abbreviation',
  );

  const fieldMetadata = Viz.getAllFieldMeta(open?.viz);
  const fieldMeta = _.get(fieldMetadata, field.name, {});

  // only use fieldMeta on waterfall chart at this time
  const isDecrease =
    _.isEqual(open?.viz?.chartType, ChartSpecs.waterfall.id) &&
    !_.isEqual(shelf?.id, 'SLICER') &&
    _.isBoolean(_.get(fieldMeta, FieldMetadata.IS_DECREASE))
      ? _.get(fieldMeta, FieldMetadata.IS_DECREASE)
      : false;

  const useFiscalCalendar =
    VIZ_SELECTORS.hasVizDatasetFiscalCalendarSetting(state, ownProps) &&
    VIZ_SELECTORS.getActiveVizFiscalSetting(state, ownProps) === 'true';
  return {
    aggregateType,
    useFiscalCalendar,
    discoveryId,
    field,
    isDecrease,
  };
};

export default compose(connect(mapStateToProps))(SimpleFieldPill);
