import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDiscoverTheme } from '../../../../common/emotion/theme';
import { PopperStyled as PopperUnstyled } from '../../../../components/nested-dropdown/nested-dropdown.styles';

export const PopperStyled = styled(PopperUnstyled as any)`
  width: ${props => (props.disablePortal ? '100%' : 'auto')};
  max-width: 250px;
  min-width: ${props => (props.disablePortal ? '100%' : 'auto')};
  z-index: 5;
  border: none;
`;
export const SlicerLabel = styled.div<{ height: string; isOpen: boolean }>(
  ({ height, isOpen }) => {
    const {
      mobileMaxWidth,
      isDashletMode,
      colors: { ContentText },
    } = useDiscoverTheme();

    const mobileStyles = css`
      padding-left: 15px;
      transform: ${isOpen && 'translate(-100%, 0)'};
      min-width: 100%;
    `;

    return css`
      width: 100%;
      font-size: 14px;
      color: ${ContentText};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 4px 0 12px;
      position: relative;
      height: ${height}px;

      ${isDashletMode &&
        css`
          ${mobileStyles}
        `}

      @media (max-width: ${mobileMaxWidth}px) {
        ${mobileStyles}
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
  },
);

interface ISlicerOptionSubMenuWrapper {
  isSubMenuOpen: boolean;
  listHeight: number;
}

export const SlicerOptionSubMenuWrapper = styled.div<
  ISlicerOptionSubMenuWrapper
>(({ isSubMenuOpen, listHeight }) => {
  const { mobileMaxWidth, isDashletMode, isMobile } = useDiscoverTheme();

  const mobileStyles = css`
    width: 100%;
    display: block;
    transform: translate(${isSubMenuOpen && !isMobile ? '-100%' : 0}, 0);
    transition: transform 300ms ease-in-out;
  `;

  return css`
    display: ${isSubMenuOpen ? 'block' : 'none'};

    .corvana-calendar-picker {
      position: relative;
      left: 0;
    }

    ${isDashletMode &&
      css`
        .corvana-calendar-picker {
          height: ${listHeight - 10}px;
          overflow: hidden auto;
        }
      `}

    @media (max-width: ${mobileMaxWidth}px) {
      ${mobileStyles}
    }
  `;
});

export const StyledList = styled.div(() => {
  const { isDashletMode } = useDiscoverTheme();

  return css`
    .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }

    ${isDashletMode &&
      css`
        margin-top: 0.2rem;
        .ReactVirtualized__Grid.ReactVirtualized__List {
          overflow: inherit !important;
          width: 100% !important;
        }
        .ReactVirtualized__Grid__innerScrollContainer {
          max-width: 100% !important;
        }
      `}
  `;
});

export const DatePickerWrapper = styled.div(() => {
  const {
    mobileMaxWidth,
    isDashletMode,
    colors: { ContentText, SlicerTimePickerHeaderBackground },
  } = useDiscoverTheme();

  const mobileStyles = css`
    width: 100%;

    &.since-calendar {
      top: -28px;
    }

    &.between-calendar {
      top: -56px;
    }

    .rc-calendar {
      width: 100%;
    }
  `;

  return css`
    &.corvana-calendar-picker .rc-calendar-header {
      background-color: ${SlicerTimePickerHeaderBackground};
      height: 35px;
    }

    .rc-calendar {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;

      .rc-calendar-input {
        color: ${ContentText};
      }
    }

    .rc-time-picker-panel-inner {
      width: 100%;
    }

    .rc-calendar-time-picker-panel .rc-time-picker-panel-select {
      width: 33.3%;
    }

    .rc-calendar {
      .rc-calendar-my-select,
      .rc-calendar-clear-btn,
      .rc-calendar-footer-btn {
        display: block;
      }
    }

    .rc-calendar-range-part {
      width: 50%;
    }

    .rc-calendar-range-middle {
      margin-left: -4px;
      left: 50%;
    }

    .rc-calendar-month-panel-year-select-arrow,
    .rc-calendar-year-panel-decade-select-arrow {
      display: none;
    }

    .rc-calendar-month-panel-year-select-content,
    .rc-calendar-year-panel-decade-select-content {
      display: inline;
    }

    .rc-calendar-year-select,
    .rc-calendar-month-select,
    .rc-calendar-header,
    .rc-calendar-month-panel-header,
    .rc-calendar-year-panel-header {
      padding: 0 4px;
    }

    .rc-calendar-range {
      .rc-calendar-date {
        width: 90%;
      }
      .rc-calendar-body {
        padding-left: 2px;
        padding-right: 2px;
      }

      .rc-calendar-month-panel-year-select,
      .rc-calendar-year-panel-decade-select {
        width: 100%;
      }

      .rc-calendar-year-panel-year,
      .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
        width: 42px;
      }
    }

    ${isDashletMode &&
      css`
        ${mobileStyles}
      `}

    @media (max-width: ${mobileMaxWidth}px) {
      ${mobileStyles}
    }
  `;
});
