import { css } from '@emotion/react';
import { font } from './mixins';

export const tableStyles = ({
  theme: {
    colors: {
      ContentBackground,
      CorvanaLightText,
      CorvanaMediumBorder,
      MediumBorder,
      TextCellColor,
      ValLineStroke,
      LightFontWeight,
      MediumFontWeight,
      baseFontColor,
      TableBodyBackground,
      TableBorderColor,
      Black,
      TableHeaderBackground,
      TableRowHover,
      SelectRowPropColor,
      RegularFontWeight,
    },
  },
}) => {
  const tableRowHeight = '80px';
  const tableFontSize = '14px';
  const tableFontWeight = LightFontWeight;
  const tableHeaderFontSize = '14px';
  const tableHeaderFontWeight = MediumFontWeight;
  const menuItemSelectedColor = SelectRowPropColor;

  return css`
    /** General Table Layout **/
    @keyframes pulse {
      0%,
      100% {
        background-color: transparent;
      }
      25%,
      75% {
        background-color: ${menuItemSelectedColor};
      }
    }
    .pulse {
      animation: pulse 1.5s ease-in-out forwards;
    }
    .corvana-bs-table {
      /*====================*/
      /* stuff needed to allow for sort/filter dropdown menu to appear*/
      overflow: auto;
      /* dropdown label styling*/
      /*====================*/
    }
    .corvana-bs-table th > .btn-group {
      display: inherit;
    }
    .corvana-bs-table th div > .btn-group {
      display: table-header-group;
    }
    .corvana-bs-table .react-bs-table table td,
    .corvana-bs-table .react-bs-table table th {
      overflow: visible;
    }
    .corvana-bs-table .react-bs-container-header {
      overflow: visible;
    }
    .corvana-bs-table .tableHeaderDropdown > button {
      color: ${baseFontColor};
    }
    .corvana-bs-table th .btn-group > .dropdown-toggle {
      ${font({ size: tableHeaderFontSize, weight: tableHeaderFontWeight })}
      width: 100%;
      height: 24px;
      background-color: transparent;
      border: 0px;
      padding: 0px;
      box-shadow: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
    .corvana-bs-table th .btn-group > .dropdown-toggle:focus,
    .corvana-bs-table th .btn-group > .dropdown-toggle:active {
      background-color: transparent;
      border: 0px;
    }
    .corvana-bs-table th .btn-group .dropdown-menu a {
      font-size: ${tableHeaderFontSize};
    }
    .corvana-bs-table .react-bs-container-header th {
      padding: 8px 32px 8px 0px;
    }
    .corvana-bs-table .react-bs-container-header th > div {
      overflow: initial;
    }
    .corvana-bs-table .react-bs-container-body {
      overflow: initial;
    }
    .corvana-bs-table .react-bs-container-body .table > tbody > tr {
      background-color: ${TableBodyBackground};
    }
    .corvana-bs-table .react-bs-container-body .table > tbody > tr > td {
      ${font({ size: tableFontSize, weight: tableFontWeight })}
      padding: 8px 32px 8px 0px;
      height: ${tableRowHeight};
      vertical-align: middle;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-bottom: 1px solid ${TableBorderColor};
      overflow: hidden;
    }
    .corvana-bs-table .react-bs-container-body .table > tbody > tr > td > div {
      overflow: hidden;
    }
    .corvana-bs-table
      .react-bs-container-body
      .table
      > tbody
      > tr
      > td
      .toolbar-button.btn {
      margin-right: 8px;
    }
    .corvana-bs-table
      .react-bs-container-body
      .table
      > tbody
      > tr
      > td
      .toolbar-button {
      vertical-align: middle;
    }
    .corvana-bs-table
      .react-bs-container-body
      .table
      > tbody
      > tr
      > td.visible-overflow {
      overflow: visible;
    }
    .corvana-bs-table
      .react-bs-container-body
      .table
      > tbody
      > tr
      > td.visible-overflow
      > div {
      overflow: visible;
    }
    .corvana-bs-table .react-bs-table {
      margin: 0px 0px 0px 0px;
    }
    .corvana-bs-table .react-bs-table {
      border: 0px;
      ${font({ size: tableFontSize })}
    }
    .corvana-bs-table .react-bs-table > div > table > thead > tr > th {
      ${font({ size: tableHeaderFontSize, weight: tableHeaderFontWeight })}
      border-left: 0px;
      border-right: 0px;
      border-top: 1px solid ${TableBorderColor} !important;
      background: ${TableHeaderBackground};
      border-bottom: 1px solid ${TableBorderColor};
    }
    .corvana-bs-table .corvana-bs-table-row.selectable {
      cursor: pointer;
    }
    .corvana-bs-table .corvana-bs-table-row.selectable:hover {
      background-color: ${TableRowHover};
    }
    .corvana-bs-table .react-bs-table .form-control {
      ${font({ size: tableFontSize })}
      padding: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 34px;
      margin-left: -5px;
      padding-left: 4px;
      padding-bottom: 1px;
      margin-top: -7px;
      /* align text exactly */
      margin-bottom: -7px;
      border: 1px solid ${ValLineStroke};
      border-radius: 0px;
    }
    .corvana-bs-table .react-bs-table .disabled-tbl-control.form-control {
      ${font({ weight: tableHeaderFontWeight })}
      border: 1px solid ${ContentBackground};
      background-color: ${ContentBackground};
      color: ${CorvanaLightText};
      cursor: default;
    }
    .corvana-bs-table .textCell:hover {
      border: 1px solid ${CorvanaMediumBorder};
      padding-top: 6px;
      padding-left: 4px;
      padding-bottom: 6px;
      margin-top: -7px;
      margin-left: -5px;
      margin-bottom: -7px;
      margin-right: 4px;
    }
    .corvana-bs-table .selectCell {
      margin-left: 3px;
    }
    .corvana-bs-table .selectCell:hover {
      border: 1px solid ${MediumBorder};
      padding-top: 6px;
      padding-left: 7px;
      padding-bottom: 6px;
      margin-top: -7px;
      margin-left: -5px;
      margin-bottom: -7px;
      margin-right: -8px;
    }
    .corvana-bs-table .textCellDefault {
      color: ${TextCellColor};
    }
    .corvana-bs-table .react-bs-table select.form-control {
      border-radius: 0px;
      border: 0;
      outline: 1px solid ${ValLineStroke};
    }
    .corvana-bs-table .react-bs-table .dropdown > .dropdownStyle.btn {
      margin-top: -9px;
      margin-bottom: -7px;
      margin-left: -5px;
      padding-left: 7px;
      border-radius: 0px;
    }
    .grid-text,
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header,
    .corvana-bs-table.grid .react-bs-container-body .table > tbody > tr > td {
      ${font({ size: tableFontSize, weight: tableFontWeight })}
      height: 40px;
      line-height: 40px;
      padding: 0px 6px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .corvana-bs-table.grid {
      color: ${baseFontColor};
    }
    .corvana-bs-table.grid .react-bs-table > div > table > thead > tr > th {
      ${font({ size: tableHeaderFontSize, weight: LightFontWeight })}
      border: 1px solid ${TableBorderColor};
      padding: 0px;
      /* when not resizable, there is an extra (empty div) added, don't let that have a border either*/
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header {
      display: flex;
      align-items: center;
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header
      .dropdown {
      width: 100%;
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header
      .dropdown-menu {
      width: 100%;
      margin-top: 24px;
      top: unset;
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header
      .dropdown-toggle {
      font-weight: ${LightFontWeight};
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header {
      ${font({ size: tableFontSize })}
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .multi-line-header:first-of-type {
      ${font({ size: '14px', weight: RegularFontWeight })}
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      .resizable
      > .resize-content
      > .multi-line-header:not(:last-of-type) {
      border-bottom: 1px solid ${TableBorderColor};
    }
    .corvana-bs-table.grid
      .react-bs-table
      > div
      > table
      > thead
      > tr
      > th
      > div:not(.resizable).multi-line-header:not(:nth-last-of-type(2)) {
      border-bottom: 1px solid ${TableBorderColor};
    }
    .corvana-bs-table.grid .react-bs-container-body .table > tbody > tr {
      border-left: 1px solid ${TableBorderColor};
      border-right: 1px solid ${TableBorderColor};
      border-bottom: 1px solid ${TableBorderColor};
    }
    .corvana-bs-table.grid .react-bs-container-body .table > tbody > tr > td {
      border-bottom: 0px;
    }
    .corvana-bs-table.grid
      .react-bs-container-body
      .table
      > tbody
      > tr
      > td:not(:first-of-type) {
      border-left: 1px solid ${TableBorderColor};
    }
    /* turn off the resize indicator if in grid mode, the columns already have defined lines*/
    .corvana-bs-table.grid + .resize-indicator {
      display: none;
    }
    .corvana-bs-table.grid .resizable .resize-handle {
      right: -6px;
    }
    .resizable-container {
      position: relative;
      display: flex;
      flex: 1;
    }
    .resizable-container.resize-cursor {
      cursor: col-resize;
    }
    .resizable-container .resize-indicator {
      position: absolute;
      height: 100%;
      width: 1px;
      border-right: 1px dashed ${Black};
      top: 0px;
    }
    .resizable {
      position: relative;
    }
    .resizable > .resize-content {
      overflow: hidden;
    }
    .resizable > .resize-handle {
      position: absolute;
      z-index: 999;
      width: 15px;
      height: 100%;
      top: 0;
      right: -28px;
      cursor: col-resize;
    }
    .menuitem-selected {
      background-color: ${menuItemSelectedColor};
    }
    .sort-icon {
      width: 8px;
      margin: 8px;
    }
    .corvana-bs-table.frozen-headers .react-bs-container-header {
      overflow: hidden;
    }
    .corvana-bs-table.frozen-headers .react-bs-container-body {
      /* enable frozen header scrolling (still requires a height prop to be set on the BootstrapTable)*/
      overflow: auto;
      width: 100%;
    }
    #admin-tabs .ReactVirtualized__Table__row,
    #admin-tabs .ReactVirtualized__Table__rowColumn {
      /* important needed to override the component-level style tag styles */
      overflow: visible !important;
    }
    #admin-tabs .ReactVirtualized__Grid__innerScrollContainer {
      overflow: visible !important;
    }
  `;
};
