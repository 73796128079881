import styled from '@emotion/styled';
import { font } from '../../../../common/emotion/mixins';

export const SlugSpan = styled.span`
  font-size: ${font({ size: '14px' })};
  margin-right: 0;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
`;
