import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VizTabNavHeight, svgColorize } from '../../common/emotion/mixins';
import Tabs from '@mui/material/Tabs';
import { useDiscoverTheme } from '../../common/emotion/theme';

export const ScrollingNavBox = styled.div(() => {
  const {
    isMobile,
    darkMode,
    mobileMaxWidth,
    colors: {
      SugarGrayPalette: { Gray70, Gray80 },
      PanelBackground,
      ContentBackground,
      TabBackground,
      BorderColor,
    },
  } = useDiscoverTheme();

  return css`
    display: flex;
    flex-flow: row nowrap;
    max-width: ${isMobile ? '100vw' : 'calc(100vw - 60px)'};
    overflow-x: auto;
    position: relative;
    flex-shrink: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: ${TabBackground};
    border-bottom: 1px solid ${BorderColor};
    margin-bottom: 12px;

    @media (min-width: ${mobileMaxWidth}px) {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &.scrolling .sticky.sticky-left {
      background: ${PanelBackground};
      position: sticky;
      position: -webkit-sticky;
      left: 0px;
    }
    &.scrolling .sticky.sticky-right {
      position: sticky;
      position: -webkit-sticky;
      right: 0px;
    }
    &.scrolling .sticky.sticky-left[blur='true'],
    &.scrolling .sticky.sticky-right[blur='true'] {
      box-shadow: 0px 0px 28px 28px ${ContentBackground};
    }

    .scroll-controller {
      display: flex;
      flex-flow: row nowrap;
      width: 56px;
      height: 100%;
      border-top: none;
      border-left: none;
      border-right: none;
      box-sizing: border-box;
      z-index: 10;
    }

    .scroll-controller.hidden {
      display: none;
    }

    .scroll-controller .control {
      width: 28px;
      padding: inherit;
      border: none;
      background: ${PanelBackground};
    }

    ${darkMode
      ? css`
          ${svgColorize({ color: Gray80 })(
            '.scroll-controller .control:disabled svg',
          )}
          ${svgColorize({ color: Gray70 })('.scroll-controller .control svg')}
        `
      : css`
          .scroll-controller .control:disabled {
            opacity: 0.5;
          }
        `}

    .scroll-controller .control svg {
      vertical-align: middle;
    }

    .scroll-controller .control.control-left svg {
      transform: rotate(180deg);
    }
  `;
});

export const TabsStyled = styled(Tabs)(() => {
  const {
    colors: { ActiveTabIndicator },
  } = useDiscoverTheme();

  return css`
    min-height: ${VizTabNavHeight}px;
    overflow: visible;

    & .MuiTabs-indicator {
      height: 2px;
      background: ${ActiveTabIndicator};
    }
  `;
});
