import FunnelSvgIcon from '../../../../images/sdd/viz/visualization-selection-funnel.svg';
import ListSvgFunnel from '../../../../images/sdd/viz/funnel.svg';
import BaseChartSpec, {
  NON_NUMERIC_CALC,
  Validations,
} from '../../BaseChartSpec';
import { ShelfTypes } from '../../../discovery/interfaces';
import { LegendShapes } from '../legend-shape';
import { ChartSpecIds, Types } from '../../../common/Constants';
import { Viz } from '../../VizUtil';
import _ from 'lodash';
import { IViz, IQueryWithCalc } from '../../interfaces';
import { IAnnotation, IAnyAttribute } from '../../../datasets';
import { messages } from '../../../i18n';

export const DEFAULT_CREATED_DATE_ATTRIB_NAME = 'Date Created';

const getAnnotationValue = (
  annotations: IAnnotation[],
  key: string,
  defaultValue?: string,
): string => {
  return _.find(annotations, _.matches({ key }))?.value || defaultValue;
};

const getDatasetAnnotationFromViz = (
  viz: IViz,
  key: string,
  defaultValue?: string,
) => {
  return getAnnotationValue(viz?.dataset?.annotations, key, defaultValue);
};

const parseEnumeratedField: (...args: any) => any = (
  field: IAnyAttribute,
  viz: IViz,
  found: string[] = [],
) => {
  const { name } = field || {};
  if (_.includes(found, name)) {
    const errString = messages.formatString(
      messages.funnel.circularReferenceDetectedError,
      {
        name,
        previousNodesList: found.join(', '),
      },
    );
    throw new Error(errString as string);
  }
  found.push(name);
  const enumIdFieldKey = getAnnotationValue(
    field?.annotations,
    'ENUM_ID_ATTRIB',
  );
  const enumField = _.find(
    viz?.dataset?.attributes,
    _.matches({ name: enumIdFieldKey }),
  );
  return enumIdFieldKey ? parseEnumeratedField(enumField, viz, found) : field;
};

const getStageField = (viz: IViz) => {
  return _.head(viz.layout?.XAXIS);
};

const getMappedStageField = (viz: IViz) => {
  return parseEnumeratedField(getStageField(viz), viz);
};

const isCustomStageField = (field: IAnyAttribute) => {
  return _.some(
    field?.annotations,
    _.conforms({
      key: _.matches('IS_STAGE_FIELD'),
      value: v => v && v !== 'false',
    }),
  );
};

const getCalculationInfo = attr => {
  let name = `[${attr.name}]`;
  let agg = attr.defaultAggregation;
  const calc = !_.isEmpty(attr.calculation) ? attr.calculation : attr.formula;
  if (!_.isEmpty(calc)) {
    const stripHiddenRegex = /(\r\n|\n|\r)/gm;
    const valsInBracketsRegex = /\[(.*?)]/gm;
    const cleanCalc = calc.replace(stripHiddenRegex, '');
    if (_.startsWith(cleanCalc, 'SUM(')) {
      agg = 'Sum';
    } else if (_.startsWith(cleanCalc, 'COUNT_DISTINCT(')) {
      agg = 'Count (Distinct)';
    }

    name = _.last(cleanCalc.match(valsInBracketsRegex));
  }

  return {
    aggregation: agg,
    baseField: name,
  };
};

export const getStageAttrib = (viz: IViz) => {
  const field = getMappedStageField(viz);
  const datasetValue = getDatasetAnnotationFromViz(viz, 'stageAttrib');
  return isCustomStageField(field) ? field.name : datasetValue;
};

const supportedDataset = (viz: IViz) => {
  const stageAttrib = getStageAttrib(viz);
  return !_.isUndefined(stageAttrib);
};

const getAnnotationFromVizOrField = (viz: IViz, key: string) => {
  const field = getMappedStageField(viz);
  let value = getDatasetAnnotationFromViz(viz, key);
  if (isCustomStageField(field)) {
    const fieldKey = _.flow(_.snakeCase, _.toUpper)(key);
    value = getAnnotationValue(field?.annotations, fieldKey, value);
  }
  return value;
};

export const getDaysInStageAttrib = (viz: IViz) => {
  return getAnnotationFromVizOrField(viz, 'daysInStageAttrib');
};

export const getStageTransitionAttrib = (viz: IViz) => {
  return getAnnotationFromVizOrField(viz, 'stageTransitionAttrib');
};

export const getCloseDateAttrib = (viz: IViz) => {
  return getDatasetAnnotationFromViz(viz, 'closeDateAttrib');
};

export const getObjectIdAttrib = (viz: IViz) => {
  return getDatasetAnnotationFromViz(viz, 'objectIdAttrib');
};

export const getCreatedDateAttrib = (viz: IViz, defaultValue = '') => {
  return getDatasetAnnotationFromViz(viz, 'createdDateAttrib', defaultValue);
};

class FunnelSpec extends BaseChartSpec {
  constructor() {
    super({
      id: 'funnel',
      name: 'funnel.chartName',
      placeholderImage: '/assets/images/sdd/chart/canvas-icon-funnel.svg',
      icon: <FunnelSvgIcon />,
      listIcon: <ListSvgFunnel />,
      legendShape: LegendShapes.SQUARE,
      supportsSummary: true,
      summaryOrientation: 'bottom',
      shelves: {
        [ChartSpecIds.STACK]: {
          id: ChartSpecIds.STACK,
          name: 'funnel.stackShelf',
          aggregateMeasures: false,
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
        },
        [ChartSpecIds.XAXIS]: {
          id: ChartSpecIds.XAXIS,
          name: 'funnel.xAxisShelf',
          requiresOrdinal: true,
          accepts: NON_NUMERIC_CALC,
          shelfType: ShelfTypes.SELECTION,
          isRequired: true,
          groupNames: true,
          limits: {
            min: 1,
            max: 1,
          },
        },
        [ChartSpecIds.VALUES]: {
          id: ChartSpecIds.VALUES,
          name: 'funnel.valuesShelf',
          accepts: [Types.ANY],
          isRequired: true,
          shelfType: ShelfTypes.MEASURE,
          limits: {
            min: 1,
            max: 1,
          },
        },
        [ChartSpecIds.SLICER]: {
          id: ChartSpecIds.SLICER,
          name: 'chartSpecs.area.slicer',
          accepts: [Types.STRING, Types.TIMESTAMP],
          isRequired: false,
          shelfType: ShelfTypes.SLICER,
        },
      },

      // These will be rendered as checkboxes in the Format panel of the viz in the 'Show' section
      customFormatToggles: [
        {
          name: 'center',
          displayLabel: 'funnel.centerToggle',
          type: 'show',
          isAvailable: _.stubTrue,
        },
        {
          name: 'avgDaysInStage',
          displayLabel: 'funnel.avgDaysInStageToggle',
          type: 'show',
          isAvailable: (viz: IViz) => {
            // this must be a compatible dataset and the Stage attribute must be in the Stages shelf
            if (!supportedDataset(viz)) {
              return false;
            }

            const expectedStgName = getStageAttrib(viz);
            const stgName = !_.isEmpty(viz.layout.XAXIS)
              ? getMappedStageField(viz).name
              : 'na';
            return stgName === expectedStgName;
          },
        },
        {
          name: 'avgConversionDays',
          displayLabel: 'funnel.avgConversionDaysToggle',
          type: 'show',
          isAvailable: (viz: IViz) => {
            return supportedDataset(viz);
          },
        },
        {
          name: 'conversionRate',
          displayLabel: 'funnel.conversionRateToggle',
          type: 'show',
          isAvailable: (viz: IViz) => {
            return supportedDataset(viz);
          },
        },
      ],
      supportsPriorPeriodMetrics: false,
      supportsLayoutPanelSort: false,
      supportsLegendSelection: true,
      xAxisOrient: 'top',
      hideXAxisTickLabels: true,
    });
    this.validationRules = [
      Validations.HasLayout,
      Validations.TypeSafe,
      Validations.FieldLimit,
    ];
  }
  mapShelvesToQueryVariables(viz) {
    if (!this.validate(viz)) {
      return {
        attributeNames: [],
        measures: [],
      };
    }
    let attributeNames = [];

    if (viz.layout.XAXIS.length > 0) {
      const funnelAttribute = getStageField(viz).name;
      const ordinal = Viz.getOrdinalFor(funnelAttribute, viz);
      attributeNames = [funnelAttribute, ordinal.name];
    }
    const stackFields = _.get(viz, 'layout.STACK', []);
    if (!_.isEmpty(stackFields)) {
      const stackAttributes = stackFields.map(g => g.name);
      attributeNames = [...attributeNames, ...stackAttributes];
    }

    const measures = viz.layout.VALUES.reduce((layoutMeasures, v) => {
      // need to add the window calc attribute reference to the list of measures since that is the real value of the funnel
      layoutMeasures.push({
        attributeName: `window_${v.name}`,
        resultSetFunction: 'NONE',
      });

      // Add Average days in stage if it's toggled on
      if (Viz.isCustomFormatToggleOn('avgDaysInStage', viz)) {
        const daysInStageAttr = getDaysInStageAttrib(viz);
        const { aggregation } = getCalculationInfo(v);

        layoutMeasures.push({
          attributeName: `funnel_${daysInStageAttr}`,
          aggregation: 'Average',
          resultSetFunction: 'NONE',
        });

        layoutMeasures.push({
          attributeName: `funnel_${v.name}`,
          aggregation,
          resultSetFunction: 'NONE',
        });
      }

      if (Viz.isCustomFormatToggleOn('avgConversionDays', viz)) {
        layoutMeasures.push({
          attributeName: 'funnel_ConversionDays',
          aggregation: 'NONE',
          resultSetFunction: 'NONE',
        });
      }

      return layoutMeasures;
    }, []);
    return {
      attributeNames: _.uniq(attributeNames),
      measures,
    };
  }

  mapCalcsToQuery(viz: IViz): IQueryWithCalc {
    const calcs = Viz.mapCalcsToQuery(viz);

    if (_.isEmpty(viz?.layout?.XAXIS)) {
      return calcs;
    }

    const funnelAttribute = getStageField(viz).name;
    const ordinal = Viz.getOrdinalFor(funnelAttribute, viz);

    if (_.isNil(ordinal)) {
      throw new Error(`No ordinal column found for ${funnelAttribute}`);
    }

    const stackFields = _.get(viz, 'layout.STACK', []);

    // we need to add a calculation for our funnel value (added to measures in mapShelvesToQueryVariables function above)
    const windowCals = viz.layout.VALUES.map((field: IAnyAttribute) => {
      const selectedAggregation = Viz.customAggregation({
        options: viz?.options,
        field,
      });

      // Use Count Distinct unless we know more about the dataset & attribute
      let expr = '';
      if (
        _.isEmpty(stackFields) &&
        (selectedAggregation === 'Count' ||
          selectedAggregation === 'Count (Distinct)' ||
          !supportedDataset(viz))
      ) {
        let agg = 'COUNT_DISTINCT';
        if (selectedAggregation === 'NONE') {
          agg = '';
        }
        expr = `WINDOW(${agg}([${field.name}]), LIST(), [${ordinal.name}], [${ordinal.name}])`;
      } else {
        // if we are in the salesforce dataset, we can assume we want to select the first value from each opportunity.
        let agg =
          selectedAggregation === 'Count (Distinct)'
            ? 'COUNT_DISTINCT'
            : selectedAggregation;
        if (selectedAggregation === 'NONE') {
          agg = '';
        }
        const objId = getObjectIdAttrib(viz);
        const fields = [...stackFields.map(sf => sf.name)]
          .map(f => `[${f}]`)
          .join(', ');
        if (_.isEmpty(stackFields)) {
          expr = `FIRST_VALUE(${agg}([${field.name}]), LIST([${objId}]), LIST([Snapshot Date]), [${ordinal.name}])`;
        } else {
          expr = `FIRST_VALUE(${agg}([${field.name}]), LIST(${fields}), LIST([${objId}]), LIST([Snapshot Date]), [${ordinal.name}])`;
        }
      }
      return {
        attributeName: `window_${field.name}`,
        expression: expr,
      };
    });

    const extraCalcs = [];

    if (
      !_.isEmpty(viz?.layout?.VALUES) &&
      Viz.isCustomFormatToggleOn('avgDaysInStage', viz)
    ) {
      // add the calc needed for days in stage
      const daysInStageAttr = getDaysInStageAttrib(viz);
      const stageTransitionAttr = getStageTransitionAttrib(viz);
      const valueAttr = _.head(viz.layout.VALUES);
      const { baseField } = getCalculationInfo(valueAttr);

      const expr = `CASE([${stageTransitionAttr}] = 1, [${daysInStageAttr}], NULL)`;
      extraCalcs.push({
        attributeName: `funnel_${daysInStageAttr}`,
        expression: expr,
      });

      const countExpr = `CASE([${stageTransitionAttr}] = 1, ${baseField}, NULL)`;
      extraCalcs.push({
        attributeName: `funnel_${valueAttr.name}`,
        expression: countExpr,
      });
    }
    if (Viz.isCustomFormatToggleOn('avgConversionDays', viz)) {
      // add the calc needed for days in stage
      const createdAttr = getCreatedDateAttrib(
        viz,
        DEFAULT_CREATED_DATE_ATTRIB_NAME,
      );
      const closeAttr = getCloseDateAttrib(viz);
      const snapshotAttr = 'Current Snapshot';

      const expr = `AVERAGE(CASE([${snapshotAttr}] = 1, DATE_DIFF("DAY", [${createdAttr}], [${closeAttr}]), NULL))`;
      extraCalcs.push({
        attributeName: 'funnel_ConversionDays',
        expression: expr,
      });
    }

    return { calcs: [...calcs.calcs, ...windowCals, ...extraCalcs] };
  }

  shouldDeferToExport = _.constant(false);

  validateFieldForShelf(field, shelfId, layout, fromShelf = null) {
    const superStatus = super.validateFieldForShelf(
      field,
      shelfId,
      layout,
      fromShelf,
    );
    if (!superStatus) {
      return superStatus;
    }

    // XAxis (stages) only supports columns with ordinalAttributes
    if (shelfId === 'XAXIS') {
      return !_.isEmpty(field.ordinalAttribute);
    } else {
      return true;
    }
  }

  buildSecondaryQueryVariables(viz, primaryVariables) {
    const showConversionDays = Viz.isCustomFormatToggleOn(
      'avgConversionDays',
      viz,
    );
    const showAvgDays = Viz.isCustomFormatToggleOn('avgDaysInStage', viz);

    // only need secondary query if the funnel is a stacked funnel
    if (!_.isEmpty(viz.layout.STACK) && (showAvgDays || showConversionDays)) {
      const secondaryVariables = { ...primaryVariables };
      secondaryVariables.calcs = _.filter(primaryVariables.calcs ?? [], _calc =>
        _.includes(
          secondaryVariables?.attributeNames ?? [],
          _calc?.attributeName,
        ),
      );
      secondaryVariables.measures = [];

      if (showAvgDays) {
        const daysInStageAttr = getDaysInStageAttrib(viz);
        const stageTransitionAttr = getStageTransitionAttrib(viz);

        const expr = `CASE([${stageTransitionAttr}] = 1, [${daysInStageAttr}], NULL)`;

        // only keep the funnel_Days In Stage calc from the original
        const daysCalcName = `funnel_${daysInStageAttr}`;
        const daysInStageCalc = {
          attributeName: daysCalcName,
          expression: expr,
        };
        const daysInStageSum = {
          attributeName: 'funnel_DaysInStage_Sum',
          expression: `SUM([${daysCalcName}])`,
        };
        const daysInStageCount = {
          attributeName: 'funnel_DaysInStage_Count',
          expression: `COUNT([${daysCalcName}])`,
        };

        secondaryVariables.calcs = [
          ...secondaryVariables.calcs,
          daysInStageCalc,
          daysInStageSum,
          daysInStageCount,
        ];

        // select the sum and count of days in stage for each slice of the funnel
        secondaryVariables.measures = [
          ...secondaryVariables.measures,
          {
            attributeName: 'funnel_DaysInStage_Sum',
            aggregation: 'NONE',
            resultSetFunction: 'NONE',
          },
          {
            attributeName: 'funnel_DaysInStage_Count',
            aggregation: 'NONE',
            resultSetFunction: 'NONE',
          },
        ];
      }

      if (showConversionDays) {
        const createdAttr = getCreatedDateAttrib(
          viz,
          DEFAULT_CREATED_DATE_ATTRIB_NAME,
        );
        const closeAttr = getCloseDateAttrib(viz);
        const snapshotAttr = 'Current Snapshot';
        const cycleTimeCalcName = 'funnel_CycleTime';

        const cycleTimeCalc = {
          attributeName: cycleTimeCalcName,
          expression: `CASE([${snapshotAttr}] = 1, DATE_DIFF("DAY", [${createdAttr}], [${closeAttr}]), NULL)`,
        };
        const cycleTimeSum = {
          attributeName: 'funnel_CycleTime_Sum',
          expression: `SUM([${cycleTimeCalcName}])`,
        };
        const cycleTimeCount = {
          attributeName: 'funnel_CycleTime_Count',
          expression: `COUNT([${cycleTimeCalcName}])`,
        };

        secondaryVariables.calcs = [
          ...secondaryVariables.calcs,
          cycleTimeCalc,
          cycleTimeSum,
          cycleTimeCount,
        ];

        // select the sum and count of cycle time for each slice of the funnel
        secondaryVariables.measures = [
          ...secondaryVariables.measures,
          {
            attributeName: 'funnel_CycleTime_Sum',
            aggregation: 'NONE',
            resultSetFunction: 'NONE',
          },
          {
            attributeName: 'funnel_CycleTime_Count',
            aggregation: 'NONE',
            resultSetFunction: 'NONE',
          },
        ];
      }

      return secondaryVariables;
    }
  }
}

export default FunnelSpec;
