import { FetchResult } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import _, { get, isFunction, isNil, noop } from 'lodash';
import DiscoverQueries from './DiscoverQueries';
import {
  ADD_VISUALIZATION_QUERY_NAME,
  visualizationFragment,
  visualizationFragmentName,
} from './Fragments';
import { makeReference } from '@apollo/client/utilities';
import { ApolloCache } from '@apollo/client/cache/core/cache';
import { IViz } from '../../discovery';
import { updateVisualizationOp } from '../graphql/DiscoverQueries';

export const buildCacheUpdate = (graphQLKey: string, cacheCallback = noop) => (
  cache: ApolloCache<any>,
  { data: responseData }: FetchResult<any>,
) => {
  const updatingObject = get(responseData, graphQLKey);

  if (isNil(updatingObject?.id)) {
    return;
  }

  const cacheKey = cache.identify({
    __typename: 'DatasetVisualization',
    id: updatingObject?.id,
  });

  const cachedViz: Partial<IViz> = cache.readFragment({
    fragment: visualizationFragment,
    fragmentName: visualizationFragmentName,
    id: cacheKey,
  });

  const data = { ...cachedViz, ...updatingObject };

  isFunction(cacheCallback) && cacheCallback(cache, cacheKey, data);
};

export const UpdateVizOptions = {
  update: buildCacheUpdate(updateVisualizationOp, (cache, cacheKey, data) => {
    cache.writeQuery({
      query: DiscoverQueries.UpdateVisualizationMutation,
      id: cacheKey,
      data,
    });
  }),
};

export const CreateVizOptions = {
  update: buildCacheUpdate(
    ADD_VISUALIZATION_QUERY_NAME,
    (cache: ApolloCache<any>, cacheKey, data) => {
      cache.writeQuery({
        query: DiscoverQueries.CreateVisualizationMutation,
        id: cacheKey,
        data,
      });

      cache.modify({
        fields: {
          visualizations(existingVisualizations = []) {
            return [...existingVisualizations, makeReference(cacheKey)];
          },
        },
      });
    },
  ),
};

export const PinnedVizQuery = graphql(DiscoverQueries.DiscoveriesQuery, {
  skip: (ownProps: { pinnedDiscoveryIds: string[] }) =>
    _.isEmpty(ownProps.pinnedDiscoveryIds),
  props: ({
    data,
    ownProps,
  }: {
    data: any;
    ownProps: { pinnedDiscoveryIds: string[] };
  }) => {
    if (data.loading) {
      return { pinnedDiscoveries: [], loading: data.loading };
    } else {
      const sortOrders = {};
      const visualizations = _(data?.visualizations)
        .filter(v => {
          const index = ownProps.pinnedDiscoveryIds.findIndex(
            id => id === v.id,
          );
          // track the position of the pinned id so we can be sure to put them back in the correct order
          if (index !== -1) {
            sortOrders[v.id] = index;
          }
          return index !== -1;
        })
        .map(v => {
          const { datasetId: id } = v;
          const dataset = _.find(data?.datasets, { id });
          return {
            ...v,
            dataset,
          };
        })
        .map(v => ({ ...v, discoveryType: 'VISUALIZATION' }))
        .value();

      return {
        pinnedDiscoveries: _.sortBy(visualizations, v => sortOrders[v.id]),
        loading: false,
      };
    }
  },
});
