import { isEmpty, isFunction, last } from 'lodash';
import { pendoConstants } from './Constants';

declare const pendo;

const pendoHandler = {
  get(target, prop, receiver) {
    return Reflect.get(target, prop, receiver);
  },
};

export const PendoClient = new Proxy(
  {
    initialize: (user, isDashletMode): void => {
      if (isDashletMode || !isFunction(pendo.initialize)) {
        return;
      }

      try {
        if (!isEmpty(user.pendoAccountId) && !isEmpty(user.pendoVisitorId)) {
          pendo.initialize({
            visitor: {
              id: user.pendoVisitorId,
            },
            account: {
              id: user.pendoAccountId,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    track: (discovery, tenantId, appUrl, userSrn): void => {
      if (!isFunction(pendo.getVisitorId) || !isFunction(pendo.track)) {
        return;
      }

      const userId = last(userSrn.split(':'));
      const { action, eventLabel: label, category } = pendoConstants;
      const eventData = {
        action,
        label,
        category,
        reportName: discovery.name,
        vizId: discovery.id,
        tenantId,
        userId,
        visitorId: pendo.getVisitorId(),
        visitorDomain: appUrl,
      };
      pendo.track(label, eventData);
    },
  },
  pendoHandler,
);
