import { useSelector } from 'react-redux';
import { Copyright } from '../../UIUtilityWidgets';
import { PRIVACY_POLICY_LINK } from '../../../Constants';
import { messages } from '../../../../i18n';
import Util from '../../../Util';
import { URLs } from '../../../Urls';
import { ButtonContainer, PrimaryButton } from '../../../../ui';
import { CopyrightText, StyledModalPortal } from './logout-dialog.styles';
import classnames from 'classnames';

export const LogoutDialog = () => {
  const {
    returnToUrl = URLs.getQueryParams()?.return_to_url ?? '/',
    isDashletMode,
    idmLogoutUrl,
    appUrl,
  } = useSelector(
    ({
      dashlet: { isDashletMode },
      login: { idmLogoutUrl, returnToUrl },
      main: { appUrl },
    }: any) => ({
      returnToUrl,
      isDashletMode,
      idmLogoutUrl,
      appUrl,
    }),
  );
  const logoImgSrc = Util.assetUrl({
    appUrl,
    path: 'discover_logo.svg',
  });
  try {
    const url = new URL(idmLogoutUrl);
    const redirectUriObj = new URL(appUrl);
    redirectUriObj.pathname = 'logout';
    redirectUriObj.searchParams.append('return_to_url', returnToUrl);
    const redirectUri = redirectUriObj.toString();
    url.searchParams.append('redirect_uri', redirectUri);
    const fixedUrl = url.toString();
    window.location.href = fixedUrl;
    return null;
  } catch (err) {
    console.log('Failed to parse idmLogoutUrl', err);
  }

  const validateAndRedirect = redirectToUrl => {
    const currentDomain = window.location.hostname;
    const regex = new RegExp(`^(.*\\.)?${currentDomain}$`, 'i');
    try {
      const cleanUrl = decodeURIComponent(redirectToUrl);
      if (regex.test(new URL(cleanUrl).hostname)) {
        window.location.assign(returnToUrl);
      } else {
        throw new Error('Invalid Redirect');
      }
    } catch (err) {
      console.error(err);
      return;
    }
  };

  return (
    <>
      <StyledModalPortal
        className={classnames({
          dashlet: isDashletMode,
        })}
        contentClassName={'logout-dialog'}
        title={''}
        buttonGroup={
          <ButtonContainer
            style={{
              marginBottom: '0.8rem',
              alignSelf: 'flex-end',
              marginRight: '1rem',
            }}
          >
            <PrimaryButton
              className={'logout-button'}
              onClick={() => validateAndRedirect(returnToUrl)}
            >
              {messages.loginDialog.returnToLogin}
            </PrimaryButton>
          </ButtonContainer>
        }
        zIndex={1051}
      >
        <div className={'scrollable'}>
          <div className='login-body'>
            <div className='login-logo'>
              <img src={logoImgSrc} className='invertable center-block' />
            </div>
            <div className='login-header text-center'>
              {messages.logoutDialog.loggedOut}
            </div>
          </div>
        </div>
      </StyledModalPortal>
      {!isDashletMode && (
        <CopyrightText className={'tosText'}>
          <Copyright />
          <span className='vertical-bar' />
          <a href={PRIVACY_POLICY_LINK} target={'_blank'} rel='noreferrer'>
            {messages.privacyPolicy}
          </a>
        </CopyrightText>
      )}
    </>
  );
};
