import _, { includes, keys } from 'lodash';
import {
  DASHLET_MODE,
  DashletMode,
  DashletRouteDefinitions,
} from './dashlet.const';
import { useSelector } from 'react-redux';
import { getConfig, updateConfig } from '../common/global-discover-config';

export const setDashletModeOverride = dashletMode =>
  updateConfig({
    dashletMode,
  });

export const getDashletMode: () => DashletMode = () => {
  const { dashletMode: _dashletMode } = getConfig();
  if (_dashletMode) {
    return _dashletMode;
  }

  return;
};

export const isInDashletReportMode = () =>
  getDashletMode() === DASHLET_MODE.REPORT;

export const isDashletMode: () => boolean = () =>
  includes(keys(DashletRouteDefinitions), getDashletMode());

export const useIsDashletMode = () =>
  useSelector(
    ({ dashlet: dashletState = {} }: any = {}) => dashletState?.isDashletMode,
  );
