import styled from '@emotion/styled';
import { ModalPortal } from '../modal-portal';
import { font, useDiscoverTheme } from '../../../emotion';
import { css } from '@emotion/react';

export const StyledModalPortal = styled(ModalPortal)(() => {
  const {
    colors: { RegularFontWeight, ContentText },
  } = useDiscoverTheme();

  return css`
    .logout-dialog {
      width: 650px;
      @media (max-width: 808px) {
        width: calc(100% - 32px);
      }
    }

    .login-logo img {
      height: 3em;
      transition: all 10ms ease;
    }
    .login-header {
      ${font({
        size: '24px',
        weight: RegularFontWeight,
        color: ContentText,
      })}
      margin: 40px 0 32px 0;
    }
  `;
});

export const CopyrightText = styled.div(() => {
  const {
    darkMode,
    colors: { ContentText, ClearWhite },
  } = useDiscoverTheme();

  return css`
    position: absolute;
    z-index: 1151;
    bottom: 25px;
    width: 100%;
    color: ${darkMode ? ContentText : ClearWhite};

    a,
    .copyright {
      color: ${darkMode ? ContentText : ClearWhite};
    }
  `;
});
