import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useLiveQuerySelector,
  useOpenDiscoverySelector,
  useVizRuntimeFilterSelector,
} from '../../common/redux/selectors/viz-selector.hook';
import {
  getSortedQueryData,
  ISortedQueryData,
} from '../../common/redux/selectors/viz-selectors';
import { ChartSpecs } from '../ChartSpecs';
import { Viz } from '../VizUtil';
import { useVizQueryDiscoverAction } from '../../common/redux/actions/discover-actions.hook';
import { useIsAdvancedMode } from '../../common/redux/selectors/main-selector-hooks';
import {
  isEqualByProps,
  isEqualWithoutProps,
} from '../../common/utilities/comparison-functions.util';
import { useHasValueChanged } from '../../common/utilities/state-helpers.hook';
import { useDiscoverOptionSelector } from '../discovery-context/discovery.context';
import { ILayout } from '../interfaces';
import { IVizChartStateMap } from './viz-chart.interfaces';
import { DynamicFields, DynamicValue, SugarContext } from '../../common';
import { isDashletMode } from '../../auth/dashlet.util';

export const useChartValidation = ({ viz }) => {
  const spec = ChartSpecs[viz.chartType];

  const validation = useMemo(() => spec.validate(viz), [spec, viz]);

  useEffect(() => {
    if (!validation.valid) {
      console.warn('Visualization is not valid', validation.error);
    }
  }, [validation]);
  const retVal = useMemo(() => ({ chartSpec: spec, validation }), [
    spec,
    validation,
  ]);
  return retVal;
};

export const useVizChartStateMap = ({
  vizId: discoveryId,
}): IVizChartStateMap => {
  const useLiveQuery = useLiveQuerySelector({ discoveryId });
  const { value: slicerSelections } = useDiscoverOptionSelector({
    vizId: discoveryId,
    option: 'slicerSelections',
  });

  const convertedFilters = useVizRuntimeFilterSelector({ discoveryId });

  const open = useOpenDiscoverySelector({ discoveryId });
  const isAdvancedMode = useIsAdvancedMode();
  const historyLengthHasChanged = useHasValueChanged({
    value: open?.future?.length,
  });

  const { present: discovery } = open;
  let {
    vizLoading,
    viz,
    vizQueryError: queryError,
    monitorEventId,
    insightMode,
    dirty,
  } = discovery;
  viz = useMemo(
    () => ({
      ...viz,
      options: {
        ...viz?.options,
        slicerSelections: JSON.stringify(slicerSelections),
        filters: JSON.stringify(convertedFilters),
      },
    }),
    [convertedFilters, slicerSelections, viz],
  );
  const { layout } = viz;
  const queryResults: ISortedQueryData = getSortedQueryData(
    discovery,
  ) as ISortedQueryData;
  const secondaryQueryResults = discovery?.vizQuerySecondaryResults?.data;
  const retVal = useMemo(
    () => ({
      historyLengthHasChanged,
      useLiveQuery,
      insightMode,
      dirty,
      vizLoading,
      queryResults,
      secondaryQueryResults,
      queryError,
      viz,
      layout,
      isAdvancedMode,
      monitorEventId,
    }),
    [
      dirty,
      insightMode,
      historyLengthHasChanged,
      isAdvancedMode,
      layout,
      monitorEventId,
      queryError,
      queryResults,
      secondaryQueryResults,
      useLiveQuery,
      viz,
      vizLoading,
    ],
  );
  return retVal;
};

export const useVizChartDispatchMap = ({
  viz,
  vizId,
  monitorEventId,
  chartSpec,
  variables,
}) => {
  const sourceName = viz.name;

  // dashlets do not modify reports, so we assume they have good definitions. This helps dashboard performance
  const skipAnalyze = isDashletMode();

  const doVizQuery = useVizQueryDiscoverAction();
  const vizQuery = useCallback(() => {
    const secondaryQueryVariables = chartSpec.buildSecondaryQueryVariables(
      viz,
      variables,
    );
    doVizQuery(
      vizId,
      sourceName,
      monitorEventId,
      variables,
      viz?.queryId,
      true,
      secondaryQueryVariables,
      skipAnalyze,
    );
  }, [
    chartSpec,
    doVizQuery,
    monitorEventId,
    sourceName,
    viz,
    variables,
    vizId,
    skipAnalyze,
  ]);
  return { vizQuery };
};

export const useVizChartSelectors = ({ vizId }) => {
  const {
    historyLengthHasChanged,
    useLiveQuery,
    insightMode,
    vizLoading,
    queryResults,
    secondaryQueryResults,
    queryError,
    viz,
    dirty,
    layout,
    isAdvancedMode,
    monitorEventId,
  } = useVizChartStateMap({ vizId });

  const { chartSpec, validation } = useChartValidation({ viz });

  const variables = useMemo(() => Viz.buildQueryVariables(viz, chartSpec), [
    chartSpec,
    viz,
  ]);
  const { vizQuery } = useVizChartDispatchMap({
    viz,
    vizId,
    monitorEventId,
    chartSpec,
    variables,
  });

  const retVal = useMemo(
    () => ({
      useLiveQuery,
      chartSpec,
      validation,
      insightMode,
      vizLoading,
      dirty,
      historyLengthHasChanged,
      queryResults,
      secondaryQueryResults,
      queryError,
      viz,
      layout,
      isAdvancedMode,
      vizQuery,
      variables,
    }),
    [
      useLiveQuery,
      chartSpec,
      validation,
      insightMode,
      vizLoading,
      historyLengthHasChanged,
      queryResults,
      secondaryQueryResults,
      queryError,
      viz,
      dirty,
      layout,
      isAdvancedMode,
      vizQuery,
      variables,
    ],
  );
  return retVal;
};

const buildLayoutSignature = (
  layout?: ILayout,
  shelfIteree?: (arg: any) => string,
) => {
  return _.join(
    _.flatMap(
      _.toPairs(layout as ILayout),
      ([key, shelf]) =>
        `${key}:${_.join(
          _.flatMap(
            shelf ?? [],
            _.isFunction(shelfIteree) ? shelfIteree(shelf) : '',
          ),
          ',',
        )}`,
    ),
    ',',
  );
};

export const useVizChartState = ({ vizId }) => {
  const {
    useLiveQuery,
    chartSpec,
    validation,
    historyLengthHasChanged,
    insightMode,
    vizLoading,
    queryResults,
    secondaryQueryResults,
    queryError,
    viz,
    layout,
    dirty,
    isAdvancedMode,
    vizQuery,
    variables,
  } = useVizChartSelectors({ vizId });
  const [hasChanged, setHasChanged] = useState(false);
  const vizIdHasChanged = useHasValueChanged({ value: vizId });
  const isUnchangedNewViz = (vizId ?? '').includes('newViz') && !dirty;
  const queryHasChanged = useHasValueChanged({
    value: variables,
    comparator: isEqualWithoutProps('lastDate', 'lastLevel'),
  });
  const emptyLayoutSignature = buildLayoutSignature(_.omit(layout, 'SLICER'));
  const layoutSignature = buildLayoutSignature(
    _.omit(layout, 'SLICER'),
    _.constant('name'),
  );
  const hasLayoutSignatureChanged = useHasValueChanged({
    value: layoutSignature,
    defaultValue: emptyLayoutSignature,
  });
  const hasChartTypeChanged = useHasValueChanged({
    value: chartSpec,
    comparator: isEqualByProps('id'),
    defaultValue: false,
  });
  const layoutHasChanged = hasLayoutSignatureChanged;
  const hasSelectedTargetsChanged = useHasValueChanged({
    value: viz?.options?.selectedTargets,
  });
  const liveQueryHasChanged = useHasValueChanged({ value: useLiveQuery });
  const fields = useMemo(
    () => [
      ...variables.attributeNames,
      ..._.map(variables.measures, 'attributeName'),
    ],
    [variables.attributeNames, variables.measures],
  );

  const vizAlreadyFetched = useSelector(
    (state: any) => state.discover.discoveryAlreadyRendered,
  );

  const queryFields = useMemo(
    () =>
      _(queryResults?.executeQuery?.columnInfo)
        .reject({ columnType: 'FORMAT' })
        .map('attributeName')
        .reject(_.isNil)
        .value(),
    [queryResults?.executeQuery?.columnInfo],
  );

  const dynamicValues = useContext(SugarContext) as DynamicValue;

  // listens to dashlet context
  const reporteeData =
    dynamicValues[DynamicFields.Forecasts.reportees as string];
  const forecastsTimePeriodStart =
    dynamicValues[DynamicFields.Forecasts.timeperiodstart as string];
  const forecastsTimePeriodEnd =
    dynamicValues[DynamicFields.Forecasts.timeperiodend as string];

  const hasForecastsUserChanged = useHasValueChanged({
    value: reporteeData,
  });
  const hasForecastsTimePeriodStartChanged = useHasValueChanged({
    value: forecastsTimePeriodStart,
  });
  const hasForecastsTimePeriodEndChanged = useHasValueChanged({
    value: forecastsTimePeriodEnd,
  });

  const hasDashletContextChanged =
    hasForecastsUserChanged ||
    hasForecastsTimePeriodStartChanged ||
    hasForecastsTimePeriodEndChanged;

  const hasMissingFields =
    !_.isEmpty(viz.missingFields) || !_.isEmpty(viz.missingFilters);
  const fieldsAreDifferent = !_.isEqual(fields, queryFields);
  const isDashlet = isDashletMode();
  const insightModeReset =
    insightMode && !queryHasChanged && historyLengthHasChanged;
  const userChange =
    (vizIdHasChanged ||
      queryHasChanged ||
      insightModeReset ||
      layoutHasChanged ||
      hasSelectedTargetsChanged ||
      liveQueryHasChanged ||
      hasDashletContextChanged ||
      (fieldsAreDifferent && !vizLoading)) &&
    !queryError;
  const shouldRunInitialQuery =
    validation.valid &&
    _.isNil(queryResults) &&
    _.isNil(queryError) &&
    !vizLoading &&
    (useLiveQuery || !dirty);
  const liveQueryEnabledInDirtyState =
    dirty && useLiveQuery && liveQueryHasChanged;

  useEffect(() => {
    if (userChange && !hasMissingFields) {
      if (validation.valid && (useLiveQuery || isDashlet)) {
        if (
          (vizAlreadyFetched && vizIdHasChanged) ||
          (liveQueryHasChanged && useLiveQuery && queryHasChanged)
        ) {
          setHasChanged(true);
        } else if (liveQueryHasChanged && !queryHasChanged) {
          setHasChanged(prevState => {
            return !prevState;
          });
        } else if (hasChartTypeChanged || queryHasChanged) {
          vizQuery();
        }
      } else if (!useLiveQuery || shouldRunInitialQuery) {
        setHasChanged(true);
      }
    } else if (!useLiveQuery && !vizAlreadyFetched && vizLoading) {
      setHasChanged(false);
    }
  }, [
    isDashlet,
    liveQueryHasChanged,
    shouldRunInitialQuery,
    hasChartTypeChanged,
    queryHasChanged,
    useLiveQuery,
    userChange,
    validation.valid,
    vizAlreadyFetched,
    vizIdHasChanged,
    vizLoading,
    vizQuery,
    setHasChanged,
    hasForecastsUserChanged,
    hasMissingFields,
  ]);

  useEffect(() => {
    if (
      !hasMissingFields &&
      validation.valid &&
      _.isNil(queryResults) &&
      _.isNil(queryError) &&
      !vizLoading &&
      (useLiveQuery || !dirty)
    ) {
      Viz.configureCurrencySymbol(viz);
      vizQuery();
    }
  }, [
    dirty,
    queryError,
    queryResults,
    useLiveQuery,
    validation.valid,
    viz,
    vizLoading,
    vizQuery,
    hasMissingFields,
  ]);

  const forceQuery = useCallback(() => {
    setHasChanged(false);
    if (validation.valid) {
      vizQuery();
    }
  }, [validation.valid, vizQuery]);

  const willQueryFire =
    validation.valid &&
    ((layoutHasChanged && useLiveQuery) ||
      shouldRunInitialQuery ||
      liveQueryEnabledInDirtyState);
  const isEmptyViz = vars => {
    return _.isEmpty(
      _.flattenDeep([
        vars.attributeNames || [],
        vars.calcs || [],
        vars.measures || [],
        vars.sorts || [],
        vars.subtotals || [],
      ]),
    );
  };
  const retVal = useMemo(
    () => ({
      useLiveQuery,
      liveQueryHasChanged,
      chartSpec,
      validation,
      hasChanged,
      forceQuery,
      vizLoading:
        vizLoading ||
        willQueryFire ||
        (queryHasChanged &&
          !isUnchangedNewViz &&
          !isEmptyViz(variables) &&
          validation.valid &&
          useLiveQuery),
      queryResults,
      secondaryQueryResults,
      queryError,
      viz,
      shouldRunInitialQuery,
      isAdvancedMode,
      dirty,
    }),
    [
      useLiveQuery,
      liveQueryHasChanged,
      chartSpec,
      validation,
      hasChanged,
      forceQuery,
      vizLoading,
      willQueryFire,
      queryHasChanged,
      isUnchangedNewViz,
      variables,
      queryResults,
      secondaryQueryResults,
      queryError,
      viz,
      shouldRunInitialQuery,
      isAdvancedMode,
      dirty,
    ],
  );
  return retVal;
};
