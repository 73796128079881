import { css } from '@emotion/react';
import {
  font,
  flexBox,
  hbox,
  vbox,
  centeredPanel,
  flexFill,
  positionAbsoluteContainer,
  ellipsis,
  noSelect,
  mainContentHeightProperty,
  ModernBorderRadius,
} from './mixins';

export const commonStyles = ({
  isDashletMode,
  isMobile,
  theme: {
    darkMode,
    viewHeight,
    colors: {
      TabHighlightHover,
      PrimaryButtonTextColor,
      TableRowBackgroundHover,
      CorvanaButtonIconColor,
      StandardButtonColor,
      baseFontColor,
      LightFontWeight,
      StrongFont,
      PanelBackground,
      CorvanaLightText,
      negativeColor,
      RegularFontWeight,
      ButtonColorPrimary,
      DisabledPrimaryButtonColor,
      ButtonColorPrimaryHover,
      ButtonBorderColorPrimaryHover,
      DefaultButtonTextColor,
      DefaultButtonBorderColor,
      DefaultButtonFillColor,
      DefaultButtonTextColorHover,
      ModalBackdropColor,
      DefaultButtonBorderColorHover,
      DefaultButtonFillColorHover,
      ContentText,
      CorvanaBrightBlue,
      CorvanaMediumBorder,
      hoverHighlight,
      CorvanaBrandOrangeHighlight,
      RequiredTextColor,
      DisabledFormControlBackgroundColor,
      ContentBackground,
      PlaceholderText,
      Strokes,
    },
  },
}) => css`
  #main {
    --viewport-width: 100vw;
    --viewport-height: 100vh;
  }
  /* Force 100% so that we can layout in a page effectively */
  html,
  body,
  #main {
    ${font({})};
    height: ${isDashletMode ? `${viewHeight}` : isMobile ? '100%' : '100vh'};
    color: ${baseFontColor};
    overflow: hidden;
    color-scheme: ${darkMode ? `dark` : `auto`};
  }
  body {
    color: ${ContentText} !important;
    background-color: ${ContentBackground} !important;
  }
  text {
    color: ${ContentText} !important;
  }
  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .invertable {
    filter: invert(${darkMode ? 1 : 0});
  }

  .content-container {
    height: 100%;
  }

  .pl-60 {
    padding-left: 3.5rem;
  }

  .hidden {
    display: none !important;
  }

  label {
    ${font({ weight: LightFontWeight })}
  }

  strong {
    font-family: ${StrongFont};
    font-weight: normal;
  }

  .flex-box {
    ${flexBox()};
  }
  .hbox {
    ${hbox()};
  }
  .vbox {
    ${vbox()};
  }
  .position-absolute-container {
    ${positionAbsoluteContainer()};
  }
  .flex-end {
    justify-content: flex-end;
  }

  .flex-fill {
    ${flexFill()};
  }

  .fill {
    height: 100%;
    width: 100%;
  }

  #loader {
    ${vbox()};
    width: 100%;
  }
  #loader > div {
    align-self: center;
    position: absolute;
    z-index: 100;
    top: 45%;
  }

  #mainDiv {
    ${vbox()};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    /* min-width: 1280px;*/
  }

  #contentDiv {
    background: ${PanelBackground};
    overflow-y: auto;
    ${vbox()};
    flex: 1;

    ${mainContentHeightProperty({ isMobile, isDashletMode })}
  }
  #contentDiv > div {
    overflow: auto;
    flex: 1;
    position: relative;
  }
  #contentDiv > div#datasets {
    overflow: hidden;
  }

  .content-panel {
    ${vbox()};
    flex: 1;
    height: 100%;
  }

  .form-panel {
    padding: 9px;
  }

  .centered-panel {
    ${centeredPanel()};
  }

  .a:focus {
    outline: 0;
  }

  .btn:focus,
  .btn:active:focus,
  .btn.active:focus,
  .btn.focus,
  .btn:active.focus,
  .btn.active.focus {
    outline: 0;
  }

  :focus {
    outline: 0;
  }

  .text-light {
    color: ${CorvanaLightText};
  }

  .text-weight-lighter {
    font-weight: lighter;
  }

  .text-small {
    font-size: 14px;
  }
  .text-xsmall {
    font-size: 12px;
  }
  .light-font-weight {
    font-weight: ${LightFontWeight};
  }
  .text-medium {
    font-size: 18px;
  }
  /** Commons **/

  .requiredField {
    color: ${negativeColor} !important;
  }

  .btn-primary {
    ${font({ size: '16px', weight: RegularFontWeight })};
    background-color: ${ButtonColorPrimary};
    border-color: ${ButtonColorPrimary};
    color: ${PrimaryButtonTextColor};
  }

  button.btn-primary.disabled,
  button.btn-primary[disabled] {
    background-color: ${DisabledPrimaryButtonColor};
    border-color: ${DisabledPrimaryButtonColor};
    opacity: 1;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
  }

  .btn-primary:hover {
    background-color: ${ButtonColorPrimaryHover};
    border-color: ${ButtonBorderColorPrimaryHover};
  }
  .modal-backdrop.in {
    background-color: ${ModalBackdropColor};
  }

  .btn-default {
    ${font({ size: '16px', weight: LightFontWeight })};
    color: ${DefaultButtonTextColor};
    border: 1px solid ${DefaultButtonBorderColor};
    background-color: ${DefaultButtonFillColor};
    border-radius: 5px;
  }
  .btn-default:focus,
  .btn-default:hover {
    color: ${DefaultButtonTextColorHover};
    border: 1px solid ${DefaultButtonFillColorHover};
    background-color: ${DefaultButtonFillColorHover};
  }
  .btn-default:active {
    color: ${DefaultButtonTextColorHover};
    border: 1px solid ${DefaultButtonBorderColorHover};
    background-color: ${DefaultButtonFillColorHover};
    box-shadow: none;
  }

  .formCheckbox label input[type='checkbox'] {
    margin-top: 7px;
  }

  .form-control {
    ${font({ size: '16px' })};
    border: 1px solid ${Strokes};
    color: ${ContentText};
    height: 32px;
  }

  .form-control[disabled] {
    ${font({ weight: LightFontWeight })};
    background-color: ${DisabledFormControlBackgroundColor};
  }

  .form-control:focus {
    border-color: ${darkMode ? Strokes : CorvanaBrightBlue};
    box-shadow: none;
    outline: 0;
  }

  /** Dropdowns **/

  .dropdownStyle {
    text-align: left;
  }

  .dropdownStyle > span:first-of-type + span {
    float: right;
  }

  .dropdownStyle.btn:focus {
    /* Overwrite button focus css, dropdown has own focus css on toggle*/
    background: none;
  }

  .dropdownStyle.btn .caret {
    float: right;
    margin-top: 8px;
  }

  .dropdownStyle > div:first-of-type + span {
    float: right;
  }

  /** Toolbar Button **/
  .toolbar-btn:hover,
  .toolbar-btn:focus,
  .toolbar-btn:active {
    background-color: ${StandardButtonColor};
    border: none;
  }

  .toolbar-btn,
  .toolbar-btn.btn:active {
    padding: 1px 6px 3px 6px;
    background-color: ${CorvanaButtonIconColor};
    display: inline-block;
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .icon-small {
    height: 24px;
    width: 24px;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    background-color: ${TabHighlightHover};
  }

  .disable-text-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .btn {
    padding: 8px 16px;
    border-radius: 2px;
  }
  .form-control {
    padding: 8px;
    border-radius: ${ModernBorderRadius};
    height: 40px;
  }
  .form-group {
    margin-bottom: 16px;
  }

  .btn-group .btn-default {
    ${font({ size: '12px', weight: LightFontWeight })};
    color: ${PlaceholderText};
    padding: 6px 12px;
    border-color: ${CorvanaMediumBorder};
    height: 40px;
  }
  .btn-group .btn-default:hover:not(:disabled) {
    color: ${ContentText};
    background-color: ${hoverHighlight};
    border-color: ${CorvanaMediumBorder};
  }
  .btn-group .btn-default.active {
    box-shadow: none;
    background-color: transparent;
    border-color: ${CorvanaBrandOrangeHighlight};
  }
  .btn-group .btn-default.active:hover {
    background-color: ${hoverHighlight};
  }
  .btn-group .btn-default.custom-time-toggle-button:hover {
    background-color: transparent;
  }
  .btn-group .btn-default.custom-time-toggle-button > img {
    height: 18px;
  }

  .collapsed-x {
    width: 0px !important;
  }

  .add-button {
    border-radius: 5px !important;
    padding: 9px 8px !important;
  }
  .add-button .button-icon,
  .add-button .button-label {
    vertical-align: middle;
    display: inline-block;
  }
  .add-button path {
    fill: ${DefaultButtonTextColor};
  }
  .add-button circle {
    stroke: ${DefaultButtonTextColor};
  }
  .add-button:focus path,
  .add-button:hover path {
    fill: ${DefaultButtonTextColorHover};
  }
  .add-button:focus circle,
  .add-button:hover circle {
    stroke: ${DefaultButtonTextColorHover};
  }
  .add-button:active path {
    fill: ${DefaultButtonTextColorHover};
  }
  .add-button:active circle {
    stroke: ${DefaultButtonTextColorHover};
  }
  .add-button .add-icon {
    margin-right: 8px;
  }

  .no-select {
    ${noSelect()};
  }
  .ellipsis {
    ${ellipsis()};
  }
  .required-text {
    color: ${RequiredTextColor};
  }

  #custom-dnd-layer {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  #viz-trash-container {
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .rotate90 {
    transform: rotate(90deg);
  }

  /* hide text if it more than N lines  */
  .btn-lg,
  .btn-group-lg > .btn {
    border-radius: 2px;
  }

  .bottom-gap-med {
    margin-bottom: 24px !important;
  }

  .no-margin {
    margin: 0;
  }
  .close-margin {
    margin: 0 0 8px 0;
  }
  .tight-margin {
    margin: 0 0 16px 0;
  }
  .med-margin {
    margin: 0 0 24px 0;
  }

  a:focus {
    outline: none;
    outline-offset: -2px;
  }

  .UITable .ReactVirtualized__Table__row {
    color: ${ContentText} !important;
  }
  .UITable .ReactVirtualized__Table__row:hover,
  .UITable .ReactVirtualized__Table__row.focused {
    background-color: ${TableRowBackgroundHover} !important;
  }
`;
