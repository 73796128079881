import { LoDashStatic } from 'lodash';
import { IComponent, IModel, ISugarApp } from './mfe.interfaces';
import {
  DynamicFields,
  setDynamicValues,
  getDynamicValues,
  pendoConstants,
} from '../../app/frontend/js/common';
import { useEffect, useRef } from 'react';
import {
  IDashletFilter,
  IDashboardFilter,
} from '../../app/frontend/js/common/utilities/sugar-filter-converter/sugar-filter-converter.interfaces';

declare const _: LoDashStatic;
declare const App: ISugarApp;

export function findComponent(
  rootElem: IComponent,
  matcher: (component: IComponent) => boolean,
  checked: IComponent[] = [],
) {
  if (_.includes(checked, rootElem)) {
    return;
  }
  checked.push(rootElem);
  const isMatch = matcher(rootElem);
  if (isMatch) {
    return rootElem;
  }
  const components = rootElem._components || [];
  let match;
  for (let i = 0; i < components.length && !match; i++) {
    const c = components[i];
    match = findComponent(c, matcher, checked);
  }
  return match;
}

export const getMfeLayoutComponent = (module: string) =>
  _(['record-dashlet', 'list-dashlet'])
    .chain()
    .map(layout => App.metadata.getLayout(module, layout))
    .map('components')
    .flatten() // flatMap is not supported on different versions of lodash
    .map('view')
    .filter(Boolean)
    .find(view =>
      _.includes(['Sugar Discover', 'LBL_SUGAR_DISCOVER_DASHLET'], view?.name),
    )
    .value();

export function findNamedChild(rootElem: IComponent, name: string) {
  return findComponent(rootElem, e => {
    return e?.options?.name === name;
  });
}

export function buildQueryString(params) {
  const qs = _(params)
    .chain()
    .omit(_.isNull as any)
    .map(
      (val, key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(val))}`,
    )
    .join('&')
    .value();
  return _.isEmpty(qs) ? '' : `?${qs}`;
}

export function buildUrl({ baseUrl, path = '', queryParams = {} }) {
  const url = [baseUrl.replace(/\/$/, ''), path.replace(/^\//, '')]
    .filter(_.negate(_.isEmpty))
    .join('/');
  return `${url}${buildQueryString(queryParams)}`;
}

export function isSynced(model: IModel) {
  return model?.getSynced() === model?.toJSON();
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      (savedCallback.current as () => void)();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

let requestedUsers = [];

export const clearRequestedUserIds = () => {
  requestedUsers = [];
};

export const addRequestedUser = userMetadata => {
  requestedUsers.push(userMetadata);
};

const buildTeamForecastUsers = (_model, user) => {
  const defaultUser = App.user.toJSON();
  const { id: userId = defaultUser?.id ?? '' } = user;
  const reporteeEndpoint = `${App.api.buildURL(
    `Forecasts/reportees/${userId}`,
  )}`; // the 'level: -1' param is specified for the reportees endpoint, but does not currently work

  App.api.call('read', reporteeEndpoint, -1, {
    success(reporteeData, _request) {
      function provideReportees(_reporteesTree, _rootUserId = '') {
        if (_.isArray(_reporteesTree)) {
          const isRootAManager = _.includes(
            _.map(_reporteesTree, (['metadata', 'id'] as unknown) as string),
            _rootUserId,
          );

          if (isRootAManager) {
            const _user = _.find(
              _reporteesTree,
              _reportee => _reportee?.metadata?.id == _rootUserId,
            );
            const children = _.reject(
              _user?.children,
              _reportee => _reportee?.metadata?.id === _rootUserId,
            );
            _.forEach(children.concat(_user), _reportee =>
              provideReportees(_reportee, _reportee?.metadata?.id),
            );
          } else {
            // find user that has the _rootUserId in children
            const parentUser = _.find(_reporteesTree, _reportee => {
              const childrenIds = _.map(_reportee?.children, ([
                'metadata',
                'id',
              ] as unknown) as string);
              return _.includes(childrenIds, _rootUserId);
            });

            const _user = _.find(
              parentUser?.children,
              _reportee => _reportee?.metadata?.id === _rootUserId,
            );

            provideReportees(_user, _rootUserId);
          }
        } else {
          const _parentUser = _reporteesTree?.metadata;
          const childrenWithParent = _.map(_reporteesTree?.children, [
            'metadata',
          ]);
          const children = _.reject(
            childrenWithParent,
            ({ id }: any) => id === _parentUser?.id,
          );

          // only pull hierarchical userIds
          const isRootUserInChildren =
            _parentUser?.id !== _rootUserId &&
            _.some(children as any[], ({ id }) => id === _rootUserId);

          const prefilteredUsers = isRootUserInChildren
            ? [_parentUser]
            : _.uniq(children.concat(_parentUser));

          const requestedUserIds = _.map(requestedUsers, 'id');

          const shouldRequestUsers = _.reject(prefilteredUsers, ({ id }) =>
            _.includes(requestedUserIds, id),
          );

          _.forEach(shouldRequestUsers, ({ id, full_name }) => {
            const _user = { id, full_name };
            addRequestedUser(_user);
            buildTeamForecastUsers(_model, {
              id,
            });
          });
        }
      }

      const incompleteReporteesList: string[] =
        (getDynamicValues()[DynamicFields.Forecasts.reportees] as string[]) ??
        [];

      // build array of reportees from tree
      const _userId: string = _.last(_request?.params?.url?.split('/'));
      provideReportees(reporteeData, _userId);
      const reportees: string[] = incompleteReporteesList?.concat(
        _.map(requestedUsers, 'full_name'),
      );

      const dynamicValueReporteeList = _.reject(
        _.uniq(reportees),
        _.isUndefined,
      ) as string[];

      setDynamicValues({
        [DynamicFields.Forecasts.reportees]: _.isEmpty(dynamicValueReporteeList)
          ? null
          : dynamicValueReporteeList,
      });
    },
    error(errMsg) {
      console.log(errMsg);
    },
  });
};

export const doForecastsSelectedUserChange = (_model, user) => {
  // clear current dynamic value
  clearRequestedUserIds();

  const forecastType = App.utils.getForecastType(
    user?.is_manager,
    user?.showOpps,
  );
  const isTeamForecast = forecastType.toUpperCase() === 'ROLLUP';

  addRequestedUser(user);

  setDynamicValues({
    [DynamicFields.Forecasts.reportees]: [user?.full_name],
  });

  if (isTeamForecast) {
    buildTeamForecastUsers(_model, user);
  }
};

export const doForecastsTimePeriodChange = _timePeriodModel => {
  /**
   * The timePeriodModel has the incorrect start/end date - it only has the changed label hash
   * The start/end dates will be updated afterwards. Retrieving the model data via setTimeout is not ideal
   */
  setTimeout(() => {
    const timePeriodModel = App?.controller?.context;
    const {
      attributes: {
        selectedTimePeriodStartEnd: { start = null, end = null } = {},
      } = {},
    } = timePeriodModel;

    const formatDateValue = timeValue => {
      const datePattern = /\d\d\d\d-\d\d-\d\d/;
      return datePattern.test(timeValue) ? `TODAY("${timeValue}")` : timeValue;
    };

    setDynamicValues({
      [DynamicFields.Forecasts.timeperiodstart as string]: formatDateValue(
        start,
      ),
      [DynamicFields.Forecasts.timeperiodend as string]: formatDateValue(end),
    });
  }, 100);
};

const getAppMeta = (model: IModel) => {
  return App.utils.deepCopy(
    model?.get('metadata') ?? {
      dashlets: [],
    },
  );
};
const getUserDashboardMeta = (modelId: string) => {
  const componentLastStateKey = 'dashboard-filters';
  const dashboardKey = `Dashboards:${modelId}`;
  const lastStateKey = App.user.lastState.buildKey(
    componentLastStateKey,
    App.user.id,
    dashboardKey,
  );

  return App.utils.deepCopy(App.user.lastState.get(lastStateKey));
};

export const trackReportLoaded = (dashletId: string, model: IModel) => {
  const appMeta = getAppMeta(model);
  const thisDashlet = _.find(appMeta.dashlets, dashlet =>
    _.isEqual(dashlet?.view?.dashletId, dashletId),
  );
  const trackEvent = App?.analytics?.connectors?.Pendo?.track;
  const getPendoMetadata = App?.analytics?.connectors?.Pendo?.getPendoMetadata;

  if (!trackEvent || !_.isFunction(trackEvent)) {
    console.warn('Cannot track Sugar Discover dashlet');
    return;
  }

  if (!thisDashlet || !App?.user?.id) {
    console.warn('Required dashlet or user information is missing');
    return;
  }

  try {
    const pendoMeta = getPendoMetadata() ?? {
      visitor: {},
      account: {},
    };
    const { action, eventLabel, category } = pendoConstants;
    const eventData = {
      action,
      label: eventLabel,
      category,
      dashboardName: model.attributes.name,
      dashletName: thisDashlet.view.label,
      vizId: thisDashlet.view.vizId,
      tenantId: thisDashlet.view.tenantId,
      userId: App.user.id,
      visitorId: pendoMeta.visitor.id,
      visitorDomain: pendoMeta.account.domain,
      visitorSiId: pendoMeta.account.si_id,
    };

    trackEvent(eventLabel, eventData);
  } catch (error) {
    console.error('Error tracking Sugar Discover dashlet:', error);
  }
};
export const setupDashboardFilters = (
  dashletId: string,
  model: IModel,
): IDashboardFilter => {
  const appMeta = getAppMeta(model);
  const userDashboardMeta = getUserDashboardMeta(model?.attributes?.id);
  const thisDashlet = _.find(appMeta.dashlets, dashlet =>
    _.isEqual(dashlet?.view?.dashletId, dashletId),
  );

  if (thisDashlet) {
    const filters = appMeta.filters || {};
    if (!_.isEmpty(userDashboardMeta?.filters)) {
      _.assign(filters, userDashboardMeta?.filters);
    }
    const allFilters = _.filter(filters, v =>
      _.some(v.fields, { dashletId: thisDashlet.id }),
    );
    const vizId = thisDashlet.view?.vizId ?? '';
    const filterDefs: IDashletFilter = allFilters.reduce((acc, item) => {
      _.filter(item.fields, { dashletId: thisDashlet.id }).forEach(field => {
        if (!_.isEmpty(vizId)) {
          if (!acc[vizId]) {
            acc[vizId] = {};
          }
          acc[vizId][`${field.tableKey}:${field.fieldDef.name}`] = {
            fieldDef: field.fieldDef,
            filterDef: item.filterDef,
            targetModule: field.tableKey,
          };
        }
      });
      return acc;
    }, {});
    return { vizId, filterDefs };
  } else {
    return { vizId: undefined, filterDefs: {} };
  }
};
