import { isDashletMode } from '../auth';
import { getConfig } from './global-discover-config';
import { getIsDevMode } from './Constants';

declare global {
  interface Window {
    newrelic: any;
  }
}

const newRelicNotLoaded = () => {
  const isDevMode = getIsDevMode();

  if (!isDevMode) {
    console.error('cannot load new relic script');
  }
};

const emptyNewRelic: INewRelic = {
  setCustomAttribute: newRelicNotLoaded,
  addPageAction: newRelicNotLoaded,
  addPageView: newRelicNotLoaded,
};

interface WrappedNewRelicFunction {
  (): void;
  (arg: any): void;
  (name: string, value: string | number, persist?: boolean);
}

interface INewRelic {
  addPageAction: WrappedNewRelicFunction;
  setCustomAttribute: WrappedNewRelicFunction;
  addPageView: WrappedNewRelicFunction;
}

const newRelicPropHandler = {
  get(target, prop, receiver) {
    if (window.newrelic) {
      return window.newrelic[prop];
    }
    return Reflect.get(target, prop, receiver);
  },
};

const getNewRelicWrapper = () => {
  const newRelic: INewRelic = new Proxy(emptyNewRelic, newRelicPropHandler);
  const { TENANT_ID: tenantId } = getConfig();
  newRelic.setCustomAttribute('tenant-id', tenantId);
  newRelic.setCustomAttribute('is-dashlet', isDashletMode().toString());
  return newRelic;
};

const newRelicWrapper = getNewRelicWrapper();

export default newRelicWrapper;
