import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useDiscoverTheme } from '../../../../common/emotion';
import { CSSProperties, FC } from 'react';
import {
  ISearchableDropdownProps,
  SearchableDropdown,
} from '../../../../ui/dropdowns/searchable-dropdown';

export const Label = styled.h2(
  ({ theme: { colors: { Gray70 } = {} as any } = {} as any }: any) => css`
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0.5rem;
    color: ${Gray70};
  `,
);

export const TrackingCloseDateDiv = styled.div`
  display: grid;
  grid-template-columns: [left] 10rem [mid] 15rem [right];
  grid-template-rows: [top] 1fr [bottom];
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

export const TableColumn = styled.div`
  padding: 0.25rem;
  width: ${({ width = 10 }: { width: number }) => width}px;
`;

const TableColumnHeaderBase = styled.div(
  ({ theme: { colors: { ContentBackground } = {} as any } = {} }: any) => css`
    padding: 0.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${ContentBackground};
    height: 3rem;
  `,
);

export const TableColumnHeader = styled(TableColumnHeaderBase)`
  grid-column-start: col-start ${({ colNum = 1 }: { colNum: number }) => colNum};
  grid-column-end: col-end ${({ colNum = 1 }: { colNum: number }) => colNum};
  grid-row-start: top;
  grid-row-end: mid;
`;

export const TableColumnHeaderLast = styled(TableColumnHeaderBase)`
  grid-column-start: col-end
    ${({ colLength = 1 }: { colLength: number }) => colLength};
  grid-column-end: right;
  margin-left: -1px;
`;

export const useDatasetIngestionStyles = () => {
  const topSectionStyle = {
    maxWidth: '55rem',
    marginTop: '1rem',
    marginBottom: '0.25rem',
    display: 'grid',
    gridTemplateColumns: '[left] 1fr [mid] 1fr [right]',
    gridTemplateRows: '[top] 4rem [center] 10rem [bottom]',
    gap: '2rem 2rem',
    fontSize: '14px',
  };

  const datasetStatusStyle = {
    gridColumnStart: 'left',
    gridColumnEnd: 'mid',
    gridRowStart: 'top',
    gridRowEnd: 'center',
  };

  const primaryModuleContainerStyle = {
    gridColumnStart: 'mid',
    gridColumnEnd: 'right',
    gridRowStart: 'top',
    gridRowEnd: 'center',
  };

  const primaryModuleDropdownStyle = {
    maxWidth: '20rem',
  };

  const rewindHistoryStyle = {
    gridColumnStart: 'left',
    gridColumnEnd: 'mid',
    gridRowStart: 'center',
    gridRowEnd: 'bottom',
  };

  const continueTrackingStyle = {
    gridColumnStart: 'mid',
    gridColumnEnd: 'right',
    gridRowStart: 'center',
    gridRowEnd: 'bottom',
  };

  const trackingPeriodLabelStyle = {
    gridColumnStart: 'left',
    gridColumnEnd: 'mid',
    gridRowStart: 'top',
    gridRowEnd: 'bottom',
  };

  const trackingPeriodDropdownStyle = {
    gridColumnStart: 'mid',
    gridColumnEnd: 'right',
    gridRowStart: 'top',
    gridRowEnd: 'bottom',
  };

  const trackingFiscalLabelStyle = {
    gridColumnStart: 'left',
    gridColumnEnd: 'mid',
    gridRowStart: 'top',
    gridRowEnd: 'bottom',
  };

  const trackingFiscalDropdownStyle = {
    gridColumnStart: 'mid',
    gridColumnEnd: 'right',
    gridRowStart: 'top',
    gridRowEnd: 'bottom',
  };

  const rewindHistoryContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  };

  const rewindHistoryDropdownStyle = {
    maxWidth: '20rem',
  };

  const importHistoryContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  const importHistoryLabelStyle = {
    display: 'inline-block',
    marginBottom: '0',
    marginRight: '0.75rem',
  };

  const importHistoryCheckboxStyle = {
    padding: 0,
  };

  return {
    topSectionStyle,
    datasetStatusStyle,
    primaryModuleContainerStyle,
    primaryModuleDropdownStyle,
    rewindHistoryStyle,
    continueTrackingStyle,
    trackingPeriodLabelStyle,
    trackingPeriodDropdownStyle,
    trackingFiscalLabelStyle,
    trackingFiscalDropdownStyle,
    rewindHistoryContainerStyle,
    rewindHistoryDropdownStyle,
    importHistoryContainerStyle,
    importHistoryLabelStyle,
    importHistoryCheckboxStyle,
  };
};

export const useJoiningTablesStyles = ({
  columnHeadersLength,
  columnWidth,
  deleteWidth,
}) => {
  const {
    colors: {
      Gray40,
      Gray20,
      ContentBackground,
      StrongFontWeight,
      DropdownBorderColor,
    } = {},
  } = useDiscoverTheme();

  const bottomSectionStyle = {
    maxWidth: '90rem',
    backgroundColor: Gray20,
    marginBottom: '2rem',
    borderRadius: '5px',
    padding: '0.5rem 0.75rem 1rem',
    display: 'flex',
    flexDirection: 'column',
  } as CSSProperties;

  const joinsTableContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem',
  };

  const joinsLabelStyle = {
    fontSize: '16px',
    fontWeight: StrongFontWeight,
  };

  const joinsControlContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  const joiningTableContainerStyle = {
    flexGrow: 1,
  };

  const defaultContainerStyle = {
    height: '1px',
    width: '100%',
    minWidth: '30rem',
  };

  const containerStyle = {
    backgroundColor: Gray40,
    border: `1px solid ${Gray40}`,
    borderRadius: '3px',
  };

  const headerContainerStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columnHeadersLength}, [col-start] ${columnWidth}px [col-end]) ${deleteWidth -
      columnHeadersLength}px [right]`,
    gridTemplateRows: `[top] 3rem [bottom]`,
    gap: '1px',
  };

  const joiningRowStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: ContentBackground,
    borderBottom: `1px solid ${Gray40}`,
  };

  const aliasInputStyle = {
    height: '28px',
    width: '100%',
    border: `1px solid ${DropdownBorderColor}`,
    borderRadius: '2px',
    fontSize: '12px',
    padding: '5px',
  };

  const deleteColumnStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return {
    bottomSectionStyle,
    joinsTableContainerStyle,
    joinsLabelStyle,
    joinsControlContainerStyle,
    joiningTableContainerStyle,
    defaultContainerStyle,
    containerStyle,
    headerContainerStyle,
    joiningRowStyle,
    aliasInputStyle,
    deleteColumnStyle,
  };
};

export const SearchableColumn: FC<ISearchableDropdownProps> = (
  props: ISearchableDropdownProps,
) => (
  <div
    style={{
      padding: '0.5rem',
    }}
  >
    <SearchableDropdown {...props} />
  </div>
);
