import { css } from '@emotion/react';
import styled from '@emotion/styled';
import withStyles from '@mui/styles/withStyles';
import { Button } from '@mui/material';
import { Disableable as _Disableable } from '../../../common/emotion';
import _ from 'lodash';

const Disableable = _.isFunction(_Disableable) ? _Disableable : _.identity;

export const Title = ({ children }) => {
  return <span className='title'>{children}</span>;
};

export const ColorSeriesRowRoot = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const ColorSeriesLabel = Disableable(styled.div`
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 200;
  font-style: inherit;
  font-size: 12px;
`);
export const DropdownCaret = ({ className = '' }) => (
  <div
    className={className}
    css={css({ width: 8, marginRight: 10, marginLeft: 10 })}
  >
    <span css={css({ display: 'flex' })} className='caret'></span>
  </div>
);

export const ColorPickerToggle = withStyles(
  ({
    colors: {
      MediumBorder,
      ContentBackground,
      ContentText,
      PanelNavBackground,
    } = {} as any,
  }: any) => ({
    root: {
      height: 40,
      fontWeight: 300,
      display: 'flex',
      flexDirection: 'row',
      width: 180,
      alignContent: 'stretch',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderColor: MediumBorder,
      borderStyle: 'solid',
      boxShadow: 'none',
      borderRadius: 8,
      borderWidth: 1,
      color: ContentText,
      fontSize: 12,
      backgroundColor: ContentBackground,
      padding: '6px 8px',
      overflow: 'visible',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: PanelNavBackground,
        boxShadow: 'none',
      },
    },
  }),
)(Button);

export const ColorBoxContainer = styled.div({
  flex: 'none',
  width: 24,
  height: 24,
});
