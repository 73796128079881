import _ from 'lodash';
import { DashletMode } from '../auth/dashlet.const';

export interface IConfigType {
  dashletMode?: DashletMode;
  currentSugarModule?: string;
  mfeManifest?: any; // structure provided by our /manifest endpoint
  wsUri?: string;
  gtmid?: string;
  trackerWsUri?: string;
  SLACK_CLIENT_ID?: string;
  APPLICATION_URL?: string;
  TENANT_ID?: string;
  TENANT_IDM_ID?: string;
  CORVANA?: string;
  IDM_TOKEN?: string;
  IS_GRAPHIQL?: boolean;
  SKIP_URL_TENANT?: boolean;
  SUPPRESS_PROP_WARNINGS?: boolean;
  NR_ID?: string;
  STRICT_MODE?: boolean;
  UI_AWESOMENESS?: boolean;
}

let config: IConfigType;

export const updateConfig = (cfg: Partial<IConfigType>) => {
  config = Object.freeze(_.assign({}, config, cfg));
};

updateConfig(
  ((window as any) ?? {})?.discoverConfig || (global as any)?.discoverConfig,
);

export const getConfig = () => config;
