import { TOTALS_FLAG } from '../discovery/charts/pivot/QueryPivotUtils';
import _ from 'lodash';

type Comparator = (curr: any, next: any, reversed: boolean) => -1 | 0 | 1;
type Refinement = (any) => boolean;

const isString: Refinement = (x: any) => _.isString(x);

/**
 * Sorts AlphaNumerically but in an Excel fashion. Reversed inverses the comparison
 */
const ExcelLikeComparator: Comparator = (
  a: any,
  b: any,
  reversed = false,
): -1 | 0 | 1 => {
  const loweredA: any = isString(a) ? a.toLowerCase() : a;
  const loweredB: any = isString(b) ? b.toLowerCase() : b;
  const flip = reversed ? -1 : 1;

  if (loweredA < loweredB) {
    return reversed ? 1 : -1; // (-1 * flip) is not a literal
  } else if (loweredA > loweredB) {
    return flip;
  }

  // Equal on first pass. Now take case into consideration. Unlike standard JS sort we will sort lowercase higher
  if (a < b) {
    return flip;
  } else if (a > b) {
    return reversed ? 1 : -1;
  }
  return 0;
};

/**
 * Always sorts __ALL__ last, otherwise returns the ExcelLikeComarator
 * @param a
 * @param b
 * @param reversed
 * @returns {*}
 * @constructor
 */
const SubtotalAwareExcelLikeComparator: Comparator = (
  a: any,
  b: any,
  reversed = false,
): -1 | 0 | 1 => {
  if (a === TOTALS_FLAG && b === TOTALS_FLAG) {
    return 0;
  } else if (a === TOTALS_FLAG) {
    return 1;
  } else if (b === TOTALS_FLAG) {
    return -1;
  } else {
    return ExcelLikeComparator(a, b, reversed);
  }
};

/**
 * Direct replacement for _.orderBy but supports a custom comparison function. Array is sorted in place
 *
 * @param arry - Object Array to be sorted
 * @param keys - keys to extract values from or functions to be called to return values per-entry
 * @param directions - array of either 'asc' or 'desc'
 * @param comparisonFunction - function with which to sort
 * @returns sorted array (Even though it's mutated already)
 */
const objectSort = (
  arry: Object[],
  keys: Function | string,
  directions: 'asc'[] | 'desc'[],
  comparisonFunction: Comparator = ExcelLikeComparator,
) => {
  arry.sort((a, b) => {
    let returnVal = 0;
    let index = -1;
    while (returnVal === 0 && ++index < keys.length) {
      const key = keys[index];
      const aVal = _.isFunction(key) ? key(a) : a[key];
      const bVal = _.isFunction(key) ? key(b) : b[key];

      const direction: 'asc' | 'desc' =
        directions && index < directions.length ? directions[index] : 'asc';
      returnVal = comparisonFunction(aVal, bVal, direction === 'desc');
    }
    return returnVal;
  });
  return arry;
};

export { ExcelLikeComparator, SubtotalAwareExcelLikeComparator };
export default objectSort;
