import _, { isObject, isString } from 'lodash';
import * as d3 from 'd3';
import { NULL_DISPLAY, NULL_TOKEN, DATA_FORMATTER } from './Constants';
import { URLs } from './Urls';
import { DefaultFontName } from '../components/ui/fonts';

let canvasContext;

const getCanvasContext = font => {
  if (!canvasContext) {
    const e = document.createElement('canvas');
    canvasContext = e.getContext('2d');
  }
  canvasContext.font = font;
  return canvasContext;
};

const Util = {
  DEFAULT_PASSWORD_RULES: {
    minTotalChars: 8,
    minUppercaseChars: 1,
    minLowercaseChars: 1,
    minSpecialChars: 1,
  },

  assetUrl: ({ appUrl, path }) =>
    URLs.joinUrls(appUrl, 'assets/images/sdd', path),
  removeTypeNameRecursively(obj) {
    // default to input for String, Number, etc.
    let copy = obj;
    if (_.isArray(obj)) {
      copy = obj.map(child => this.removeTypeNameRecursively(child));
    } else if (_.isObject(obj)) {
      copy = { ...obj };
      delete copy.__typename;
      for (const childName in copy) {
        const child = copy[childName];
        if (isObject(child)) {
          copy[childName] = this.removeTypeNameRecursively(child);
        }
      }
    }
    return copy;
  },

  calcTextDimensions: _.memoize(
    (txt, font = `12px ${DefaultFontName}`) => {
      return getCanvasContext(font).measureText(txt);
    },
    (txt, font) => `${txt}_${font}`,
  ),

  // calculate the width of text based on a temporary canvas element
  calcTextWidth: _.memoize(
    (txt, font = `12px ${DefaultFontName}`) => {
      return Util.calcTextDimensions(txt, font)?.width;
    },
    (txt, font) => `${txt}_${font}`,
  ),

  // calculate the height of text based on a temporary canvas element
  calcTextHeight: _.memoize(
    (txt, font = `12px ${DefaultFontName}`) => {
      const canvasTextMetrics = Util.calcTextDimensions(txt, font);
      const actualHeight =
        canvasTextMetrics.actualBoundingBoxAscent +
        canvasTextMetrics.actualBoundingBoxDescent;
      return actualHeight;
    },
    (txt, font) => `${txt}_${font}`,
  ),

  calcLabelInfo(txtArr, font = `12px ${DefaultFontName}`) {
    let maxWidth = 0;
    let maxChars = 100000; // set to a very high value
    txtArr.forEach(text => {
      text = `${text}`; // numbers, etc.
      let width = this.calcTextWidth(text, font);
      while (width > 160) {
        // NOTE: we should monitor the performance of this on large groups of large text.
        // for now we skip every three characters until we are less than 160 pixels
        text = text.substring(0, text.length - 3);
        maxChars = Math.min(maxChars, text.length - 3);
        width = this.calcTextWidth(text, font);
      }
      maxWidth = Math.max(maxWidth, this.calcTextWidth(text, font));
    });
    return { maxWidth, maxChars };
  },

  // Given a range [min,max] expands the range by the given factor. Special handling for single values
  expandRange(range, factor) {
    const min = d3.min(range);
    const max = d3.max(range);

    // Check for a special case where there is no variance
    if (min - max === 0) {
      // There is no variance in the axis.
      if (min !== 0) {
        // Single X value not zero, pad such that 3 becomes [0,3,6], -3 becomes [-6,-3,0].
        // This Axis results in values compressed to a single vertical axis in the middle
        return min < 0 ? [min * 2, 0] : [0, min * 2];
      } else {
        // special case when the only axis value is zero. again pushes the values into the middle
        return [-1, 1];
      }
    }
    // Normal path with a true range. Return incoming range padded outward by the factor supplied
    const padding = (max - min) * factor;
    let newMin = min === 0 ? 0 : min - padding;
    let newMax = max === 0 ? 0 : max + padding;

    // make sure we don't expand passed zero if not values do not
    if (min < max) {
      // ascending
      if (min > 0 && newMin < 0) {
        newMin = 0;
      }
    } else {
      // decending
      if (max < 0 && newMax > 0) {
        newMax = 0;
      }
    }
    return [newMin, newMax];
  },

  validatePassword: (password, rules) => {
    const validationRules = _.isNil(rules)
      ? Util.DEFAULT_PASSWORD_RULES
      : rules;
    const minCharsRegex = new RegExp(
      `^(?=.{${validationRules.minTotalChars},})`,
      'g',
    );
    const minLowercaseRegex = new RegExp(
      `^(?=.*[a-z]{${validationRules.minLowercaseChars},})`,
      'g',
    );
    const minUppercaseRegex = new RegExp(
      `^(?=.*[A-Z]{${validationRules.minUppercaseChars}})`,
      'g',
    );
    const minSpecialChars = new RegExp(
      `^(?=.*[^a-zA-Z\\s\\d]{${validationRules.minSpecialChars}})`,
      'g',
    );
    const validations = {
      hasMinimumTotalChars:
        validationRules.minTotalChars === 0
          ? true
          : minCharsRegex.test(password),
      hasMinimumLowercaseChars:
        validationRules.minLowercaseChars === 0
          ? true
          : minLowercaseRegex.test(password),
      hasMinimumUppercaseChars:
        validationRules.minUppercaseChars === 0
          ? true
          : minUppercaseRegex.test(password),
      hasMinimumSpecialChars:
        validationRules.minSpecialChars === 0
          ? true
          : minSpecialChars.test(password),
      isValid: () => {
        return (
          validations.hasMinimumLowercaseChars &&
          validations.hasMinimumTotalChars &&
          validations.hasMinimumUppercaseChars &&
          validations.hasMinimumSpecialChars
        );
      },
    };

    return validations;
  },

  encode(x) {
    // binary to base-64 encoded ascii
    return window.btoa(x);
  },

  decode(x) {
    // base-64 encoded ascii to binary
    return window.atob(x);
  },

  formatCurrency(val, i18nPrefs = {}) {
    const value = !val ? 0 : val;
    return DATA_FORMATTER.CURRENCY.format(value, i18nPrefs);
  },

  handleNullToken(val) {
    return val === NULL_TOKEN ? NULL_DISPLAY : val;
  },

  isDatasetEditable(dataset) {
    const editMode = _.get(dataset, 'editMode', 'NONE');
    return editMode === 'FULL' || editMode === 'LIMITED';
  },
};

export default Util;

// the app running from home screen and not browser (think iOS)
export function isStandalone() {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    ('standalone' in window.navigator && window.navigator.standalone)
  );
}

const rolesMap = {
  DEVELOPER: 'User',
  TENANT_ADMIN: 'Admin',
  READONLY: 'ReadOnly',
  NOACCESS: 'NoAccess',
  DASHLETUSER: 'DashletUser',
};

export const assignableRoles = [
  'User',
  'Admin',
  'ReadOnly',
  'NoAccess',
  'DashletUser',
];

export function remapRoleNames(roles) {
  return roles.map(item => {
    return remapRoleName(item);
  });
}
export function remapRoleName(role) {
  return rolesMap[role] ? rolesMap[role] : role;
}

export const parseJSON = (JSONString, out = {}) => {
  if (!isString(JSONString)) {
    return out;
  }

  try {
    return JSON.parse(JSONString);
  } catch (e) {
    console.log('Could not parse JSON', JSONString);
    return out;
  }
};

export const joinTwoStringsWithSpace = (str1, str2) => `${str1} ${str2}`;
