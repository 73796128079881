import { css } from '@emotion/react';
import { font } from '../../common/emotion/mixins';

export const uiTableStyles = ({
  theme: {
    darkMode,
    colors: {
      MediumFontWeight,
      ContentBackground,
      TableHeaderBackground,
      LightFontWeight,
      TableRowHover,
      BorderColor,
      MediumBlue,
      TableHeaderTextColor,
    },
  },
}) => css`
  .UITable .ReactVirtualized__Table__headerRow {
    ${font({ size: '14px', weight: MediumFontWeight })}
    text-transform: capitalize;
    color: ${TableHeaderTextColor};
    background: ${TableHeaderBackground};
    border-bottom: 1px solid ${BorderColor};
    padding-left: 8px;
  }
  .UITable .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
  }
  .UITable .ReactVirtualized__Table__row {
    ${font({ size: '14px', weight: LightFontWeight })}
    border-bottom: 1px solid ${BorderColor};
    background-color: ${darkMode ? MediumBlue : ContentBackground};
    padding-left: 8px;
    overflow: visible !important;
  }
  .UITable .ReactVirtualized__Table__row:hover,
  .UITable .ReactVirtualized__Table__row.focused {
    background-color: ${TableRowHover};
  }
  .UITable .ReactVirtualized__Table__headerColumn,
  .UITable .ReactVirtualized__Table__rowColumn {
    padding: 0 8px;
    height: 100%;
  }
`;
