import {
  filter,
  get,
  isEmpty,
  isNil,
  isObject,
  some,
  find,
  isFinite,
} from 'lodash';
import { useEffect, useMemo } from 'react';
import { Viz } from '../../VizUtil';
import { ChartSpecs } from '../../ChartSpecs';
import { useQuery } from '@apollo/client';
import { VizQueries } from '../../../common/graphql';
import {
  useOpenVizSelector,
  useVizOptionSelector,
} from '../../../common/redux/selectors/viz-selector.hook';
import { ILinkToReport } from '../../viz-redirect';
import { IToggle, IViz } from '../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import Discover from '../../../common/redux/actions/DiscoverActions';
import { useHasValueChanged } from '../../../common/utilities/state-helpers.hook';
import { useAccount } from '../../../common/utilities/account';

const isEnableReportLink = (_customFormatToggles: IToggle[]) => {
  const enableReportLinkToggle: IToggle = find(_customFormatToggles, {
    key: 'enableReportLink',
  });
  return !!enableReportLinkToggle?.on;
};

export const useReportLinkEnabled = (vizId: string) => {
  const linkToReport: ILinkToReport | {} = useVizOptionSelector({
    discoveryId: vizId,
    option: 'linkToReport',
    defaultValue: {},
  });
  const { data: { visualization: targetViz } = {} } = useQuery(
    VizQueries.GetVisualization,
    {
      skip: isNil((linkToReport as ILinkToReport)?.id),
      variables: {
        id: (linkToReport as ILinkToReport)?.id ?? '',
      },
    },
  );
  const viz = useOpenVizSelector({ discoveryId: vizId });
  const isTargetVizOpen = !!(
    useOpenVizSelector({ discoveryId: targetViz?.id }) && targetViz?.id
  );

  const {
    drillLinkDisabled = false,
    isDashletMode = false,
    hasSideDrawerDrill = false,
  } = useSelector((state: any) => state.dashlet ?? {});
  const { isDashletUser } = useAccount();
  const userAllowed = hasSideDrawerDrill || !isDashletUser;
  const dispatch = useDispatch();
  const hasTargetVizChanged = useHasValueChanged({ value: targetViz });

  const enabled =
    !drillLinkDisabled &&
    userAllowed &&
    shouldEnableReportLink(viz, [targetViz], linkToReport as ILinkToReport);

  useEffect(() => {
    // preload target report when in dashlet mode
    if (
      !isTargetVizOpen &&
      enabled &&
      isDashletMode &&
      isObject(targetViz) &&
      !isEmpty(targetViz) &&
      hasTargetVizChanged
    ) {
      dispatch(Discover.openDiscovery(targetViz));
    }
  }, [
    dispatch,
    enabled,
    isDashletMode,
    isTargetVizOpen,
    targetViz,
    hasTargetVizChanged,
  ]);

  return {
    enabled,
    targetViz,
    isTargetVizOpen,
  };
};
export const shouldEnableReportLink = (
  viz: IViz,
  visualizations: IViz[],
  linkToReportProvided?: ILinkToReport,
) => {
  const customFormatToggles: IToggle[] = Viz.getCustomFormatTogglesFromViz(viz);
  const linkToReport =
    linkToReportProvided ??
    JSON.parse(get(viz, 'options.linkToReport', '{}') as string);

  const possibleLinkedReports = filter(visualizations ?? [], {
    chartType: ChartSpecs.pivot.id,
  });
  const linkedReportExists = some(possibleLinkedReports, {
    id: (linkToReport as ILinkToReport)?.id,
  });

  return (
    isEnableReportLink(customFormatToggles) &&
    !isEmpty(linkToReport) &&
    get(linkToReport, 'isValid', true) &&
    linkedReportExists
  );
};
/**
 * ShouldEnableReportLinkHOC is an HOC to use a custom React hook - useReportLinkEnabled
 * @param Component
 * @param vizId
 * @constructor
 */
export const ShouldEnableReportLinkHOC = (Component: any, vizId: string) => {
  return (props: any) => {
    const _vizId = vizId ?? props.vizId;
    const {
      enabled: enableReportLink,
      targetViz,
      isTargetVizOpen,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useReportLinkEnabled(_vizId);

    return (
      <Component
        enableReportLink={enableReportLink}
        targetViz={targetViz}
        isTargetVizOpen={isTargetVizOpen}
        {...props}
      />
    );
  };
};

export const useOffscreenDimensions = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenWidth, offscreenHeight } = getOffscreenDimensionsAsync();
  const dimensions = useMemo(() => ({ offscreenWidth, offscreenHeight }), [
    offscreenHeight,
    offscreenWidth,
  ]);
  return dimensions;
};

export const useOffscreenHeight = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenHeight } = useOffscreenDimensions({
    getOffscreenDimensionsAsync,
  });
  return offscreenHeight;
};

export const useOffscreenWidth = ({ getOffscreenDimensionsAsync }) => {
  const { offscreenWidth } = useOffscreenDimensions({
    getOffscreenDimensionsAsync,
  });
  return offscreenWidth;
};

export const useVerticalAxisWrapper = ({
  getOffscreenDimensionsAsync,
  scrollPct,
}) => {
  const offscreenHeight = useOffscreenHeight({ getOffscreenDimensionsAsync });
  let scrollTop = Math.min(
    0,
    Math.max(-(offscreenHeight * scrollPct), -offscreenHeight),
  );
  scrollTop = isFinite(scrollTop) ? scrollTop : 0;

  const retVal = useMemo(() => ({ scrollPct, scrollTop }), [
    scrollPct,
    scrollTop,
  ]);
  return retVal;
};
