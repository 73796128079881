import { Component } from 'react';
import { Nav, NavItem, Tab } from '@sugar-discover/react-bootstrap-wrapper';
import SelectItemsSvg from '../../../images/sdd/icon_select_items.svg';
import SetConditionSvg from '../../../images/sdd/icon_set_condition.svg';
import SelectStringItems from './SelectStringItems';
import { createFilterForField, StringFilterSubTypes } from './Filter';
import { isEqual, isNil, isEmpty } from 'lodash';
import Discover from '../../common/redux/actions/DiscoverActions';
import { compose } from 'react-recompose';
import { connect } from 'react-redux';
import StringCondition from './StringCondition';
import StringConditionPreview from './StringConditionPreview';
import { TextSearchField } from '../../components/ui/form';
import CorvanaCheckbox from '../../common/widgets/CorvanaCheckbox';
import { messages } from '../../i18n';
import { IFilter } from '../../datasets/interfaces/filter.interface';
import { IAnyAttribute } from '../../datasets';

const FilterNavItem = ({ children, svgIcon }) => {
  return (
    <div className='filter-nav-item'>
      <div className='filter-nav-icon'>{svgIcon}</div>
      <div className='filter-nav-content'>{children}</div>
    </div>
  );
};

interface IPropTypes {
  activeFilter: IFilter;
  field: IAnyAttribute;
  changeFilter: any;
  filter: IFilter;
  vizId: string;
  vizCalcs: any[];
}

class StringFilter extends Component<
  IPropTypes,
  // Note: state can contain any of the filter subtypes, we should look at refactoring.
  any // { search?: any; applyCurrentVizFilters: any }
> {
  constructor(props) {
    super(props);
    const filter = { ...props.filter };
    const subType =
      isNil(filter) || isNil(filter.subType) ? '' : filter.subType;
    this.state = {
      [StringFilterSubTypes.SELECT_ITEMS]:
        subType === StringFilterSubTypes.SELECT_ITEMS ? filter : null,
      [StringFilterSubTypes.SET_CONDITION]:
        subType === StringFilterSubTypes.SET_CONDITION ? filter : null,
      [StringFilterSubTypes.TOP_BOTTOM]:
        subType === StringFilterSubTypes.TOP_BOTTOM ? filter : null,
      applyCurrentVizFilters: true,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      !isNil(this.props.activeFilter) &&
      !isEqual(
        prevProps?.activeFilter?.subType,
        this.props.activeFilter?.subType,
      )
    ) {
      // keep track of the previous filter
      this.setState({
        [this.props.activeFilter.subType]: { ...this.props.activeFilter },
      });
    }
  }

  componentDidMount() {
    this._allowVerticalFlexFillContentArea(true);
  }

  onTabChange(key) {
    let currentFilter = this.state[key];
    if (isNil(currentFilter)) {
      const f = createFilterForField(this.props.field);
      f.subType = key;
      currentFilter = f;
      this.setState({ [key]: { ...f } });
    }
    this.props.changeFilter(currentFilter);
    this._allowVerticalFlexFillContentArea(
      key !== StringFilterSubTypes.TOP_BOTTOM,
    );
  }

  _allowVerticalFlexFillContentArea(allow = false) {
    if (allow) {
      document.querySelector('.string-filter').classList.add('grow');
    } else {
      document.querySelector('.string-filter').classList.remove('grow');
    }
  }

  handleChange(name, value) {
    const obj = {};
    obj[name] = value;
    this.setState(() => {
      return obj;
    });
  }

  onClearSearch() {
    this.setState(() => {
      return { search: '' };
    });
  }

  handleCheck(e) {
    const { name } = e.target;
    const obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  }

  render() {
    const { filter, field, vizId, vizCalcs } = this.props;
    const activeKey = filter?.subType
      ? filter.subType
      : StringFilterSubTypes.SELECT_ITEMS;
    let info = { total: 0, selected: 0 };

    if (this.props.activeFilter && this.props.activeFilter.info) {
      info = this.props.activeFilter.info;
    }
    return (
      <Tab.Container
        id='string-filter-tab-container'
        defaultActiveKey={activeKey}
        onSelect={key => {
          this.onTabChange(key);
        }}
      >
        <div className='filter-content string-filter'>
          <div className='section-label'>
            {messages.filters.selectFilterType}
          </div>
          <div className='filter-panel filter-type'>
            <Nav bsStyle='pills'>
              <NavItem eventKey={StringFilterSubTypes.SELECT_ITEMS}>
                <FilterNavItem svgIcon={<SelectItemsSvg />}>
                  {messages.filters.selectItems}
                </FilterNavItem>
              </NavItem>
              <NavItem eventKey={StringFilterSubTypes.SET_CONDITION}>
                <FilterNavItem svgIcon={<SetConditionSvg />}>
                  {messages.filters.setACondition}
                </FilterNavItem>
              </NavItem>
            </Nav>
          </div>
          <Tab.Content animation>
            <Tab.Pane
              eventKey={StringFilterSubTypes.SELECT_ITEMS}
              className='filter-menu'
            >
              <div className='section-label'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {messages.filters.itemList}
                  </div>
                  <TextSearchField
                    style={{ margin: '0px 16px 0px 20px' }}
                    placeholder={messages.filters.searchFieldsPlaceholder}
                    value={this.state.search}
                    onChange={this.handleChange.bind(this, 'search')}
                    large
                  />
                  <CorvanaCheckbox
                    checked={this.state.applyCurrentVizFilters}
                    onChange={e =>
                      this.setState({
                        applyCurrentVizFilters: e.currentTarget.checked,
                      })
                    }
                  >
                    {messages.filters.applyReportFiltersToList}
                  </CorvanaCheckbox>
                </div>
                {this.props.activeFilter && (
                  <div className='info'>
                    {messages.formatString(
                      messages.filters.selectionInfo,
                      <strong>{info.selected}</strong>,
                      <strong>{info.total}</strong>,
                    )}
                  </div>
                )}
              </div>
              <div className='filter-panel item-list'>
                {isEqual(activeKey, StringFilterSubTypes.SELECT_ITEMS) &&
                this.state.applyCurrentVizFilters ? (
                  <SelectStringItems
                    key={'withFilteredSelection'}
                    search={this.state.search}
                    filter={filter}
                    field={field}
                    vizId={vizId}
                    vizCalcs={vizCalcs}
                  />
                ) : (
                  <SelectStringItems
                    key={'withUnfilteredSelection'}
                    search={this.state.search}
                    filter={filter}
                    field={field}
                    vizId={vizId}
                    vizCalcs={vizCalcs}
                    applyCurrentVizFilters={false}
                  />
                )}
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey={StringFilterSubTypes.SET_CONDITION}>
              <div className='section-label'>
                {messages.filters.setCondition}
              </div>
              <div className='filter-panel filter-condition'>
                {isEqual(activeKey, StringFilterSubTypes.SET_CONDITION) && (
                  <StringCondition
                    filter={filter}
                    field={field}
                    vizId={vizId}
                  />
                )}
              </div>
              <div className='section-label'>
                {messages.filters.previewSelections}
                <CorvanaCheckbox
                  checked={this.state.applyCurrentVizFilters}
                  onChange={e =>
                    this.setState({
                      applyCurrentVizFilters: e.currentTarget.checked,
                    })
                  }
                >
                  {messages.filters.applyReportFiltersToPreview}
                </CorvanaCheckbox>
              </div>
              <div className='filter-panel filter-preview'>
                {isEqual(activeKey, StringFilterSubTypes.SET_CONDITION) && (
                  <StringConditionPreview
                    vizId={vizId}
                    field={field}
                    filter={filter}
                    vizCalcs={vizCalcs}
                    applyCurrentVizFilters={this.state.applyCurrentVizFilters}
                  />
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    activeFilter: isEmpty(state.discover.activeFilter)
      ? ownProps.filter
      : state.discover.activeFilter,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changeFilter(filter) {
      dispatch(Discover.setActiveFieldFilter(filter));
    },
  };
};

export default compose<IPropTypes, any>(
  connect(mapStateToProps, mapDispatchToProps),
)(StringFilter);
